'use strict';

import {union, pick, omit, isObject, difference} from 'lodash';
import JsonEditor from '../json-editor';
import Schema from '../schema-tools';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(fields, value) {
    this.props.onChange({
      ...omit(this.props.value || {}, fields),
      ...(value || {})
    });
  }

  getGroupSchema(schema, fields) {
    return {
      type: 'object',
      properties: pick(schema.properties || {}, fields),
      required: union(schema.required || [], fields.map(field => (schema.required || []).indexOf(field) >= 0)),
      additionalProperties: false,
      groups: null
    };
  }

  render() {
    const Component = this.props.templates.groups;
    const {schema} = this.props;
    const {groups} = schema;

    const groupEditors = groups.filter(isObject).filter(group => group.fields).map(group => {
      const groupSchema = this.getGroupSchema(schema, group.fields);
      const groupValue = pick(this.props.value || {}, group.fields);
      const handleChange = value => this.handleChange(group.fields, value);
      return {
        title: group.title,
        element: (<JsonEditor key={group.title}
          {...this.props}
          schema={groupSchema}
          value={groupValue}
          errors={this.props.errors}
          validate={this.props.validate}
          validateImmediately={this.props.validateImmediately}
          onValidate={this.props.onValidate}
          onChange={handleChange}
          />)
      };
    });

    const groupProps = groups.filter(isObject).reduce((props, group) => ([...props, ...(group.fields || [])]), []);
    const props = union(Object.keys(schema.properties), Object.keys(this.value || {}));
    const groupFields = difference(props, groupProps);
    const groupSchema = this.getGroupSchema(schema, groupFields);
    groupSchema.additionalProperties = schema.additionalProperties;
    const handleChange = value => this.handleChange(groupFields, value);
    const groupValue = pick(this.props.value || {}, groupFields);
    const restEditor = groupFields.length > 0 && (<JsonEditor key={''}
      {...this.props}
      schema={groupSchema}
      value={groupValue}
      onChange={handleChange}
      errors={this.props.errors}
      validate={this.props.validate}
      validateImmediately={this.props.validateImmediately}
      onValidate={this.props.onValidate}
      formatError={this.props.formatError}
      />);

    return (<Component
      rest={restEditor}
      groups={groupEditors}
      />);
  }
}
