'use strict';

import moment, {ISODATEFORMAT} from 'moment';
import styled from 'styled-components';
import PropertyList from '../property-list';
import {Link} from 'react-router-dom';
import {ChevronCapsuleButton} from '../buttons';
import Date from '../date';
import EventSteps from '../event-steps';

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  padding-right: 25px;

  > *:last-child {
    flex-shrink: 0;
    margin-left: 20px;
  }

  .date-wrapper {
    margin-right: 45px;
    > div {
      margin-left: 18px;
    }
    .time {
      color: ${props => props.theme.actionOrange};
      font-weight: bold;
    }
  }

  .time {
    color: ${props => props.theme.actionOrange};
    font-weight: bold;
    font-size: 0.85em;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.lightGray};
  }

  &:first-child {
    padding-top: 0;
  }

  > div {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .body {
    align-self: center;
  }

  h1 {
    margin-top: 0;
    font-size: 24px;
    color: ${props => props.theme.almostBlack};
    margin-bottom: 6px;
    font-size: 24px;
    line-height: 28x;

    > a {
      color: ${props => props.theme.almostBlack};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.big}px) {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0;

    a {
      margin-top: 20px;
    }
  }
`;

export default class extends React.Component {
  render() {
    const {id, eventName, location, start, end, url, status: {items: [status]}} = this.props;

    const done = status && status.material && status.participants;

    let date = null;
    if (start) {
      const startDate = moment(start, ISODATEFORMAT);
      const endDate = moment(end ? end : start, ISODATEFORMAT);
      date = start && (
        <div className="date-wrapper">
          <Date date={startDate} width={50} />
          <span className="time">{startDate.format('HH:mm')} - {endDate.format('HH:mm')}</span>
        </div>
      );
    }

    return (
      <Item key={id}>
        <div>
          {date}
          <div className="body">
            <h1><Link to={url}>{ eventName }</Link></h1>
            <PropertyList
              properties={[
                {value: location},
              ]}
              />
            <div style={{marginTop: '10px'}}>
              <EventSteps
                steps={[{
                  done,
                  label: done ? 'Geen openstaande acties' : 'Er zijn openstaande acties'
                }]}
              />
            </div>
          </div>
        </div>
        <ChevronCapsuleButton to={url}>Details</ChevronCapsuleButton>
      </Item>
    );
  }
}
