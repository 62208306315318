import Block from './block';
import EventSteps from './event-steps';

export default class extends React.Component {
  render() {
    const {
      participated,
      finishedEvaluation,
      hasEvaluation,
      paid,
      type
    } = this.props;

    const steps = [{done: participated, label: 'Deelgenomen'}];
    if (hasEvaluation) {
     steps.push({done: finishedEvaluation, label: 'Evaluatieformulier ingevuld'});
    }

    if (type !== 'in_company') {
      steps.push({done: paid, label: 'Betaald'});
    }

    return (<Block
      title="Certificaat"
      description="Het certificaat is pas beschikbaar als u aan de training heeft deelgenomen, het evaluatieformulier heeft ingevuld en de factuur is betaald. De verwerking van de deelname kan enkele dagen duren. U krijgt een e-mail zodra uw certificaat beschikbaar is."
      hasBackground
      >
      <EventSteps
        steps={steps}
      />
    </Block>);
  }
}
