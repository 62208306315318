'use strict';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({target}) {
    this.props.onChange(target.value);
  }

  render() {
    const {maxLength} = this.props.schema;
    const value = typeof this.props.value === 'string' ? this.props.value : '';
    return (<this.props.templates.formElement {...{...this.props, template: this.props.template || 'textarea'}}>
      <textarea
        maxLength={maxLength}
        onChange={this.handleChange}
        placeholder={this.props.schema.placeholder}
        onBlur={this.props.onBlur(this.props)}
        value={value}
        />
    </this.props.templates.formElement>)
  }
}
