export default class extends React.Component {
  render() {
    return (<svg className={this.props.className} width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g transform="translate(-3.000000, -1.000000)">
	            <g>
	                <path d="M17,12 L12,12 L12,17 L17,17 L17,12 Z M16,1 L16,3 L8,3 L8,1 L6,1 L6,3 L5,3 C3.89,3 3.01,3.9 3.01,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 L18,3 L18,1 L16,1 Z M19,19 L5,19 L5,8 L19,8 L19,19 Z" fill="#000000" fillRule="nonzero"></path>
	                <polygon points="0 0 24 0 24 24 0 24"></polygon>
	            </g>
	        </g>
	    </g>
		</svg>);
  }
}
