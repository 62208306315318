'use strict';

import styled from 'styled-components';
import PropertyList from "../property-list";
import moment, { ISODATEFORMAT } from 'moment';
import TeacherSessionMaterials from './teacher-session-materials';
import { Mutation, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import Block from './../../components/block';
import { CapsuleButton, TransparentAnchor } from './../../components/buttons';
import fetch from 'isomorphic-fetch';
import download from 'downloadjs';
import EventSteps from '../event-steps';
import Dialog from '../dialog';
import React from 'react';
import EducationUpcomingSession from '../education-upcoming-session';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-right: 40px;

  > * {
    flex-basis: 48%;
    margin-right: 2%;

    &:nth-child(even) {
      margin-left: 2%;
      margin-right: 0;
    }

    &.participant-list {
      flex-basis: 100%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.big}px) {
    padding-right: 0;
    margin-right: 0 !important;

    > * {
      flex-basis: 100%;
      margin-right: 0;
      margin-left: 0;

      &:nth-child(even) {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  .general-information {
    margin-top: 25px;
  }

  .participant-list table {
    width: 100%;

    thead {
      font-weight: bold;
      tr td {
        border-bottom: 1px solid ${props => props.theme.darkGray};
      }
    }
    th {
      text-align: left;
    }
    td:nth-child(5), th:nth-child(5) {
      text-align: center;
    }
    tr {
      width: 100%;

      td {
        padding: 5px;
        border-bottom: 1px solid ${props => props.theme.lightGray};
      }
    }
    tr.button-row td {
      border: none;
    }
  }

  textarea {
    clear: both;
    width: 100%;
    height: 70px;
    margin-bottom: 11px;
  }
`;

const DialogWrapper = styled.div`
  h1 {
    color: ${props => props.theme.activeBlue};
  }
  p {
    color: ${props => props.theme.darkGray};
  }
`;

@withApollo
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      participants: props.session.participants.items || [],
      participantsChanged: false,
      showConfirm: false,
      note: ''
    };
  }

  render() {
    const { session, code, refetch, client, currentUser } = this.props;
    const { participants, participantsChanged } = this.state;

    const status = session.status.items[0];
    const submitted = status && status.participants;

    const properties = [];
    if (session.location) {
      properties.push({ label: 'Locatie', value: session.location });
    }
    if (session.start) {
      properties.push({ label: 'Start', value: moment(session.start, ISODATEFORMAT).format('DD-MM-YYYY') });
    }
    if (session.end) {
      properties.push({ label: 'Eind', value: moment(session.end, ISODATEFORMAT).format('DD-MM-YYYY') });
    }

    const exportParticipants = () => {
      fetch('/download-participants/' + code, {
        headers: {
          Authorization: 'Bearer ' + localStorage.accessToken
        }
      }).then(res => res.blob()).then(blob => {
        download(blob, 'deelnemers.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      });
    };

    return (
      <Mutation mutation={gql`mutation ($session: String!, $note: String!) {
        submitParticipants(session: $session, note: $note)
      }`}>
        {(submitParticipants, { }) => (
          <Wrapper>
            <div className="general-information">
              <Block title="Algemene informatie">
                <div className="teacher-note">
                  {session.teacherNote}
                </div>
                <div style={{marginTop: '25px'}}>
                  <p><strong>Te verrichten acties:</strong></p>
                  <EventSteps
                    steps={[{
                      done: status && status.material,
                      label: 'Opleidingsmateriaal uploaden'
                    }, {
                      done: status && status.participants,
                      label: 'Aanwezigheid deelnemers registreren'
                    }]}
                  />
                </div>
              </Block>
            </div>
            <EducationUpcomingSession
              title="Klassikale bijeenkomst"
              start={session.start}
              end={session.end}
              eventTeacher={{
                name: `${currentUser.initials} ${currentUser.lastName}`
              }}
              locationInfo={session.location}
              postalCode={null}
              address={null}
              city={null}
              routeUrl={null}
            />
            <div className="participant-list">
              <Block title="Deelnemers">
                {participants.length === 0 && (
                  <p>Er zijn nog geen deelnemers bekend voor deze sessie.</p>
                )}
                {participants.length > 0 && (
                  <React.Fragment>
                    <table>
                      <thead>
                        <tr>
                          <th>Achternaam</th>
                          <th>Initialen</th>
                          <th>Voornaam</th>
                          <th>Bedrijf</th>
                          <th>Aanwezig?</th>
                        </tr>
                      </thead>
                      <tbody>
                        {participants.sort((a, b) => {
                          return String(a.lastName).toLowerCase().localeCompare(String(b.lastName).toLowerCase());
                        }).map((participant, index) => (
                          <tr key={participant.id}>
                            <td>{participant.lastName || null}</td>
                            <td>{participant.initials || null}</td>
                            <td>{participant.firstName || null}</td>
                            <td>{participant.companyName || null}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={participant.present}
                                onChange={() => {
                                  const cloned = JSON.parse(JSON.stringify(participants));
                                  cloned[index].present = !cloned[index].present;
                                  this.setState({
                                    participants: cloned,
                                    participantsChanged: true
                                  })
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                        <tr className="button-row">
                          <td colSpan={4}>
                            <TransparentAnchor onClick={exportParticipants}>
                              Deelnemerslijst downloaden
                            </TransparentAnchor>
                          </td>
                          <td style={{textAlign:'center'}}>
                            {participantsChanged && (
                              <CapsuleButton
                                type="button"
                                onClick={() => {
                                  client.mutate({
                                    mutation: gql`mutation SetAttendance (
                                      $session: String!,
                                      $present:[String]!,
                                      $absent: [String]!
                                    ) {
                                      updateAttendance(session: $session, present: $present, absent:$absent)
                                    }`,
                                    variables: {
                                      session: code,
                                      present: participants.filter(p => p.present).map(p => p.id),
                                      absent: participants.filter(p => !p.present).map(p => p.id)
                                    }
                                  }).then(() => {
                                    this.setState({
                                      participantsChanged: false
                                    })
                                  });
                                }}
                              >
                                Opslaan
                              </CapsuleButton>)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </React.Fragment>
                )}
              </Block>
              {!submitted && (
                <Block title="Deelnemers bevestigen">
                  <p>
                    Na het registreren van de aanwezigheid dient u dit te bevestigen.
                    Na het bevestigen kunt u geen wijzigingen meer aanbrengen.
                  </p>
                  <label>
                    Notitie (optioneel)
                    <textarea value={this.state.note} onChange={({target: {value}}) => {
                      this.setState({note: value});
                    }}></textarea>
                  </label>
                  <CapsuleButton onClick={() => {
                    this.setState({ showConfirm: true });
                  }}>Bevestigen</CapsuleButton>
                </Block>
              )}
            </div>

            {this.state.showConfirm && (
              <DialogWrapper>
                <Dialog
                  title="Deelnemers bevestigen"
                  body={`Weet u zeker dat u de aanwezigheid wilt bevestigen? U kunt hierna geen wijzigingen meer aanbrengen.`}
                  buttons={(
                    <React.Fragment>
                      {!submitted && (
                        <CapsuleButton onClick={() => {
                          submitParticipants({
                            variables: {
                              session: code,
                              note: this.state.note
                            }
                          }).then(() => {
                            refetch();
                          });
                          this.setState({ showConfirm: false });
                        }}>
                          Bevestigen
                        </CapsuleButton>
                      )}
                      <TransparentAnchor onClick={() => {
                        this.setState({ showConfirm: false });
                      }}>
                        Annuleren
                      </TransparentAnchor>
                    </React.Fragment>
                  )}
                  onClose={() => {
                    this.setState({ showConfirm: false });
                  }}
                />
              </DialogWrapper>
            )}

          </Wrapper>
        )}
      </Mutation>
    );
  }
}
