import styled from 'styled-components';

const FooterContainer = styled.div`
  margin-top: 100px;
  padding: 25px;
  border-top: 1px solid ${props => props.theme.lightGray};
  
  .section {
    max-width: 1800px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .logo-container {
    flex-basis: 408px;
    margin-left: 20px;
  }

  .logo {
    text-decoration: none;
    
    > img {
      width: 325px;
    }

    &:hover {
      text-decoration: none;
    }
  }
  
  .copyright {
    margin-right: 80px;
    flex-grow: 1;
    color: ${props => props.theme.mediumGray};
    font-size: 0.8em;
    text-transform: uppercase;
    text-align: right;
    
    a {
      color: ${props => props.theme.actionOrange};
      
      &:hover {
        color: ${props => props.theme.darkerOrange};
      }
    }
  }
  
  @media (max-width: ${ props => props.theme.breakpoints.big }px) {
    .section {
      flex-direction: column;
      
      .logo-container {
        margin-left: 0;
        flex-basis: auto;
      }
    }
    
    .copyright {
      margin-right: 0;
      text-align: center;
    }
  }
`;

export default class Footer extends React.Component {
  render() {
    return (<FooterContainer>
      <div className="row">
        <div className="section">
          <div className="logo-container">
            <a className="logo" href="https://www.rendement.nl/" title="Rendement.nl" target="_blank">
              <img src="/assets/rendementuitgeverij-logo.png" alt="Rendement Uitgeverij"/>
            </a>
          </div>
          <div className="copyright">
            <div className="privacy-statement">
              Gebruik van deze site betekent dat u onze <a className="warning" target="_blank" href="https://www.rendement.nl/av">algemene voorwaarden</a> en
              ons <a className="warning" target="_blank" href="https://www.rendement.nl/privacybeleid">privacy statement</a> accepteert.
            </div>
            <div className="commerce-info">
              KVK: 27181487 | IBAN: NL24 INGB 0006 4159 44 | BTW: NL 8079.64.712.B.01
            </div>
          </div>
        </div>
      </div>
    </FooterContainer>);
  }
}