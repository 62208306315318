'use strict';

import styled from 'styled-components';
import {Link} from 'react-router-dom';

const CapsuleButton = styled.button`
	padding: 0 20px;
	border-radius: 10px;
	border: none;
	height: 45px;
	line-height: 45px;
	font-size: 16px;
	color: white;
	background-color: ${props => props.theme.activeBlue};
	cursor: pointer;
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	box-sizing: border-box;

	svg * {
		transition: fill 0.2s ease-in-out, stroke 0.2s ease-in-out;
	}

	&:focus {
    outline: none;
	}

	&:hover {
		background-color: ${props => props.theme.darkerBlue};
	}

	&:link, &:visited {
		color: white;
		text-decoration: none;
		font-weight: normal;
	}

	&:disabled {
		background-color: ${props => props.theme.lightGray};
		cursor: not-allowed;
	}
`;

const SmallCapsuleButton = CapsuleButton.extend`
	height: auto;
	line-height: 1em;
	padding: 12px;
`;

const ChevronCapsuleButton = CapsuleButton.withComponent(Link).extend`
	display: inline-block;

	:after {
		display: inline-block;
		content: '';
		width: 9px;
		height: 15px;
		background-image: url('/assets/chevron-right.svg');
		background-size: contain;
		background-repeat: no-repeat;
		margin-left: 1em;
		position: relative;
		top: 2px;
	}
`;

const CTACapsuleButton = CapsuleButton.extend`
	background-color: ${props => props.theme.actionOrange};

	&:hover {
		background-color: ${props => props.theme.darkerOrange};
	}
`;

const CTACapsuleLink = CTACapsuleButton.withComponent(Link).extend`
	display: inline-block;
`;

const WarningCapsuleButton = CapsuleButton.extend`
	background-color: ${props => props.theme.warningRed};
`;

const SubduedCapsuleButton = CapsuleButton.extend`
	background-color: white;
	border: 1px solid ${props => props.theme.mediumGray};
	color: ${props => props.theme.mediumGray};

	&:hover {
		background-color: ${props => props.theme.mediumGray};
		color: white;
	}
`;

const SubduedCapsuleLink = SubduedCapsuleButton.withComponent(Link).extend`
	&:link, &:visited { color: ${props => props.theme.mediumGray}; }
	&:hover {
    color: white;
    svg path {
      fill: white;
    }
  }
`;

const TransparentButton = CapsuleButton.extend`
	background-color: transparent;
	color: ${props => props.theme.actionBlue};

	&:hover {
		color: ${props => props.theme.darkerBlue};
		background-color: transparent;
	}

	&:visited {
		color: ${props => props.theme.actionBlue};
	}

	&:hover {
		text-decoration: underline;
	}
`;

const SlimTransparentButton = TransparentButton.extend`
	padding: 0;
	line-height: 1em;
	height: auto;
	text-align: left;

	&:hover {
		text-decoration: none;
		font-weight: bold;
	}
`;

const ChevronCapsuleAnchor = ChevronCapsuleButton.withComponent('a');

const ChevronCapsuleLink = ChevronCapsuleButton.withComponent(Link);

const TransparentChevronCapsuleLink = ChevronCapsuleLink.extend`
	background-color: transparent;
	color: ${props => props.theme.actionBlue};

	&:hover {
		color: ${props => props.theme.darkerBlue};
		background-color: transparent;

		&:after {
			color: ${props => props.theme.darkerBlue};
		}
	}

	&:link, &:visited {
		color: ${props => props.theme.actionBlue};
	}

	&:after{
		color: ${props => props.theme.actionBlue};
		content: '>';
	}
`;

const TransparentLink = TransparentButton.withComponent(Link).extend`
	background-color: transparent;
	padding: 0;

	color: ${props => props.theme.actionBlue};

	&:hover {
		color: ${props => props.theme.darkerBlue};
		background-color: transparent;
	}

	&:link, &:visited {
		color: ${props => props.theme.actionBlue};
	}
`;

const TransparentAnchor = TransparentLink.withComponent('a');

const BackLink = TransparentLink.extend`
	:before {
		display: inline-block;
		content: '';
		background-image: url('/assets/chevron-blue-left.svg');
		background-size: contain;
		background-repeat: no-repeat;
		transform: rotate(180deg);
		width: 9px;
		height: 15px;
		margin-right: 1em;
		position: relative;
		top: 2px;
		color: ${props => props.theme.actionBlue};
	}
`;

const BackAnchor = BackLink.withComponent('a');

const GrayTransparentLink = TransparentLink.extend`
  margin-left: 20px;

  &:link, &:visited { color: ${props => props.theme.mediumGray}; }
  &:hover {
    color: black;
    svg path {
      fill: black;
    }
  }

  svg {
    margin-right: 6px;
    position: relative;
    top: 2px;
  }
`;

export {
	BackLink,
	BackAnchor,
	CapsuleButton,
	SmallCapsuleButton,
	ChevronCapsuleButton,
	ChevronCapsuleAnchor,
	ChevronCapsuleLink,
	CTACapsuleButton,
	CTACapsuleLink,
	SlimTransparentButton,
	SubduedCapsuleButton,
	SubduedCapsuleLink,
	TransparentButton,
	TransparentLink,
	TransparentAnchor,
	TransparentChevronCapsuleLink,
	GrayTransparentLink,
	WarningCapsuleButton
};
