'use strict';

import styled from 'styled-components';

const UserWidget = styled.div.attrs({
  className: 'user-widget'
})`
	flex-basis: 1;
	flex-grow: 0;
	flex-shrink: 0;
	position: relative;
	align-self: stretch;
  margin-bottom: 10px;

  a {
    position: absolute;
    top: 0;
    right: 0;
    color: ${ props => props.theme.almostBlack };
		font-size: 16px;
		cursor: pointer;
  }

	#avatar {
		height: 99px;
		width: 99px;
		border-radius: 50px;
		background-repeat: no-repeat;
		background-size: contain;
    margin-bottom: 20px;
	}

	h1 {
		margin: 0;
		font-size: 16px;
		font-weight: 900;
		-webkit-margin-after: 0;
		color: ${ props => props.theme.almostBlack };
	}

	h2 {
    padding-top: 5px;
		margin: 0;
		font-size: 16px;
		font-weight: 900;
		-webkit-margin-before: 0;
		color: ${ props => props.theme.mediumDarkGray };
	}


	@media (max-width: ${ props => props.theme.breakpoints.big }px) {
		margin: 25px 0 0;

		#avatar {
			height: auto;
			width: 70px;
			flex-basis: 70px;
			margin-bottom: 10px;
			margin-right: 0;
		}

		.text-container {
			h1, h2, a { text-align: center }
			h1 { font-size: 18px; }
		}
	}
`;

export default class extends React.Component {
  render() {
    const {avatar, title, subTitle, onClickLogout} = this.props;

    return (<UserWidget>
      <a onClick={onClickLogout}>Uitloggen</a>
      <div id="avatar" style={{backgroundImage: `url(${avatar})`}}/>
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
    </UserWidget>);
  }
}
