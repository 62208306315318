export default class extends React.Component {
  render() {
    return (<svg className={this.props.className} width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-2.000000, -2.000000)">
          <g>
            <polygon points="0 0 24 0 24 24 0 24"></polygon>
            <path d="M21.41,11.58 L12.41,2.58 C12.05,2.22 11.55,2 11,2 L4,2 C2.9,2 2,2.9 2,4 L2,11 C2,11.55 2.22,12.05 2.59,12.42 L11.59,21.42 C11.95,21.78 12.45,22 13,22 C13.55,22 14.05,21.78 14.41,21.41 L21.41,14.41 C21.78,14.05 22,13.55 22,13 C22,12.45 21.77,11.94 21.41,11.58 Z M5.5,7 C4.67,7 4,6.33 4,5.5 C4,4.67 4.67,4 5.5,4 C6.33,4 7,4.67 7,5.5 C7,6.33 6.33,7 5.5,7 Z M17.27,15.27 L13,19.54 L8.73,15.27 C8.28,14.81 8,14.19 8,13.5 C8,12.12 9.12,11 10.5,11 C11.19,11 11.82,11.28 12.27,11.74 L13,12.46 L13.73,11.73 C14.18,11.28 14.81,11 15.5,11 C16.88,11 18,12.12 18,13.5 C18,14.19 17.72,14.82 17.27,15.27 Z" fill="#000000" fillRule="nonzero"></path>
          </g>
        </g>
      </g>
    </svg>);
  }
}
