export default {
  almostBlack: 'rgb( 74, 74, 74 )',
  darkGray: 'rgb( 105, 105, 105 )',
  mediumDarkGray: 'rgb( 117, 117, 117)',
  mediumGray: 'rgb( 146, 146, 146 )',
  lightGray: 'rgb( 220, 220, 220 )',
  backgroundGray: 'rgb( 244, 244, 244 )',
  activeBlue: 'rgb( 4, 86, 165 )',
  darkerBlue: 'rgb( 0, 74, 133 )',
  actionBlue: 'rgb( 4, 86, 165 )',
  rendementBlue: 'rgb( 4, 86, 165 )',
  actionOrange: 'rgb( 248, 141, 61 )',
  darkerOrange: 'rgb( 204, 117, 50 )',
  warningRed: 'rgb( 204, 58, 58 )',
  expiredRed: 'rgb( 208, 2, 27 )',
  errorRed: 'rgb( 169, 68, 66 )',
  paleGreen: 'rgb( 223, 240, 216 )',
  paleGreenDarker: 'rgb( 214, 233, 198 )',
  happyGreen: 'rgb( 127, 175, 11 )',
  successGreen: 'rgb( 60, 118, 61 )',
  enabledGreen: 'rgb( 0, 182, 73 )',
  breakpoints: {
    tiny: 500,
    small: 800,
    big: 1200,
    xl: 1600
  }
};
