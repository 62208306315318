'use strict';

import withApolloLoader from '../with-apollo-loader';

import TeacherSession from '../../components/screens/teacher-session';

import teacherSessionQuery from '../../queries/teacher-session';

export default withApolloLoader({
  query: teacherSessionQuery,
  getVariables: ({match: {params: {code}}}) => ({code}),
  getChildProps: ({match: {params: {code}}}) => ({code}),
  transform: ({TeacherEventSession}) => {
    const session = TeacherEventSession;
    return {session};
  },
  LoaderComponent: () => <div>Trainingssessie wordt ingeladen...</div>
})(TeacherSession);
