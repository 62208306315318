'use strict';

import styled from 'styled-components';

const Preview = styled.img`
  height: 99px;
  width: 99px;
  border-radius: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #666;
  margin-bottom: 10px;
`;

export default class extends React.Component {
  renderPreview() {
    if (!this.props.value) {
      return;
    }

    return <Preview src={this.props.value}/>;
  }

  render() {
    return (<this.props.templates.formElement {...this.props}>
      {this.renderPreview()}
      <input type="file"
        className="form-element-file"
        invalid={(this.props.errors && this.props.errors.length) ? 'invalid' : undefined}
        accept={this.props.schema.accept ? this.props.schema.accept : null}
        onBlur={this.props.onBlur(this.props)}
        onChange={event => {
          event.preventDefault();
          const files = event.target.files;
          if (files.length && files[0].type && this.props.schema.mimes.indexOf(files[0].type) < 0) {
            event.target.value = null;
            return;
          }
          return this.props.onChange(event);
        }}
      />
    </this.props.templates.formElement>);
  }
}
