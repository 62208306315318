'use strict';

import { NavLink, Route } from 'react-router-dom';

import ContentHeading from '../content-heading';
import TabGroup from '../tab-group';

import MyVisitedEducations from '../../containers/screens/my-visited-educations';
import MyUpcomingEducations from '../../containers/screens/my-upcoming-educations';
import Placeholder from "../placeholder";

export default class extends React.Component {
  render() {
    return (
    	<div>
    		<ContentHeading title="Mijn opleidingen">
          <TabGroup>
            <NavLink
              activeClassName="active"
              exact
              to="/opleidingen"
            >Actueel</NavLink>
            <NavLink
              activeClassName="active"
              to="/opleidingen/bezocht"
            >Historie</NavLink>
          </TabGroup>
        </ContentHeading>
        <Route
          exact
          path="/opleidingen"
          component={MyUpcomingEducations}
        />
        <Route
          exact
          path="/opleidingen/bezocht"
          component={MyVisitedEducations}
        />
        <Placeholder position="PH_COP-opl_wide"/>
    	</div>
    );
  }
}
