'use strict';

import styled from 'styled-components';
import { withRouter, Route, Redirect } from 'react-router-dom';
import { graphql } from 'react-apollo';

import Layout from './components/layout';
import TeacherDashboard from './containers/screens/teacher-dashboard';
import TeacherSession from './containers/screens/teacher-session';
import MyActivities from './containers/screens/my-activities';
import MyProducts from './components/screens/my-products';
import Subscription from './containers/screens/subscription';
import MyCongresses from './components/screens/my-congresses';
import MyEducations from './components/screens/my-educations';
import EducationDetails from './containers/screens/education-details';
import CongressDetails from './containers/screens/congress-details';
import MyInterests from './containers/screens/my-interests';
import MyData from './components/screens/my-data';
import MySupportRequests from './components/screens/my-support-requests';
import MyInterestsUpdate from './containers/screens/my-interests-update';
import Form from './containers/screens/form-io';
import Footer from "./components/footer";
import ErrorPage from './components/error-page';
import eventScheduleQuery from './queries/event-schedule';

const Wrapper = styled.div`
	height: 100%;
`;

@withRouter
@graphql(eventScheduleQuery)
export default class extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hasError: false
		};
	}

	componentDidCatch(error, info) {
		this.setState({ hasError: true })
	}

	componentDidMount() {
		const { history } = this.props;

		history.listen((location, action) => {
			// reset error when we move to another route
			if (this.state.hasError) this.setState({ hasError: false });
		});
	}

	render() {
		const { state: { hasError } } = this;

		let ipLogin = false;
		const jwtPayload = (localStorage.accessToken || '').split('.')[1];
		if (jwtPayload) {
			const payload = JSON.parse(atob(jwtPayload));
			if (payload.logintype === 'iplogin') {
				ipLogin = true;
			}
		}

    if (ipLogin || hasError) {
			return (
				<Wrapper>
					<Layout>
						<ErrorPage />
					</Layout>
				</Wrapper>
			);
		}

		return (<Wrapper>
			<Layout>
				<Route exact path="/" render={() => <Redirect to="/gegevens" />} />
				<Route path="/docent" exact component={TeacherDashboard} />
				<Route path="/docent/historie" exact component={TeacherDashboard} />
				<Route path="/docent/sessie/:code" exact component={TeacherSession} />
				<Route path="/docent/sessie/:code/materiaal" exact component={TeacherSession} />
				<Route path="/docent/sessie/:code/materiaal/:id" exact component={TeacherSession} />
				<Route path="/activiteiten" exact component={MyActivities} />
				<Route path="/producten/abonnementen/:title/:id" component={Subscription} />
				<Route path="/(producten|producten/losse-producten|producten/nieuwsbrieven)" exact component={MyProducts} />
				<Route path="/opleiding/:id" exact component={EducationDetails} />
				<Route path="/opleiding/:id/materiaal" exact component={EducationDetails} />
				<Route path="/opleiding/:id/materiaal/:document" exact component={EducationDetails} />
				<Route path="/opleiding/:id/(evaluatieformulier|voorbereidingsformulier)" exact component={Form} />
				<Route path="/opleidingen" exact component={MyEducations} />
				<Route path="/opleidingen/bezocht" exact component={MyEducations} />
				<Route path="/congres/:id" exact component={CongressDetails} />
				<Route path="/congres/:id/materiaal" exact component={CongressDetails} />
				<Route path="/congressen" exact component={MyCongresses} />
				<Route path="/congressen/bezocht" exact component={MyCongresses} />
				<Route path="/advies" exact component={MySupportRequests} />
				<Route path="/advies/afgerond" exact component={MySupportRequests} />
				<Route path="/advies/:id" exact component={MySupportRequests} />
				<Route path="/interesses" exact component={MyInterests} />
			  <Route path="/interesses/aanpassen" component={MyInterestsUpdate} />
				<Route path="/gegevens" component={MyData} />
				{/*<Route path="/styleguide" component={StyleGuide}/>*/}
			</Layout>
			<Footer />
		</Wrapper>);
	}
}
