'use strict';

import {withApollo} from 'react-apollo';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import LoginDetails from '../../components/screens/login-details';
import loginDetailsSchema from '../../schema/login-details';

@withApollo
@graphql(gql`query {
  User: currentUser {
    id
    name
  }
}`, {
  props: ({data: {User}, ownProps: {client}}) => ({User, client})
})
@graphql(gql`mutation ($id: ID!, $user: UserUpdateInput!) {
  updateUser(id: $id, input: $user) {
    id
  }
}`, {
  name: 'updateUser'
})
export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errors: [],
      messages: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setMessage = this.setMessage.bind(this);
  }

  setMessage(message) {
    this.setState({
      loading: false,
      messages: [message]
    });

    setTimeout(() => this.setState({messages: []}), 5000);
  }

  handleSubmit(data) {
    this.setState({loading: true, errors: []});

    if (typeof data.newPassword !== 'undefined' && data.newPasswordConfirm && data.newPassword === data.newPasswordConfirm) {
      if (!data.password) {
        // Current password is required when changing password.
        this.setState({loading: false, errors: ['Vul uw huidige wachtwoord in om uw wachtwoord te wijzigen']});
        return;
      }

      return this.props.updateUser({
        variables: {
          id: this.props.User.id,
          user: {
            new_password: data.newPassword,
            password: data.password
          }
        }
      }).then(() => {
        this.setMessage('Uw wachtwoord is succesvol aangepast.');

        return {
          ...data,
          password: '',
          newPassword: '',
          newPasswordConfirm: ''
        };
      }).catch(e => {
        this.setState({ loading: false, errors: [e.message.replace('GraphQL error: ', '')] });
        return {
          ...data,
          password: '',
          newPassword: '',
          newPasswordConfirm: ''
        };
      });
    }
    this.setState({loading: false});
  }

  render() {
    if (!this.props.User || typeof this.props.User === 'undefined') {
      return null;
    }

    const value = {
      name: this.props.User.name
    };

    return (<LoginDetails
      schema={loginDetailsSchema}
      value={value}
      handleSubmit={this.handleSubmit}
      loading={this.state.loading}
      errors={this.state.errors}
      messages={this.state.messages}
      />);
  }
}
