'use strict';

import ContentHeading from './content-heading';
import {BackAnchor} from './buttons';
import {withRouter} from "react-router-dom";

@withRouter
export default class extends React.Component {
  static defaultProps = {
    title: 'Formulier',
    backTitle: 'Terug',
    data: {},
    onSubmit: () => {}
  };

  constructor(props) {
    super(props);
    this.iframe = React.createRef();
  }

  componentDidMount() {
    this.iframe.current.setAttribute('height', getComputedStyle(this.iframe.current.parentNode).height);
    window.addEventListener('message', this.props.onSubmit, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.props.onSubmit);
  }

  render() {
    const {
      title,
      backTitle,
      url,
      data
    } = this.props;

    const query = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');

    return (
      <div>
        <ContentHeading title={title}>
          <BackAnchor onClick={e => {
            e.preventDefault();
            this.props.history.goBack();
          }}>{backTitle}</BackAnchor>
        </ContentHeading>
        <div>
          <iframe id="formio-form" style={{
            width: '100%',
            border: 'none'
          }} height="500px" ref={this.iframe} src={`/form?form=${encodeURIComponent(url)}&${query}`}/>
        </div>
      </div>
    );
  }
}
