'use strict';

export default class extends React.Component {
  render() {
    return (<div>
      {this.props.rest && (<div>
        <div>
          {this.props.rest}
        </div>
      </div>)}
      {this.props.groups.map(({title, element}) => (<fieldset key={title}>
        <legend>{title}</legend>
        <div>
          {element}
        </div>
      </fieldset>))}
    </div>);
  }
}
