'use strict';

'use strict';

import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import MailingDetails from '../../components/screens/mailing-details';
import settings from '../../settings';

const query = gql`query GetUserMailings {
  mailings: listMailing(order: {
    field: "title"
  }) {
    items {
      id
      title
      description
    }
  }

  User: currentUser {
    id
    mailings {
      items {
        id
        mailing {
          id
        }
      }
    }
  }
}`;

@graphql(query, {
  props: ({data: {User, mailings}, ownProps: {client}}) => ({User, mailings, client})
})
@graphql(gql`
  mutation( $id: ID! ) {
    deleteUserMailing( id: $id ) { id }
  }
`, {
  name: 'deleteUserMailing',
})
@graphql(gql`
  mutation( $userId: ID!, $mailing: ID! ) {
    createUserMailing( input: { user: $userId, mailing: $mailing } ) {
      id
    }
  }
`, {
  name: 'createUserMailing'
})
export default class extends React.Component {
  render() {
    if (!this.props.User || !this.props.mailings.items) {
      return null;
    }

    const {
      id: userId,
      mailings: {items: UserMailings}
    } = this.props.User;

    const mailings = this.props.mailings.items.map(item => {
      const UserMailing = UserMailings.find(n => n.mailing.id === item.id);

      const mailing = {
        ...item,
        description: settings.mailingsDescription[item.id] ? settings.mailingsDescription[item.id]: item.description,
        enabled: Boolean(UserMailing),
        toggle: () => {
          if (mailing.enabled) {
            this.props.deleteUserMailing({
              variables: {id: UserMailing.id},
              refetchQueries: ['GetUserMailings']
            });
          } else {
            this.props.createUserMailing({
              variables: {userId, mailing: mailing.id},
              refetchQueries: ['GetUserMailings']
            });
          }
        }
      };

      if (mailing.enabled) {
        mailing.subscriptionId = UserMailing.id;
      }

      return mailing;
    });

    return (<MailingDetails mailings={mailings}/>);
  }
}
