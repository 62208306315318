'use strict';

import styled from 'styled-components';
import sanitize from '../../lib/sanitize';
import {Link} from 'react-router-dom';

import Tags from '../tags';
import PropertyList from '../property-list';

const Item = styled(Link)`
  display: flex;
  flex-direction: row;
  margin: 20px 30px 20px 0;

  &:link, &:visited {
    text-decoration: none;
  }

  :first-child div.t-rail:before {
    top: 45px;
  }

  :last-child div.t-rail:before {
    top: auto;
    bottom: 45px;
  }

  div.t-rail {
    flex-basis: 75px;
    flex-shrink: 0;
    position: relative;
  }

  div.box {
    background-color: ${props => props.theme.backgroundGray};
    padding: 16px;
    color: ${props => props.theme.almostBlack};
    max-width: 800px;

    h1 {
      margin-top: 0;
      font-size: 19px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 0;
      font-size: 15px;
    }
  }
`;

export default class extends React.Component {
  render() {
    const {city, description, id, showRail, tags, timing, title, to, type} = this.props;

    return (
      <Item key={id} to={to}>
        { showRail ? <div className="t-rail"/> : null }
        <div className="box">
          <h1>
            <span className="title" dangerouslySetInnerHTML={sanitize(title)}/>
            { tags ? <Tags tags={tags}/> : null }
          </h1>
          <PropertyList
            properties={[
              {value: type, className: 'type'},
              {value: city},
              {value: timing, className: 'timing'}
            ]}
            />
          <p dangerouslySetInnerHTML={sanitize(description)}/>
        </div>

      </Item>
    );
  }
}
