'use strict';

import styled from 'styled-components';
import {withApollo} from 'react-apollo';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import postcodeApi from '../../lib/postcode';
import PersonalDetails from '../../components/screens/personal-details-update';
import SubscriptionDetailsSchema from '../../schema/subscription-details';
import UserSubscriptionQuery from '../../queries/user-subscription';

const Form = styled.div`
  header {
    margin-bottom: 20px;

    h1 {
      padding-bottom: 0 !important;
    }

    a {
      margin-left: 40px;
      text-decoration: none;
    }
  }

  > div {
    p, h2, td {
      color: ${props => props.theme.almostBlack};
    }
    a {
      color: ${props => props.theme.activeBlue};
    }
  }

  > div > p:first-child {
    margin-top: 0;
  }

  .form-key-note {
    display: none !important;
  }
  .show-note-field .form-key-note {
    display: flex !important;
  }
  div.form-element-wrapper div.form-element-wrapper.form-key-shippingAddress {
    display: block;
  }
`;

const queryOptions = ({match: {params: {id: userSubscriptionId}}}) => ({variables: {userSubscriptionId}});

@withApollo
@graphql(UserSubscriptionQuery, {
  props: ({data: {UserSubscription, User, loading}, ownProps: {match: {params: {id: userSubscriptionId}}}}) => ({
    ...UserSubscription,
    user: User,
    loading,
    userSubscriptionId
  }),
  options: queryOptions
})
@graphql(gql`mutation (
  $userSubscriptionId: String!
  $billingAddressDisabled: Boolean!
  $billingAddress: JSON!
  $shippingAddress: JSON!
  $title: String!
  $userId: String!
  $userMail: String!
) {
  updateUserSubscriptionAddress(
    userSubscriptionId: $userSubscriptionId,
    billingAddressDisabled: $billingAddressDisabled,
    billingAddress: $billingAddress,
    shippingAddress: $shippingAddress,
    title: $title,
    userId: $userId,
    userMail: $userMail
  )
}`, {
  name: 'update'
})
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      populated: false,
      loading: false,
      messages: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  getAddressProperties() {
    return [
      'address',
      'billingAddress',
    ];
  }

  setMessage(message) {
    this.setState({
      loading: false,
      messages: [message],
    });

    setTimeout(() => this.setState({messages: []}), 5000);
  }

  componentDidUpdate() {
    if ((!this.state || (!this.state.populated)) && this.props.User) {
      const newState = {populated: true};

      this.getAddressProperties().forEach(type => {
        const {houseNumber = '', postalCode = ''} = this.props.User[type] || {};
        newState[type] = {houseNumber, postalCode};
      });

      this.setState(newState);
    }
  }

  handleBlur(e, props, values) {
    const {target: {value}} = e;

    const type = ['houseNumber', 'houseNumberSuffix', 'postalCode'].find(type => props.name === type);

    if (type) {
      const addressProperty = (this.getAddressProperties()
        .filter(addressType => addressType !== 'address')
        .find(addressType => new RegExp(`/${addressType}/${type}`).test(props.path)) || 'address');

      if (value !== this.state[addressProperty][type]) {
        this.setState({[addressProperty]: {
          ...this.state[addressProperty],
          [type]: value
        }});

        const {postalCode, houseNumber} = values[addressProperty];

        if (postalCode && postalCode.length > 0 && houseNumber && houseNumber.length > 0) {
          return new Promise((resolve) => {
            postcodeApi(postalCode, houseNumber)
              .then(({street, city}) => {
                values[addressProperty] = {
                  ...values[addressProperty],
                  street,
                  city
                };

                return resolve(values);
              }).catch(() => resolve(values));
          });
        }
      }
    }

    return values;
  }

  handleSubmit(data) {
    this.setState({loading: true});
    let {
      billingAddressDisabled,
      billingAddress,
      shippingAddress
    } = data;

    this.props.update({
      variables: {
        userSubscriptionId: this.props.match.params.id,
        billingAddressDisabled,
        billingAddress,
        shippingAddress,
        title: this.props.title,
        userId: this.props.user.id,
        userMail: this.props.user.mail || this.props.user.eventMail
      }
    }).then(() => {
      this.setState({loading: false});
      this.setMessage('Uw aanvraag is ontvangen.');
    });
  }

  render() {
    if (!this.props.user || typeof this.props.user === 'undefined') {
      return <div><p>Uw gegevens worden ingeladen...</p></div>;
    }

    const productBilling = this.props.billingAddress;
    const userBilling = this.props.user.billingAddress;
    const billingAddress = productBilling && productBilling.city ? productBilling : (userBilling || {
      postalCode: '',
      houseNumber: '',
      houseNumberSuffix: '',
      street: '',
      locality: ''
    });
    const productShipping = this.props.shippingAddress;
    const userShipping = this.props.user.shippingAddress;
    const shippingAddress = productShipping && productShipping.city ? productShipping : (userShipping || {
      postalCode: '',
      houseNumber: '',
      houseNumberSuffix: '',
      street: '',
      locality: ''
    });

    const value = {
      shippingAddress,
      billingAddress,
      billingAddressDisabled:
        ['postalCode', 'city', 'houseNumber', 'houseNumberSuffix', 'street'].reduce((identical, key) => {
          return identical && shippingAddress[key] === billingAddress[key]
        }, true)
    };

    return (
      <Form>
        <PersonalDetails
          schema={SubscriptionDetailsSchema}
          value={value}
          handleSubmit={this.handleSubmit}
          onBlur={this.handleBlur}
          loading={this.state.loading}
          messages={this.state.messages}
        />
      </Form>
    );
  }
}
