'use strict';

import MyActivities from '../my-activities';
import moment from '../../lib/moment';

export default class extends React.Component {
  componentDidMount() {
    localStorage.setItem('activity_check', moment().format('YYYY-MM-DD'));
  }

  constructor(props) {
    super(props);

    this.state = {
      filters: {
        FAVORITE: true,
        DOWNLOAD: true,
        ELEARNING: true,
        ADVIES_DESK: true
      }
    };
  }

  changeFilter(type, value = false) {
    return this.setState({
      filters: {
        ...this.state.filters,
        [type]: value
      }
    });
  }

  clearFilters() {
    this.setState({
      filters: Object.keys( this.state.filters ).reduce( ( memo, name ) => ( memo[ name ] = true && memo ), {} )
    });
  }

  render() {
    return <MyActivities
      changeFilter={this.changeFilter.bind(this)}
      clearFilters={this.clearFilters.bind(this)}
      filters={this.state.filters}
    />
  }
}
