import styled from 'styled-components';

export default styled.div`
  height: 55px;
  overflow: visible;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  margin-bottom: -1px;
  padding-bottom: 1px;

  a {
    height: 44px;
    min-width: 184px;
    background-color: white;
    color: ${ props => props.theme.darkGray };
    line-height: 44px;
    transition: background-color 0.2s ease-out, color 0.2s ease-out, font-weight 0.2s ease-out;
    text-align: center;
    text-decoration: none;
    border: 2px solid ${ props => props.theme.backgroundGray };
    border-bottom: none;
    box-sizing: border-box;
    margin-right: 4px;
    border-radius: 10px 10px 0 0;

    &.active {
      font-weight: bold;
      color: black
      background-color: ${ props => props.theme.backgroundGray };
    }

    @media (max-width: ${props => props.theme.breakpoints.tiny}px) {
      min-width: 140px;
    }
  }
`;
