import gql from "graphql-tag";

export const userLicensesQuery = gql`query {
  currentUser {
    id
    licenses: subscriptions {
      items {
        id
        title
        url
        isOwner
        media
        active
        allowedDomains
        maxLicenses
        licensesLeft
				licensees {
          items {
            id
            mail
            media
            status
          }
        }
      }
    }
  }
}`;

export const userLicensesTransform = ({
  currentUser: {
    id: userId,
    licenses: {
      items: licenses
    }
  }
}) => ({
  userId,
  licenses: licenses.filter(({isOwner}) => isOwner).map(({
    licensees: {items: licensees},
    ...license
  }) => ({
    ...license,
    licensees
  }))
});
