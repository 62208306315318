import EventGeneral from '../event-general';
import EventInfo from '../../containers/event-info';
import CongressGeneralConditionalBlock from '../../containers/congress-general-conditional-block';
import CongressDateLocations from '../../containers/congress-date-locations';
import CongressInfo from '../../containers/congress-info';
import Placeholder from "../placeholder";

export default props => (
  <EventGeneral>
    <EventInfo/>
    <CongressGeneralConditionalBlock {...props}/>
    <CongressDateLocations/>
    <Placeholder position="PH_COP-condetail_left"/>
    <Placeholder position="PH_COP-condetail_right"/>
  </EventGeneral>
);
