export default class extends React.Component {
  render() {
    return (<svg
      width="36px"
      height="30px"
      viewBox="0 0 1000 1000"
      xmlSpace="preserve"
      {...this.props}
    >
      <style>{'.prefix__st1{fill:#f88d3d}'}</style>
      <path
        fill="#F88D3D"
        d="M914.3 148.4H85.7c-11.4 0-20.7 9.3-20.7 20.7v559.3c0 11.4 9.3 20.7 20.7 20.7h328c-.3 47.6-3.6 75.8-40 102.5h252.8c-36.4-26.7-39.8-54.9-40-102.5h328c11.4 0 20.7-9.3 20.7-20.7V169.1c-.2-11.4-9.5-20.7-20.9-20.7zm-15.2 497.3H100.9V183.6h798.2v462.1z"
      />
      <path
        fill="#FFFFFF"
        d="M781 376.4l-255.9 79c-16.4 4.8-33.8 4.8-50.2 0L288.8 398c-7.9 10.6-10.2 21.4-11.1 35.6 8.6 5.2 14.4 14.6 14.4 25.5 0 11.1-6.1 20.8-15.1 25.9l15 114.2c.6 4.4-2.9 8.4-7.4 8.4h-44.5c-4.5 0-7.9-3.9-7.4-8.4l15-114.2c-9-5.1-15.1-14.8-15.1-25.8 0-11.2 6.2-20.9 15.3-26 .9-16.7 3.7-30.8 11.3-44.2L219 376.4c-21.2-6.3-21.1-36.4 0-42.7l255.9-79c16.4-4.8 33.8-4.8 50.2 0l255.9 79c21.2 6.3 21.1 36.4 0 42.7zM533.9 483.7l-.2.1-.2.1c-21.9 6.4-45.1 6.4-67 0l-.2-.1-.2-.1-140-43.2-11.7 77.8c0 32.8 83.1 59.4 185.6 59.4s185.6-26.6 185.6-59.4l-11.7-77.8-140 43.2z"
      />
    </svg>);
  }
}
