'use strict';

import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import {withRouter} from 'react-router-dom';
import {matchPath} from 'react-router';
import Sidebar from '../components/sidebar';

@withRouter
@graphql(gql`
query($since: String!) {
  currentUser {
    id
    activities(
      filters: {
        created_gt: $since
      }
    ) {
      count
    }
  }
}
`, {
  props: ({data}) => {
    if (!data.loading && data.currentUser) {
      return {count: data.currentUser.activities.count}
    }
  },
  options: () => ({variables: {
    since: localStorage.getItem('activity_check') || '2019-01-01'
  }})
})
export default class extends React.Component {
  render() {
    const isCongressActive = !!matchPath(this.props.location.pathname, {
      path: '/congres/(.*)',
      exact: true
    });

    const isEducationActive = !!matchPath(this.props.location.pathname, {
      path: '/opleiding/(.*)',
      exact: true
    });

    const isTeacherActive = !!matchPath(this.props.location.pathname, {
      path: '/docent/(.*)',
      exact: true
    });

    return <Sidebar
      closeMenu={this.props.closeMenu}
      activityCount={this.props.count || 0}
      showResponsiveMenu={this.props.showResponsiveMenu}
      isCongressActive={isCongressActive}
      isEducationActive={isEducationActive}
      isTeacherActive={isTeacherActive}
    />;
  }
}
