'use strict';

export default class extends React.Component {
  render() {
    const schema = this.props.schema;

    const items = schema.enum.map(value => (
      <label className="form-radios-wrapping-label" key={value}>
        <input
          type="radio"
          checked={this.props.value === value}
          value={value}
          onChange={e => {
            this.props.onChange(e.target.value);
          }}
        />
        <span className="form-radios-caption">{value}</span>
      </label>
    ));

    return (<this.props.templates.formElement {... {...this.props, template: this.props.template || 'radios'}}>
      {items}
    </this.props.templates.formElement>);
  }
}
