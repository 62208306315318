import styled from 'styled-components';

const Container = styled.div`
  > h2 {
    margin-top: 0;
    margin-bottom: 10px;
    color: ${props => props.theme.actionBlue};
  }
  
  > .description {
    margin-top: 0;
    color: ${props => props.theme.darkGray};
  }
  
  > .contents {
    margin: 0 20px 20px 0;
    padding-bottom: 20px;
  }

  &.has-background {
    background: white;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 20px;
    
    > .contents {
      margin: 0;
      padding: 0;
    }
  }  
`;

const Block = styled.div`
  margin-bottom: 50px;
  
  @media (max-width: ${ props => props.theme.breakpoints.big }px) {
    margin-bottom: 20px;
  }
`;

export default class extends React.Component {
  static defaultProps = {
    title: false,
    hasBackground: false
  };

  render() {
    const {
      title,
      hasBackground,
      children,
      description
    } = this.props;

    return (<Block className="block">
      <Container className={hasBackground ? 'has-background' : ''}>
        {title && <h2>{title}</h2>}
        {description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} />}

        <div className="contents">
          {children}
        </div>
      </Container>
    </Block>);
  }
}