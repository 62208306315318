import styled from 'styled-components';

const Element = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;

  span {
    flex-grow: 1;
  }

  .type {
    text-transform: capitalize;
  }

	.timing {
		font-family: Roboto, sans-serif;
  }

  @media (max-width: ${props => props.theme.breakpoints.big}px) {
    flex-direction: column;
    span {
      padding-bottom: 5px;
    }
  }
`;

export default class extends React.Component {
  render() {
    const {properties} = this.props;
    return (<Element>
      { properties.map((property, index) => property.hasOwnProperty('value') ? <span key={index} className={property.className}>
        {property.icon || null} {property.label && <span>{property.label}:</span>} {property.value}
      </span> : null) }
    </Element>);
  }
}
