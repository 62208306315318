import styled from 'styled-components';

const Heading = styled.h4`
  border: 1px solid red;
  padding: 10px;
  color: red;
`;

export default class Placeholder extends React.Component {
  render() {
    const {position} = this.props;
    const debug = !!window.location.hash.match(/#debug/);

    return (<div className="placeholder-position" data-position={position}>
      {debug && <Heading>Position: {position}</Heading>}
    </div>);
  }
}