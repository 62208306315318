'use strict';

import PropTypes from 'prop-types';
import createValidator from 'is-my-json-valid';
import {clone} from 'lodash';

import RefEditor from './editors/ref';
import ArrayRefEditor from './editors/array-ref';
import ArrayEditor from './editors/array';
import BooleanEditor from './editors/boolean';
import MixedEditor from './editors/mixed';
import NullEditor from './editors/null';
import NumberEditor from './editors/number';
import ObjectEditor from './editors/object';
import TextfieldEditor from './editors/textfield';
import FileEditor from './editors/file';
import TextareaEditor from './editors/textarea';
import RadiosEditor from './editors/radios';
import DropdownEditor from './editors/dropdown';
import OneOfEditor from './editors/one-of';
import StaticEditor from './editors/static';
import GroupsEditor from './editors/groups';
import CheckboxesEditor from './editors/checkboxes';

export default class extends React.Component {
  static propTypes = {
    schema: PropTypes.object.isRequired,
    elements: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    context: PropTypes.object,
    getSubContext: PropTypes.func,
    fullSchema: PropTypes.object,
    templates: PropTypes.object.isRequired,
    deleteable: PropTypes.bool,
    onValidate: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    validateImmediately: PropTypes.bool,
    formatError: PropTypes.func,
    path: PropTypes.string,
    setChange: PropTypes.func
  }

  static defaultProps = {
    name: null,
    required: false,
    path: '',
    elements: [],
    value: null,
    context: {},
    getSubContext: context => context,
    fullSchema: null,
    deleteable: false,
    onValidate: () => {},
    onBlur: () => {},
    validateImmediately: true,
    formatError: error => ({
      ...error,
      message: typeof error.schema.validation !== 'undefined' ? error.schema.validation : error.message
    }),
    setChange: () => {}
  }

  constructor(props) {
    super(props);
    const elements = [
      ...(props.elements || []),
      {weight: 9, component: StaticEditor, applies: schema => typeof schema.const !== 'undefined'},
      {weight: 10, component: RefEditor, applies: schema => typeof schema.$ref === 'string'},
      {weight: 10, component: ArrayRefEditor, applies: schema => schema.type === 'array' && schema.items && typeof schema.items.$ref === 'string'},
      {weight: 10, component: OneOfEditor, applies: schema => Array.isArray(schema.oneOf)},
      {weight: 10, component: CheckboxesEditor, applies: schema => schema.type === 'array' && schema.uniqueItems && typeof schema.items === 'object' && schema.items !== null && Array.isArray(schema.items.enum)},
      {weight: 10, component: FileEditor, applies: schema => schema.type === 'string' && schema.upload === true},
      {weight: 11, component: ArrayEditor, applies: schema => schema.type === 'array'},
      {weight: 11, component: BooleanEditor, applies: schema => schema.type === 'boolean'},
      {weight: 11, component: NullEditor, applies: schema => schema.type === 'null'},
      {weight: 11, component: NumberEditor, applies: schema => schema.type === 'number' || schema.type === 'integer'},
      {weight: 11, component: GroupsEditor, applies: schema => schema.type === 'object' && Array.isArray(schema.groups)},
      {weight: 12, component: ObjectEditor, applies: schema => schema.type === 'object'},
      {weight: 12, component: RadiosEditor, applies: schema => schema.type === 'string' && Array.isArray(schema.enum) && schema.enum.length <= 5},
      {weight: 13, component: DropdownEditor, applies: schema => schema.type === 'string' && Array.isArray(schema.enum)},
      {weight: 14, component: TextfieldEditor, applies: schema => schema.type === 'string' && ((schema.maxLength || Infinity) <= 255 || schema.format)},
      {weight: 15, component: TextareaEditor, applies: schema => schema.type === 'string'},
      {weight: 99, component: MixedEditor, applies: schema => typeof schema.const === 'undefined'}
    ].sort((a, b) => a.weight - b.weight);

    this.state = {
      elements,
      errors: false,
      validated: false
    };

    this.getValidationErrors = this.getValidationErrors.bind(this);
    this.validate = this.validate.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getEmptyValue(type) {
    const empty = {
      string: '',
      number: 0,
      integer: 0,
      boolean: false,
      null: null,
      array: [],
      object: {}
    };
    return clone(empty[type]);
  }

  getValidationErrors(value = this.props.value) {
    let errors = [];
    try {
      if (!value) {
        value = this.getEmptyValue((this.props.schema || {}).type);
      }

      const validator = createValidator(this.props.schema);
      const filter = createValidator.filter(this.props.schema);

      // Remove optional text values that are empty.
      if (this.props.schema.type === 'object' && typeof value === 'object' && value !== null && !Array.isArray(value)) {
        const required = this.props.schema.required || [];
        Object.keys(value).forEach(name => {
          if (value[name] === '' && required.indexOf(name) < 0) {
            delete value[name];
          }
        });
      }

      if (this.props.schema.title === 'E-mailadres facturatie' && value === '') {
        return false;
      }

      const valid = validator(filter(value));

      if (valid) {
        return false;
      }

      const {errors: unformatted} = validator;

      errors = (unformatted || []).map(error => {
        const {label, schema} = this.props;
        return this.props.formatError({
          ...error,
          label,
          schema
        });
      });
    } catch (err) {
      console.error('Invalid schema: ' + err.message);
    }

    return errors;
  }

  validate(value = this.props.value) {
    const errors = this.getValidationErrors(value);

    this.props.onValidate({errors, value});

    this.setState({
      ...this.state,
      errors,
      validated: true
    });

    return errors.length === 0;
  }

  componentWillReceiveProps(props) {
    if (props.value && props.value !== this.props.value) {
      this.validate(props.value);
    }
  }

  onChange(e) {
    this.validate(e);
    this.props.onChange(e);
  }

  componentDidMount() {
    if (this.props.schema.type && typeof this.props.value === 'undefined') {
      this.props.onChange({
        value: this.getEmptyValue((this.props.schema || {}).type)
      });
    }

    if (this.props.validateImmediately) {
      this.onChange(this.props.value);
    }

    this.props.setChange(this.validate, this.props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.value !== nextProps.value) {
      return true;
    }
    if (this.props.schema !== nextProps.schema) {
      return true;
    }
    if (this.state.errors !== nextState.errors) {
      return true;
    }
    return true;
  }

  render() {
    const {schema, value, fullSchema} = this.props;
    const {elements, errors} = this.state;

    const Element = elements.reduce((prev, element) => !prev && element.applies(schema || {}) ? element : prev, null);
    if (!Element) {
      return null;
    }

    return (<Element.component
      required={this.props.required}
      name={this.props.name}
      path={this.props.path}
      deleteable={this.props.deleteable}
      label={this.props.label}
      elements={this.props.elements}
      schema={schema}
      fullSchema={fullSchema || schema || {}}
      value={value}
      errors={errors}
      context={this.props.context || {}}
      templates={this.props.templates}
      getSubContext={this.props.getSubContext || (context => context)}
      onChange={this.onChange}
      onValidate={this.props.onValidate}
      onBlur={this.props.onBlur}
      validateImmediately={this.props.validateImmediately}
      validate={this.validate}
      validated={this.state.validated}
      formatError={this.props.formatError}
      setChange={this.props.setChange}
    />);
  }
}
