import styled from 'styled-components';
import { useMemo } from 'react';

import Table from './table';
import { SmallCapsuleButton, SlimTransparentButton } from './buttons';

const LicenseDetails = styled.div`
  margin-top: 60px;

  h1 {
    font-size: 24px;
  }

  p {
    line-height: 16px;
    margin: 0;

    &.subdued {
      color: #000;
      margin: 15px;
    }
  }
`;

const LicenseTable = styled(Table)`
  tbody > tr:nth-child(odd)  {
    background: #e8e8e8;
  }

  tr > td:first-child {
    padding-left: 15px;
  }

  td {
    vertical-align: top;
    padding: 15px 0;

    label {
      display: inline-block;
      margin-top: 10px;
      margin-left: 20px;
    }

    *.red { color: #AF1E0B; }
    *.green { color: #7FAF0B; }
    *.blue { color: rgb( 4,86,165 ); }
    *.black { color: #000 }

    &.actions > p {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .new {
      position: relative;
      top: 10px;
    }

    button > i {
      position: relative;
      top: 2px;
      margin-left: 6px;
    }

    input[type="text"] {
      box-sizing: border-box;
      height: 39px;
      text-align: right;
      line-height: 37px;
      font-size: 14px;
      padding: 0 10px;
      color: black;
      border: 1px solid ${props => props.theme.lightGray};
      border-radius: 7px;

      &:placeholder {
        color: ${props => props.theme.mediumGray};
      }
    }
  }
`;

export default ({
  title,
  active,
  id,
  media: licenseMedia,
  licensees,
  maxLicenses,
  licensesLeft,
  allowedDomains,
  usernameToAdd,
  setUsernameToAdd,
  addEmail,
  resendInvitation,
  changeMedia,
  removeLicensee
}) => {

  const licensesLeftReal = maxLicenses - licensees.length;

  const items = useMemo(() => {
    const items = licensees.map(({
      id,
      mail: email,
      status,
      media: licenseeMedia
    }) => ({
      key: id,
      email: <React.Fragment>
        <p>{email}</p>
        {licenseMedia === 'print' ? <p><label><input type="checkbox" checked={licenseeMedia === 'print'} onChange={({ target: { checked } }) => changeMedia(id, checked ? 'print' : 'digital')} /> ontvangt vakblad op papier</label></p> : null}
      </React.Fragment>,
      status: {
        Resend: <span className="black">Uitnodiging opnieuw verzonden</span>,
        Pending: <span className="black">Uitnodiging verzonden</span>,
        Active: <span className="green">Actief</span>
      }[status],
      actions: <React.Fragment>
        {status === 'Pending' ? <p><SlimTransparentButton className="purple" onClick={() => resendInvitation(email)}><i className="rendement rendement-busy"></i> Uitnodiging opnieuw verzenden</SlimTransparentButton></p> : null}
        <p><SlimTransparentButton className="crimson" onClick={() => removeLicensee(id)}><i className="rendement rendement-fail"></i> Verwijderen</SlimTransparentButton></p>
      </React.Fragment>
    }));

    if (licensesLeftReal > 0) {
      items.push({
        key: 'new-license',
        email: <React.Fragment><input type="text" placeholder="e-mailadres" value={usernameToAdd} onChange={({target: {value}}) => setUsernameToAdd(value)}/> {allowedDomains === '' ? '' : '@' + allowedDomains}</React.Fragment>,
        status: <span className="black new">Nieuw</span>,
        actions: <SmallCapsuleButton disabled={!usernameToAdd.length} onClick={addEmail}>Uitnodiging verzenden <i className="rendement rendement-arrow_right"></i></SmallCapsuleButton>
      })
    }

    return items;
  }, [licensees, usernameToAdd]);

  return (
    <LicenseDetails>
      <h1>{title}</h1>
      <LicenseTable
        columns={['email', 'status', 'actions']}
        columnLabels={{
          email: 'E-mailadres',
          status: 'Status',
          actions: 'Acties'
        }}
        columnWidths={{
          email: 2,
          status: 1,
          actions: 1
        }}
        columnTypes={{
          email: 'html',
          status: 'html',
          actions: 'html'
        }}
        items={items}
      />
      {(licensesLeftReal > 0)
        ? <p className="subdued">U kunt nog maximaal <strong>{licensesLeftReal}</strong> account{licensesLeftReal > 1 ? 's' : ''} toevoegen bij dit abonnement.</p>
        : <p className="subdued">U kunt geen accounts meer toevoegen bij dit abonnement.</p>
      }
    </LicenseDetails>
  )
}
