import styled from 'styled-components';
import Date from './date';
import moment from "../lib/moment";
import Block from './block';
import StyledLink from './styled-link';
import {withRouter} from 'react-router-dom';

const WhiteWrapper = styled.div`
  padding: 25px;
  background: white;
  border-radius: 8px;

  p:last-child {
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  h2 {
    margin-bottom: 0;
    margin-top: 0;
    color: ${props => props.theme.actionBlue};
  }

  .contents {
    margin-right: 0;

    > h4 {
      margin-top: 10px;
      color: ${props => props.theme.darkGray};
    }
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;

    p {
      margin-top: 0;
    }
  }

  .date-wrapper {
    margin-right: 30px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    > div:first-child {
      align-self: center;
    }

    .time {
      color: ${props => props.theme.actionOrange};
      font-weight: bold;
    }
  }

  .location {
    > h3 {
      margin-top: 10px;
      margin-bottom: 0;
    }

    > p {
      color: ${props => props.theme.darkGray};
      margin: 0;
      .location-link {
        color: ${props => props.theme.darkGray};
      }
    }
  }
`;

@withRouter
export default class extends React.Component {
  render() {
    const {
      locationInfo,
      eventTeacher,
      title,
      address,
      postalCode,
      city,
      routeUrl
    } = this.props;

    const start = moment(this.props.start, 'YYYY-MM-DD HH:mm:ss');
    const end = moment(this.props.end, 'YYYY-MM-DD HH:mm:ss');

    return (<Container>
      <Block title={false}>
        <WhiteWrapper>
          <h2>{title}</h2>
          <h4>Docent: {eventTeacher.name}</h4>

          <div className="info">
            <div className="date-wrapper">
              <Date date={start} width={75}/>
              <span className="time">{start.format('HH:mm')} - {end.format('HH:mm')}</span>
            </div>

            <div className="location">
              <h3>{locationInfo}</h3>
              {address && address !== 'Postbus 0' && (<p>{address}</p>)}
              {postalCode && postalCode !== '0000 ZZ' && (<p>{postalCode}, {city}</p>)}
              {routeUrl && <p><a className="location-link" href={routeUrl} target="_blank">Routebeschrijving downloaden</a></p>}
            </div>
          </div>

          <p>
            <StyledLink to={`${this.props.match.url}/materiaal`}>Ga naar het materiaal</StyledLink>
          </p>
        </WhiteWrapper>
      </Block>
    </Container>);
  }
}
