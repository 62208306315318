
import {NavLink} from 'react-router-dom';
import ContentHeading from './content-heading';
import TabGroup from './tab-group';

export default () => {
  let ipLogin = false;
  const jwtPayload = (localStorage.accessToken || '').split('.')[1];
  if (jwtPayload) {
    const payload = JSON.parse(atob(jwtPayload));
    if (payload.logintype === 'iplogin') {
      ipLogin = true;
    }
  }

  if (ipLogin) {
    return (
      <div>
        <ContentHeading title="">
        </ContentHeading>
        <div>
          <p>
            Mijn Rendement is het persoonlijke dashboard voor gebruikers en abonnees van
            Rendement Uitgeverij. U bent ingelogd via uw IP-adres omdat de organisatie
            waar u voor werkt voor meerdere medewerkers een abonnement heeft afgesloten.
            U kunt daarom geen persoonlijke- of abonnementsgegevens wijzigen.
            Als u vragen hierover heeft, dan kunt u contact opnemen met uw contactpersoon bij
            ons via <a href="mailto:contractbeheer@rendement.nl">contractbeheer@rendement.nl</a>.
            U kunt natuurlijk ook altijd telefonisch contact opnemen met onze klantenservice
            via <a href="tel:+31102433933">010 – 243 3933</a> (op werkdagen tussen 9:00 en 17:00 uur).
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ContentHeading title="">
      </ContentHeading>
      <div>
        <p>Wegens een technische storing is het op dit moment niet mogelijk om uw gegevens te laden. We doen ons best om dit zo spoedig mogelijk te verhelpen.</p>

        <p>Heeft u vragen? Neem dan contact op met onze klantenservice via telefoonnummer: <a href="tel:+31102433933">010 2433933</a>.</p>
      </div>
    </div>
  );
};
