export default class extends React.Component {
  render() {
    return (<svg className={this.props.className} width="15px" height="9px" viewBox="0 0 15 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1013.000000, -893.000000)" fill="#0456A5" fillRule="nonzero">
          <g transform="translate(438.000000, 366.000000)">
            <g transform="translate(0.000000, 506.000000)">
              <g transform="translate(338.000000, 2.000000)">
                <polygon id="Shape" transform="translate(244.457500, 23.500000) rotate(90.000000) translate(-244.457500, -23.500000) " points="240.165 17.9183333 245.746667 23.5 240.165 29.0816667 241.666667 30.5833333 248.75 23.5 241.666667 16.4166667"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>);
  }
}
