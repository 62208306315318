'use strict';

import EntityForm from '../entity-form';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.handleValidate = this.handleValidate.bind(this);

    this.state = {
      hasError: false
    };
  }

  handleValidate({value}) {
    const errors = [];

    if (typeof value.newPassword !== 'undefined' && value.newPassword) {
      if (typeof value.newPasswordConfirm !== 'undefined') {
        if (value.newPassword !== value.newPasswordConfirm) {
          errors.push('Vul twee keer hetzelfde nieuwe wachtwoord in.');
        }
        if ((value.newPassword || value.newPasswordConfirm) && !value.password) {
          errors.push('Vul uw huidige wachtwoord in om uw wachtwoord te wijzigen.')
        }
      }

      const passwordErrors = [
        {
          check: pw => pw.length < 8,
          msg: '8 karakters'
        },
        {
          check: pw => !pw.match(/[a-z]/),
          msg: '1 kleine letter'
        },
        {
          check: pw => !pw.match(/[A-Z]/),
          msg: '1 hoofdletter'
        },
        {
          check: pw => !pw.match(/[0-9]/),
          msg: '1 cijfer'
        }
      ].filter(item => item.check(value.newPassword)).map(item => item.msg);

      if (passwordErrors.length > 0) {
        let errorMessage;

        if (passwordErrors.length === 1) {
          errorMessage = passwordErrors.shift();
        }
        else {
          const lastError = passwordErrors.pop();
          errorMessage = `${passwordErrors.join(', ')} en ${lastError}`;
        }

        errors.push(`Vul uw wachtwoord aan zodat het tenminste ${errorMessage} bevat.`);
      }
    }

    return errors.length > 0 ? errors : false;
  }

  render() {
    return (<div>
      <EntityForm
        schema={this.props.schema}
        value={this.props.value}
        handleSubmit={this.props.handleSubmit}
        onValidate={this.handleValidate}
        loading={this.props.loading}
        errors={this.props.errors}
        messages={this.props.messages}
        maxWidth={550}
      />
    </div>);
  }
}
