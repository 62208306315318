import { useRef, useEffect } from 'react';

function usePortal(id) {
  const rootElemRef = useRef(null);

  let getRootElem = () => {
    rootElemRef.current = document.createElement('div');
    getRootElem = () => rootElemRef.current;
    return rootElemRef.current;
  };

  useEffect(() => {
    document.getElementById(id).appendChild(rootElemRef.current);

    return () => {
      rootElemRef.current.remove();
    };
  }, []);

  return getRootElem();
}

export default usePortal;
