'use strict';

import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import UserWidget from '../components/user-widget';

@graphql(gql`query {
  User: currentUser {
    id
    avatar {
      items {
        id
        data
      }
    }
    initials
    infix
    lastName
    companyName
  }
}`, {
  props: ({data: {User}}) => ({User})
})
export default class extends React.Component {
  handleLogout() {
    let accessToken = '';
    if (typeof localStorage !== 'undefined') {
      accessToken = localStorage.accessToken;
      delete localStorage.accessToken;
      delete localStorage.accessTokenExpires;
    }

    document.location = `/logout?token=${accessToken}`;
  }

  render() {
    if (this.props.User === null) {
      this.handleLogout();
    }
    if (!this.props.User || typeof this.props.User === 'undefined') {
      return null;
    }

    const {companyName, avatar} = this.props.User;

    const fullName = ['initials', 'infix', 'lastName']
      .filter(property => typeof this.props.User[property] !== 'undefined' && this.props.User[property] !== null && this.props.User[property].length > 0)
      .map(property => this.props.User[property])
      .join(' ');

    let avatarData = '/assets/avatar.png';
    if (avatar && avatar.items.length > 0) {
      avatarData = avatar.items[0].data;
    }

    return <UserWidget
      avatar={avatarData}
      title={fullName}
      subTitle={companyName}
      onClickLogout={this.handleLogout}
    />;
  }
}
