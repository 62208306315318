'use strict';

import c from '../lib/classer';

import Header from './header';
import Sidebar from '../containers/sidebar';
import styled from 'styled-components';
import { Hamburger } from './svg/';

const VerticalDivider = styled.div`
	display: flex;
	flex-direction: column;

	div.overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 100%;
		background-color: rgba( 0, 0, 0, 0.3 );
		transition: opacity 0.4s ease-in-out;
		opacity: 0;

		&.visible {
			right: 0;
			opacity: 1;
		}
	}

	div.open-menu {
		display: none;
		position: absolute;
		top: -35px;
		left: 10px;
		width: 50px;
		height: 50px;
	}

	#layout-horizontal-divider {
		display: flex;
		flex-grow: 1;
		position: relative;

		> section.content {
			flex: 1;
			position: relative;

			> div {
				height: 100%;
				display: flex;
				flex-direction: column;

				> div:nth-child(2) {
					padding-bottom: 30px;
					padding-left: 40px;
					padding-top: 40px;
					padding-right: 80px;

					&:not(.transparent) {
						background-color: ${ props => props.theme.backgroundGray };
					}

					&.scrollview {
						flex-grow: 1;
						padding-top: 0;
					}

					&:not(.scrollview) {
						margin-right: 80px;
						position: relative;
					}
				}
			}
		}
	}

	@media (max-width: ${ props => props.theme.breakpoints.big }px) {
		div.open-menu {
			display: inline;
		}

		#layout-horizontal-divider {
			flex-direction: column;

			> section.content {
				height: 100%;
				margin-left: 40px;

				&:before {
					display: none;
				}

				> div > div:nth-child(2) {
					padding-right: 40px;

					&:not(.scrollview) {
					  margin-right: 40px;
					}
				}
			}
		}
	}

	@media (max-width: ${props => props.theme.breakpoints.small}px) {
		#layout-horizontal-divider > section.content {
			margin-left: 0;
			
			> div > div:nth-child(2):not(.scrollview) {
				margin-right: 0;
			}
		}
	}
`;

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showResponsiveMenu: false
    };
  }
  toggleMenu() {
    this.setState( { showResponsiveMenu: !this.state.showResponsiveMenu } );
  }
  render() {
    const { showResponsiveMenu } = this.state;
    const { children } = this.props;

    return (<VerticalDivider>
      <Header/>
      <div className="site-container">
        <div id="layout-horizontal-divider">
          <div className="open-menu" onClick={this.toggleMenu.bind( this )}>
            <Hamburger/>
          </div>
          <Sidebar showResponsiveMenu={showResponsiveMenu} closeMenu={this.toggleMenu.bind( this )}/>
          <section className="content">
            { children }
          </section>
        </div>
      </div>
      <div className={ c( { overlay: true, visible: showResponsiveMenu } ) } onClick={this.toggleMenu.bind( this )}></div>
    </VerticalDivider>);
  }
}
