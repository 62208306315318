'use strict';

export default class extends React.Component {
  render() {
    const props = {
      ...this.props,
      value: typeof this.props.value === 'number' ? this.props.value.toString() : '',
      maxLength: 12,
      template: 'number'
    };

    return <this.props.templates.textfield {...props}/>;
  }
}
