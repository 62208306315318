'use strict';

import {NavLink, Route} from 'react-router-dom';

import ContentHeading from '../content-heading';
import TabGroup from '../tab-group';
import LoginDetails from '../../containers/screens/login-details';
import PersonalDetails from '../../containers/screens/personal-details';
import PersonalDetailsUpdate from '../../containers/screens/personal-details-update';
import MailingDetails from '../../containers/screens/mailing-details';

export default ({userOwnsLicense}) => (
  <div>
    <ContentHeading title="Mijn gegevens">
      <TabGroup>
        <NavLink
          activeClassName="active"
          exact
          to="/gegevens"
        >
          Persoonsgegevens
        </NavLink>
        <NavLink
          activeClassName="active"
          exact
          to="/gegevens/login"
        >
          Inloggegevens
        </NavLink>
        {/* {<NavLink
          activeClassName="active"
          exact
          to="/gegevens/email"
        >
          E-mailvoorkeuren
        </NavLink>} */}
      </TabGroup>
    </ContentHeading>
    <Route
      exact
      path="/gegevens"
      component={PersonalDetails}
    />
    <Route
      exact
      path="/gegevens/persoonsgegevens"
      component={PersonalDetails}
    />
    <Route
      exact
      path="/gegevens/wijzigen"
      component={PersonalDetailsUpdate}
    />
    <Route
      exact
      path="/gegevens/login"
      component={LoginDetails}
    />
    <Route
      exact
      path="/gegevens/email"
      component={MailingDetails}
    />
  </div>
);
