import Block from './block';
import {ChevronCapsuleButton} from "./buttons";

import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  padding: 25px 0;
`;

export default class extends React.Component {
  render() {
    const {
      description,
      uri
    } = this.props;

    return (<Block title="Evaluatieformulier" description={description} hasBackground>
      <Container>
        <ChevronCapsuleButton to={uri}>Vul evaluatieformulier in</ChevronCapsuleButton>
      </Container>
    </Block>);
  }
}