'use strict';

export default class extends React.Component {
  render() {
    const {
      errors,
      label,
      children,
      name,
      templates,
      schema,
      required
    } = this.props;

    const classNames = [
      'form-element-wrapper',
      `form-${schema.type}`,
      `form-key-${name}`
    ];

    if (Boolean(errors)) {
      classNames.push('has-error');
    }

    if (required) {
      classNames.push('required');
    }

    if (this.props.template) {
      classNames.push(`form-template-${this.props.template}`);
    }

    return (<div className={classNames.join(' ')}>
      {label && <label className="form-element-label">{label}</label>}
      <div className="form-element">
        {children}
        {errors && <ul className="form-element-errors">{errors.map(error => <li className="form-element-error-wrapper" key={error.message}><templates.error>{error.message}</templates.error></li>)}</ul>}
        {schema.description && <templates.description>{this.props.schema.description}</templates.description>}
      </div>
    </div>);
  }
}
