import { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import fetch from 'isomorphic-fetch';
import gql from 'graphql-tag';
import Dialog from '../components/dialog';

const Wrapper = styled.div`
  input {
    display: none;
  }
  .upload-drop button {
    border: 6px dashed ${props => props.theme.actionBlue};
    color: ${props => props.theme.actionBlue};
    background: transparent;

    &:hover {
      border: 6px dashed ${props => props.theme.actionOrange};
      color: ${props => props.theme.actionOrange};
    }
  }

  .upload-drop .dragging button {
    border: 6px dashed ${props => props.theme.actionOrange};
  }
`;

const getData = (file, from = 0, to = Infinity) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file.slice(from, to));
  });
};

export function Upload({ multiple, client, session, type, refetch }) {
  const allowedExtensions = [
    'doc', 'docx', 'jpeg',
    'jpg', 'mp4',  'pdf',
    'png', 'ppt',  'pptx',
    'xls', 'xlsx', 'zip'
  ];

  const uploadDrop = useRef();
  const uploadBrowse = useRef();

  const dragCallback = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    uploadDrop.current.classList.add('dragging');
  }, []);

  const dragLeaveCallback = useCallback(e => {
    uploadDrop.current.classList.remove('dragging');
  }, []);

  const [uploadStatus, setUploadStatus] = useState({
    busy: false,
    error: null,
    filename: null,
    progress: 0
  });
  const resetUploadStatus = () => {
    setUploadStatus({
      busy: false,
      error: null,
      filename: null,
      progress: 0
    });
  }

  const addFiles = useCallback(async files => {
    const chunkSize = 1048576;
    const headers = { Authorization: 'Bearer ' + localStorage.accessToken };
    const totalSize = Array.from(files).map(({ size }) => size).reduce((a, b) => a + b, 0);

    for (let i = 0; i < files.length; ++i) {
      const file = files[i];
      const ext = file.name.toLowerCase().replace(/^.+\.([a-z0-9]{2,4})$/, '$1');
      if (allowedExtensions.indexOf(ext) < 0) {
        setUploadStatus({
          busy: false,
          error: 'Alleen bestanden met de volgende extenties zijn toegestaan: ' + allowedExtensions.join(', '),
          filename: null,
          progress: 0
        });
        return;
      }
    }

    let uploadedSize = 0;
    for (let i = 0; i < files.length; ++i) {
      const file = files[i];

      setUploadStatus({ busy: true, error: null, filename: file.name, progress: 0 });

      // Get upload token.
      const response = await fetch('/api/media/token', { headers });
      const { token } = await response.json();

      // Chuncked file upload.
      const chunks = Math.ceil(file.size / chunkSize);
      for (let c = 0; c < chunks; ++c) {
        const data = await getData(file, c * chunkSize, c * chunkSize + chunkSize);
        const formData = new FormData();
        formData.append('t', token);
        if (c === chunks - 1) {
          // Set commit flag on the last chunk.
          formData.append('c', 1);
        }
        formData.append('file', new Blob([data], { type: file.type }), file.name);
        await fetch('/api/media/upload', {
          method: 'POST',
          body: formData,
          headers
        }).then(res => res.json()).then(() => { });
        uploadedSize += c === chunks - 1 ? file.size % chunkSize : chunkSize;
        if (c === chunks - 1) {
          await client.mutate({
            mutation: gql`mutation AddDoc (
              $session: ID,
              $extension: String!,
              $name: String!,
              $type: String!,
              $url: String!
            ) {
              createTeacherDocument (input: {
                session:$session,
                extension: $extension,
                name: $name,
                source:"1",
                type:$type,
                url:$url
              }) {
                id
              }
            }`,
            variables: {
              session: session,
              extension: file.name.replace(/^.*\.([^\.]+)$/, '$1'),
              name: file.name,
              type: type,
              url: token
            }
          });
        }
        setUploadStatus({ busy: true, error: null, filename: file.name, progress: uploadedSize / totalSize });
      }
    }
    resetUploadStatus();
    if (refetch) {
      refetch();
    }
  }, []);

  const uploadCallback = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    uploadDrop.current.classList.remove('dragging');
    if (e.dataTransfer) {
      addFiles(e.dataTransfer.files);
    } else if (uploadBrowse.current.files) {
      addFiles(uploadBrowse.current.files);
    }
  }, []);

  useEffect(() => {
    if (uploadDrop.current) {
      uploadDrop.current.addEventListener('dragenter', dragCallback, false);
      uploadDrop.current.addEventListener('dragover', dragCallback, false);
      uploadDrop.current.addEventListener('drop', uploadCallback, false);
      uploadDrop.current.addEventListener('dragleave', dragLeaveCallback, false);
      return () => {
        uploadDrop.current.removeEventListener('dragenter', dragCallback);
        uploadDrop.current.removeEventListener('dragover', dragCallback);
        uploadDrop.current.removeEventListener('drop', uploadCallback);
        uploadDrop.current.removeEventListener('dragleave', dragLeaveCallback, false);
      };
    }
  }, [uploadDrop.current]);

  useEffect(() => {
    if (uploadBrowse.current) {
      uploadBrowse.current.addEventListener('change', uploadCallback, false);
      return () => {
        uploadBrowse.current.removeEventListener('change', uploadCallback);
      };
    }
  }, [uploadBrowse.current]);

  return (
    <Wrapper>
      <div className="upload-drop" ref={uploadDrop}>
        <input multiple={multiple} type="file" ref={uploadBrowse} />
        <button
          className="btn btn-outline-primary"
          disabled={uploadStatus.busy}
          onClick={() => {
            uploadBrowse.current.click();
          }}
        >
          Upload
        </button>
        {uploadStatus.busy && (
          <span>
            {uploadStatus.filename} uploaden {~~(uploadStatus.progress * 100)}%
          </span>
        )}
      </div>

      {uploadStatus.error && (
        <Dialog
          title="Upload mislukt"
          body={(
            <p>{uploadStatus.error}</p>
          )}
          buttons={(
            <React.Fragment>
              <button onClick={resetUploadStatus}>
                Ok
              </button>
            </React.Fragment>
          )}
          onClose={resetUploadStatus}
        />
      )}
    </Wrapper>
  )
}
