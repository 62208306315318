import Purchase from '../list-items/purchase';
import ShowMoreTab from '../show-more-tab';
import settings from '../../settings';

const paginationLimit = settings.pagination.purchases;

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: true
    };
  }
  showAll() {
    this.setState( { limit: false } );
  }
  render() {
    const { count, purchases } = this.props;
    const { limit } = this.state;

    return (<div>
      { purchases.length
        ? ( limit ? purchases.slice( 0, paginationLimit) : purchases ).map( item => {
          return (<Purchase key={ item.id } {...item} />)
        } )
        : 'U heeft nog geen losse producten.'
      }
      { limit && count > paginationLimit && <ShowMoreTab onClick={this.showAll.bind(this)}>Toon alle { count } producten</ShowMoreTab> }
    </div>);
  }
}
