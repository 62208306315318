'use strict';

import styled from 'styled-components';

const ToggleBox = styled.div`
  display: inline-block;
  position: relative;
  top: 5px;
  margin-right: 5px;
  width: 35px;
  height: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: ${ props => props.theme.backgroundGray};
  border: 2px solid ${ props => props.theme.lightGray };
  transition: background-color 0.2s ease-out;
  cursor: pointer;

  &:after {
    content: '';
    background-color: ${ props => props.theme.lightGray };
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: block;
    position: absolute;
    left: -2px;
    top: -2px;
    transition: left 0.2s ease-in-out;
  }

  &.enabled {
    background-color: ${ props => props.theme.enabledGreen };

    &:after {
      left: calc(100% - 20px + 2px);
    }
  }
`;

export default class extends React.Component {
  render() {
    const { onChange, enabled } = this.props;

    return (
      <ToggleBox onClick={onChange} className={`toggle ${enabled ? 'enabled' : ''}`}/>
    );
  }
}
