import gql from "graphql-tag";

export default gql`query ($sort: OrderDirection) {
  listTeacherEventSession (order: [
    {field: "start", direction: $sort}
  ]) {
    count
    items {
      id
      start
      end
      eventName
      location
      status {
        items {
          id
          material
          participants
        }
      }
    }
  }
}`
