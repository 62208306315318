'use strict';

import InputMask from 'react-input-mask';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({target}) {
    let {value} = target;

    if (this.props.schema.mask) {
      value = value.replace(/_+/, '');
    }

    this.props.onChange(value);
  }

  render() {
    const {maxLength} = this.props.schema;
    const value = typeof this.props.value === 'string' ? this.props.value : '';

    let type = 'text';
    if (this.props.schema.format === 'date') {
      type = 'date';
    }
    if (this.props.schema.password) {
      type = 'password';
    }

    // console.log(this.props);

    return (<this.props.templates.formElement {...{...this.props, template: this.props.template || 'textfield'}}>
      <InputMask
        className="form-element-has-mask"
        invalid={(this.props.errors && this.props.errors.length) ? 'invalid' : undefined}
        mask={this.props.schema.mask}
        type={type}
        maxLength={this.props.schema.mask ? null : maxLength}
        disabled={Boolean(this.props.schema.readOnly)}
        onChange={this.handleChange}
        value={value}
        placeholder={this.props.schema.placeholder}
        onBlur={this.props.onBlur(this.props)}
        />
    </this.props.templates.formElement>);
  }
}
