'use strict';

import { NavLink, Route } from 'react-router-dom';

import ContentHeading from '../content-heading';
import TabGroup from '../tab-group';

import TeacherSessions from '../../containers/screens/teacher-sessions';
import TeacherSessionsHistory from '../../containers/screens/teacher-sessions-history';

export default class extends React.Component {
  render() {
    return (
      <div>
        <ContentHeading title="Docentomgeving">
          <TabGroup>
            <NavLink
              activeClassName="active"
              exact
              to="/docent"
            >Actueel</NavLink>
            <NavLink
              activeClassName="active"
              to="/docent/historie"
            >Historie</NavLink>
          </TabGroup>
        </ContentHeading>
        <Route
          exact
          path="/docent"
          component={TeacherSessions}
        />
        <Route
          exact
          path="/docent/historie"
          component={TeacherSessionsHistory}
        />
      </div>
    );
  }
}
