'use strict';

import gql from 'graphql-tag';
import {graphql, withApollo} from 'react-apollo';
import {Redirect} from 'react-router-dom';
import MyInterest from '../../components/screens/my-interests';

const query = gql`query {
  User: currentUser {
    id
    themes {
      items {
        id
        theme {
          id
        }
      }
    }
  }

  disciplines: listDiscipline(order: {
    field: "title"
  }) {
    items {
      id
      title
      themes {
        items {
          id
          title
        }
      }
    }
  }
}`;

@withApollo
@graphql(query, {
  props: ({data: {User, disciplines}}) => ({...User, disciplines})
})
export default class extends React.Component {
  constructor(props) {
    super(props);

    for (const methodName of ['getHandleAddTheme', 'getHandleRemoveTheme']) {
      this[methodName] = this[methodName].bind(this);
    }

    this.state = {loading: false};
  }
  getHandleAddTheme(themeId) {
    return () => {
      this.setState({loading: true});
      const {id} = this.props;

      return this.props.client.mutate({
        mutation: gql`mutation ( $id: ID!, $themeId: ID! ) {
          addedTheme: createUserTheme( input: {
            user: $id,
            theme: $themeId
          } ){ id }
        }`,
        variables: {id, themeId},
        refetchQueries: [{ query }],
        awaitRefetchQueries: true
      }).then(() => {
        setTimeout(() => {
          this.setState({loading: false});
        }, 500);
      });
    };
  }
  getHandleRemoveTheme(themeObjectId) {
    return () => {
      this.setState({loading: true});

      return this.props.client.mutate({
        mutation: gql`mutation ( $themeObjectId: ID! ) {
          removedTheme: deleteUserTheme( id: $themeObjectId ) {id}
        }`,
        variables: {themeObjectId},
        refetchQueries: [{query}],
        awaitRefetchQueries: true
      }).then(() => {
        setTimeout(() => {
          this.setState({loading: false});
        }, 500);
      });
    };
  }
  render() {
    if (!this.props.disciplines || !this.props.themes) {
      return null;
    }

    const {disciplines: {items}} = this.props;
    const {loading} = this.state;
    const themeIds = this.props.themes.items.map(item => item.theme.id);
    const themeObjectIds = this.props.themes.items.reduce((themeObjectIds, item) => {
      themeObjectIds[item.theme.id] = item.id;

      return themeObjectIds;
    }, {});

    const disciplines = items.map(item => ({
        ...item,
        themes: item.themes.items.map(theme => ({
          ...theme,
          themeObjectId: themeObjectIds[theme.id]
        }))
    })).filter(discipline => discipline.themes.filter(({themeObjectId}) => themeObjectId).length > 0);

    if (!disciplines.length) {
      return <Redirect to="/interesses/aanpassen"/>;
    }

    return <MyInterest loading={loading} disciplines={disciplines} getHandleAddTheme={this.getHandleAddTheme} getHandleRemoveTheme={this.getHandleRemoveTheme}/>;
  }
}
