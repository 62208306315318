import gql from 'graphql-tag';

export default gql `query($id: ID!) {
  EventScheduleData(id:$id) {
    id
    documentRemarks
    documents(limit: 1000) {
      items {
        id
        eventSession {
          id
        }
        document {
          id
          name
          location
          format
          extension
          size
          documentToken
          source
          type
          url
        }
      }
    }
    loggedInParticipant {
      id
      participations (limit: 1000) {
        items {
          id
          eventSession {
            id
            code
            start
            description
          }
        }
      }
    }
  }
}`;
