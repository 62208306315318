import {useMemo} from 'react';
import {Query} from 'react-apollo';

import {identity} from '../lib/functions';

export default ({
  query,
  variables: staticVariables = {},
  getVariables = props => ({}),
  getChildProps = identity,
  transform = identity,
  LoaderComponent = props => <p>Loading</p>,
  ErrorComponent = ({error}) => <div><p style={{color: 'red'}}>Error: {error.message}</p></div>
}) => WrappedComponent => props => {

  const variables = {
    ...staticVariables,
    ...getVariables(props)
  };

  const missingVariables = [];

  for (const {variableDefinitions} of query.definitions) {
    for (const {
      variable: {name: {value: key}},
      type: {kind: variableType}
    } of variableDefinitions) {

      if (variableType == 'NonNullType' && variables[key] == null) {
        missingVariables.push(key);
      }
    }
  }

  if (missingVariables.length) {
    return <ErrorComponent error={{message: `missing parameters: ${missingVariables.join(', ')}`}}/>;
  }

  return (
    <Query
      query={query}
      variables={{...variables, ...getVariables(props)}}
    >
      {({ loading, error, data, refetch }) => {
        if (loading) {
          return <LoaderComponent/>;
        }

        if (error) {
          return <ErrorComponent error={error || {message: 'Er is een onbekende fout opgetreden'}}/>;
        }

        const transformedData = transform(data);

        return <WrappedComponent {...props} {...getChildProps(props)} {...transformedData} refetch={refetch}/>;
      }}
    </Query>
  );
}
