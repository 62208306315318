'use strict';

import {clone} from 'lodash';
import createValidator from 'is-my-json-valid';
import JsonEditor from '../json-editor';
import Schema from '../schema-tools';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.generateValidators(props.schema);
  }

  componentWillReceiveProps({schema}) {
    this.generateValidators(schema);
  }

  getEmptyValue(schema) {
    const schemaInstance = new Schema(schema || {});
    return schemaInstance.getEmptyValue();
  }

  generateValidators(schema) {
    this.variations = (schema.oneOf || []).filter(item => typeof item === 'object' && item !== null && !Array.isArray(item)).map((variationSchema, index) => {
      let isValid;
      try {
        isValid = (schema._validator || createValidator)(variationSchema.distinguishSchema || variationSchema);
      } catch (err) {
        isValid = () => false;
      }
      return {
        title: variationSchema.title || `Variation ${index}`,
        isValid,
        schema: variationSchema,
        initialValue: (variationSchema.examples || [this.getEmptyValue(variationSchema)])[0]
      };
    });
  }

  handleClick(clicked) {
    const value = this.variations.reduce((prev, {title, initialValue}) => clicked === title ? initialValue : prev, null);
    this.props.onChange(value);
  }

  render() {
    const {value, errors} = this.props;

    const editor = this.variations.reduce((prev, {isValid, schema}) => {
      if (!prev.component && isValid(value)) {
        return {
          type: schema.title,
          component: (<JsonEditor
            name={this.props.name}
            path={this.props.path}
            schema={schema}
            elements={this.props.elements}
            fullSchema={this.props.fullSchema}
            value={value}
            errors={errors}
            validate={this.props.validate}
            validateImmediately={this.props.validateImmediately}
            onValidate={this.props.onValidate}
            templates={this.props.templates}
            context={this.props.context}
            getSubContext={this.props.getSubContext}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            formatError={this.props.formatError}
            />)
        };
      }
      return prev;
    }, {
      title: null,
      component: null
    });

    const buttons = [];
    if (this.props.schema.allowChange || !editor.component) {
      this.variations.forEach(({title}) => {
        buttons.push({
          label: title,
          active: title === editor.type,
          handleClick: () => this.handleClick(title)
        });
      });
    }

    const Component = this.props.templates.oneOf;
    return (<Component
      {...this.props}
      buttons={buttons}
      title={editor.type}
      element={editor.component}
      />);
  }
}
