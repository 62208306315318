'use strict';

import styled from 'styled-components';

const Overlay = styled.div`
	background-color: rgba( 0, 0, 0, 0.3 );
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export default class extends React.Component {
  setElementRef( element ) {
    this.element = element;
  }
  onClick( event ) {
    if( event.target === this.element && this.props.onClick ) {
      this.props.onClick();
    }
  }
  render() {
    const { children } = this.props;

    return (<Overlay onClick={this.onClick.bind( this )} innerRef={this.setElementRef.bind( this )}>
      { children }
	  </Overlay>);
  }
}
