import {Route} from 'react-router-dom';
import TabGroup from '../tab-group';
import TabLink from '../tab-link';
import ContentHeading from '../content-heading';
import CongressGeneralHOC from '../../containers/screens/congress-general';
import CongressMaterialHOC from '../../containers/screens/event-material';
import Placeholder from "../placeholder";

export default class extends React.Component {
  render() {
    return (<div>
      <ContentHeading title={this.props.title}>
        <TabGroup>
          <TabLink to={`/congres/${this.props.id}`}>Algemeen</TabLink>
          <TabLink to={`/congres/${this.props.id}/materiaal`}>Materiaal</TabLink>
        </TabGroup>
      </ContentHeading>
      <Route
        exact
        path="/congres/:id"
        component={CongressGeneralHOC}
      />
      <Route
        exact
        path="/congres/:id/materiaal"
        render={({match: {params: {id}}}) => {
          return (<CongressMaterialHOC>
            <Placeholder position="PH_COP-condetail_wide"/>
          </CongressMaterialHOC>);
        }}
      />
    </div>);
  }
}
