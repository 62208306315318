'use strict';

import { TransparentLink } from "../buttons";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 1000
    };
  }
  componentDidMount() {
    window.setMediaHeight = height => {
      this.setState({height});
    };
  }

  componentWillUnmount() {
    if (window.setMediaHeight === 'function') {
      delete window.setMediaHeight;
    }
  }

  render() {
    const {session, match: {params: {id}}} = this.props;
    const doc = session.documents.items.find(d => d.id === id);

    const url = `/api/media/view/${doc.url}/${doc.name}`;
    const hasPreview = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'mp4'].indexOf(doc.extension.toLowerCase()) >= 0;

    return (
      <div>
        <TransparentLink to={`/docent/sessie/${session.id}/materiaal`}>
          Ga terug naar het materiaal overzicht
        </TransparentLink>

        <div>
          {hasPreview && (
            <iframe width="100%" height={this.state.height} src={url} border="0" style={{border: 0}}></iframe>
          )}
          {!hasPreview && (
            <p>Er is geen voorbeeldweergave beschikbaar.</p>
          )}
        </div>

      </div>
    );
  }
}
