import {identity} from './functions';
import moment, {ISODATEFORMAT} from './moment';

export default (items, format = identity) => ['start', 'end'].reduce((store, key) => {
  const times = items.map(({[key]: time}) => moment(time, ISODATEFORMAT).unix());
  const MathExtreme = Math[{start: 'min', end: 'max'}[key]];

  store[key] = format(MathExtreme(...times));

  return store;
}, {});
