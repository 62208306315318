'use strict';

import styled from 'styled-components';
import moment from 'moment';

import Event from '../list-items/event';
import Suggestion from '../list-items/suggestion';
import ShowMoreTab from '../show-more';

import settings from '../../settings';

const paginationLimit = settings.pagination.visitedEvents;


const Container = styled.div`
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .year-group {
    margin-bottom: 30px;
  }
`;

export default class extends React.Component {
  constructor( props ){
    super( props );

    this.state = {
      expanded: {}
    };
  }
  expand( year ) {
    this.setState( {
      expanded: {
        ...this.state.expanded,
        [ year ]: true
      }
    } );
  }
  render() {
    const { props: { events }, state: { expanded } } = this;

    return (
      <Container>
        { events.length
          ? Object.entries( events.reduce( ( eventsByYear, event ) => {
            const year = moment( event.date ).format('YYYY');
            ( eventsByYear[ year ] = eventsByYear[ year ] || [] ).push( event );

            return eventsByYear;
          }, {} ) ).sort( ( a, b ) => b[ 0 ] - a[ 0 ] ).map( ( [ year, items ] ) => (<div key={year} className="year-group">
            <h2>{ year }</h2>
            { items
                .sort( ( { date: dateA }, { date: dateB } ) => moment( dateB, 'YYYY-MM-DD' ) - moment( dateA, 'YYYY-MM-DD' ) )
                .slice( 0, expanded[ year ] ? Infinity : paginationLimit )
                .map( item => {
                  const Component = item.suggestion ? Suggestion : Event;
                  return (<Component key={ item.id } {...item} />);
                } )
            }
            { items.length > paginationLimit && !expanded[ year ] && <ShowMoreTab onClick={this.expand.bind( this, year )}>
              Toon alle { items.length } evenementen in { year }
            </ShowMoreTab> }
          </div>) )
          : 'U hebt nog geen congressen bezocht'
        }
      </Container>
    );
  }
}
