'use strict';

export default class extends React.Component {
  render() {
    return (<this.props.templates.formElement {...this.props}>
      <input type="file"
        className="form-element-file"
        invalid={(this.props.errors && this.props.errors.length) ? 'invalid' : undefined}
        onBlur={this.props.onBlur(this.props)}
        onChange={this.props.onChange}
      />
    </this.props.templates.formElement>);
  }
}
