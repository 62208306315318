import addressSchema from './address';

export default {
  type: 'object',
  properties: {
    shippingAddress: {
      ...addressSchema,
      title: 'Verzendadres'
    },
    billingAddressDisabled: {
      title: 'Mijn factuuradres is hetzelfde als mijn verzendadres',
      type: 'boolean'
    },
    billingAddress: {
      ...addressSchema,
      title: 'Factuuradres',
      intro: (
        <p>
          Wilt u dat er een ordernummer vermeld wordt op de factuur? Geef dit dan door aan <a href="https://www.rendement.nl/formulier/klantenservice/contactformulier" target="_blank">onze klantenservice</a>.
        </p>
      )
    }
  },
  required: [
    'shippingAddress',
    'billingAddressDisabled',
    'billingAddress'
  ],
  additionalProperties: false
};
