'use strict';

import moment, { ISODATEFORMAT } from 'moment';
import { orderBy } from 'lodash';

import withApolloLoader from '../with-apollo-loader';

import MyVisitedCongresses from '../../components/screens/my-visited-congresses';

import getStartAndEndTime from '../../lib/start-end-times';

import eventScheduleQuery from '../../queries/event-schedule';

export default withApolloLoader({
  query: eventScheduleQuery,
  transform: ({ User }) => {
    const current = moment().subtract(7, 'days').unix();

    const oldEvents = User.events.items.filter(({
      eventScheduleData: { eventSchedule: { event: { group: { type } } } }
    }) => type == 'congress').filter(item => {
      const timestamps = item.eventScheduleData.sessions.items.map(item => {
        return moment(item.start, ISODATEFORMAT).unix();
      });
      return !timestamps.filter(item => item > current).length;
    });

    const events = oldEvents.map(item => {
      const dates = item.eventScheduleData.sessions.items.map(item => moment(item.start, ISODATEFORMAT).unix()).sort();

      return {
        id: item.id,
        type: item.eventScheduleData.eventSchedule.event.group.type,
        title: item.eventScheduleData.title,
        url: `/congres/${item.eventScheduleData.id}`,
        timestamp: dates.length ? dates[0] : false,
        date: dates.length ? moment.unix(dates[0]) : false,
        location: item.eventScheduleData.eventLocation ? item.eventScheduleData.eventLocation.name : null,
        ...getStartAndEndTime(item.eventScheduleData.sessions.items, unix => unix ? moment.unix(unix).format('HH:mm') : false)
      }
    }).filter(({ timestamp }) => timestamp);

    return { events: orderBy(events, ['timestamp', 'desc']) };
  },
  LoaderComponent: () => <div>Uw congressen worden ingeladen...</div>
})(MyVisitedCongresses);
