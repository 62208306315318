'use strict';

import withApolloLoader from '../with-apollo-loader';

import TeacherSessionMaterials from '../../components/screens/teacher-session-materials';

import TeacherSessionMaterialsPage from '../../components/screens/teacher-session-materials-page';

import teacherSessionQuery from '../../queries/teacher-session';

export default withApolloLoader({
  query: teacherSessionQuery,
  getVariables: ({ match: { params: { code } } }) => ({ code }),
  getChildProps: ({ match: { params: { code } } }) => ({
    code,
    multiple: true,
    type: 'Document'
  }),
  transform: ({ TeacherEventSession }) => {
    const session = TeacherEventSession;
    return { session };
  },
  LoaderComponent: () => <div>Trainingssessie wordt ingeladen...</div>
})(TeacherSessionMaterialsPage);
