'use strict';

export default class extends React.Component {
  render() {
    const buttons = [];
    this.props.buttons.forEach(({label, handleClick}) => {
      buttons.push(<button key={label} onClick={e => {
        e.preventDefault();
        return handleClick(e);
      }}>{label}</button>);
    });

    return (<this.props.templates.formElement {...{...this.props, template: this.props.template || 'one-of'}}>
      <div className="form-one-of-buttons">
        {buttons}
      </div>
      {this.props.element}
    </this.props.templates.formElement>);
  }
}
