'use strict';


export default class extends React.Component {
  render() {
    const {adviser:{image, name}} = this.props;
    return (<div>
      <div id="avatar" style={{backgroundImage: `url(${image})`}}/>
      {name && <span>{name}</span>}
    </div>);
  }
}
