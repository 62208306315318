'use strict';

import {clone} from 'lodash';
import JsonEditor from '../json-editor';
import Schema from '../schema-tools';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClickAdd = this.handleClickAdd.bind(this);
  }

  getValue() {
    return Array.isArray(this.props.value) ? this.props.value : [];
  }

  handleChange(index, value) {
    this.props.onChange(this.getValue().map((oldValue, valueIndex) => valueIndex === index ? value : oldValue));
  }

  handleDelete(index) {
    this.props.onChange(this.getValue().filter((oldValue, valueIndex) => {
      return valueIndex !== index;
    }));
  }

  getEmptyValue(schema) {
    const schemaInstance = new Schema(schema);
    return schemaInstance.getEmptyValue();
  }

  handleClickAdd() {
    const value = Array.isArray(this.props.value) ? this.props.value : [];
    let emptyValue = this.getEmptyValue(this.props.schema.items || {});
    value.push(emptyValue);
    this.props.onChange(value);
  }

  render() {
    const Component = this.props.templates.array;

    const itemSchema = this.props.schema.items || {};
    const items = this.getValue().map((itemValue, index) => {
      const context = this.props.getSubContext(this.props.context, index);
      const handleChange = value => this.handleChange(index, value);
      const handleDelete = () => this.handleDelete(index);
      const element = (<JsonEditor
        name={this.props.name}
        path={this.props.path}
        key={index}
        deleteable
        schema={itemSchema}
        errors={this.props.errors}
        validate={this.props.validate}
        validateImmediately={this.props.validateImmediately}
        onValidate={this.props.onValidate}
        elements={this.props.elements}
        fullSchema={this.props.fullSchema}
        value={itemValue}
        templates={this.props.templates}
        context={context}
        getSubContext={this.props.getSubContext}
        onChange={handleChange}
        formatError={this.props.formatError}
        />);
      return {
        element,
        handleDelete,
        schema: itemSchema
      };
    });

    return (<Component
      {...this.props}
      items={items}
      onChange={this.handleChange}
      onDelete={this.handleDelete}
      onClickAdd={this.handleClickAdd}
      />);
  }
}
