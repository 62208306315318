import styled from 'styled-components';

const NavLinkExternal = ({href, children, className, target = '_self'}) => (
  <a href={href} className={className} target={target}>{children}</a>
);

export default styled( NavLinkExternal ).attrs( {
  activeClassName: 'active'
} )`
	color: ${ props => props.theme.almostBlack };
	font-size: 24px;
	line-height: 60px;
	text-decoration: none;

	svg {
		margin-right: 17px;
		min-width: 26px;

		&.user {
			position: relative;
			top: 3px;
		}

		&.tag {
			position: relative;
			top: 1px;
		}
	}

	&:hover {
    color: ${ props => props.theme.activeBlue };

    [fill]:not([fill=none]) { fill: ${ props => props.theme.activeBlue }; }
	}
	&.active {
    color: ${ props => props.theme.actionOrange };
    font-weight: bold;

    [fill]:not([fill=none]) { fill: ${ props => props.theme.actionOrange }; }
	}

	.notification {
		display: inline-block;
		vertical-align: top;
		text-align: center;
		line-height: 20px;
		height: 20px;
		min-width: 20px;
		border-radius: 20px;
		font-size: 16px;
		box-sizing: border-box;
		margin-top: 10px;
		color: white;
		padding: 0 5px;
		font-family: Karla;
		font-weight: normal;
		background-color: ${ props => props.theme.happyGreen };
	}
`;
