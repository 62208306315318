import styled from 'styled-components';
import {Link} from 'react-router-dom';

const StyledLink = styled(Link)`
  color: ${ props => props.theme.rendementBlue };
  
  &:hover {
    color: ${ props => props.theme.actionOrange }
  }
`;

export default class extends React.Component {
  render() {
    return <StyledLink {...this.props}>{this.props.children}</StyledLink>;
  }
}