'use strict';

import styled from 'styled-components';
import {ChevronCapsuleAnchor} from '../buttons';

const Item = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 122px;
  padding: 10px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.lightGray};
  }

  &:first-child {
    margin-top: -30px;
  }

  .icon-container {
    flex-basis: 110px;
    flex-shrink: 0;
    flex-grow: 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    
    > img {
      width: 71px;
      height: 71px;
    }
  }

  .body {
    flex-grow: 1;
  }

  h1 {
    font-size: 24px;
    line-height: 28px;
    margin-top: 0;
    color: ${props => props.theme.almostBlack};
  }

  p {
    font-size: 16px;
    color: ${props => props.theme.mediumDarkGray};
    margin-bottom: 0;
  }

  a {
    align-self: flex-start;
    margin-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
    flex-shrink: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.big}px) {
    flex-wrap: wrap;
    flex-direction: column;
    padding: 15px 0;
    align-items: flex-start;

    .icon-container {
      display: none;
    }

    h1 {
      margin-top: 0;
      font-size: 18px;
    }

    p {
      font-size: 13px;
    }

    a {
      margin-left: 0;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
`;

export default class extends React.Component {
  render() {
    const {
      description,
      logo,
      id,
      title,
      url
    } = this.props;

    let printDescription = description;
    if (typeof description === 'string') {
      printDescription = printDescription.replace(/(<([^>]+)>)/gi, "");
    }

    return (
      <Item key={id}>
        {logo && <div className="icon-container"><img src={logo} alt={title} /></div>}
        <div className="body">
          <h1>{title}</h1>
          <p>{printDescription}</p>
        </div>
        <ChevronCapsuleAnchor href={url} target="_blank">Ga naar dit product</ChevronCapsuleAnchor>
      </Item>
    );
  }
}
