'use strict';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Header = styled.header`
	#white-strip {
		background-color: white;
		border-bottom: 1px solid rgb( 220, 220, 220 );
		height: 94px;
		transition: height 0.4s ease-in-out;

		a.logo {
			width: 409px;
			height: 94px;
			margin-left: 7px;
			display: inline-block;
			background: url('/assets/mijn-rendement.svg');
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			transition: height 0.4s ease-in-out, width 0.4s ease-in-out;
		}

		@media (max-width: ${ props => props.theme.breakpoints.big }px) {
			height: 47px;
			text-align: center;

			a.logo {
				width: 204px;
				height: 47px;
			}
		}
	}
`;

export default class extends React.Component {
  render() {
    return (<Header>
	    <div id="white-strip">
	    	<div className="site-container">
	    		<Link to="/" className="logo"></Link>
    		</div>
		</div>
	  </Header>);
  }
}
