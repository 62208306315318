'use strict';

import { NavLink, Route } from 'react-router-dom';

import ContentHeading from '../content-heading';
import TabGroup from '../tab-group';

import TeacherSessionMain from '../../containers/screens/teacher-session-main';
import TeacherSessionMaterial from '../../containers/screens/teacher-session-material';
import TeacherSessionMaterials from '../../containers/screens/teacher-session-materials';

export default class extends React.Component {
  render() {
    const { session } = this.props;
    return (
      <div>
        <ContentHeading title={session.eventName}>
          <TabGroup>
            <NavLink
              activeClassName="active"
              exact
              to={`/docent/sessie/${session.id}`}
            >Algemeen</NavLink>
            <NavLink
              activeClassName="active"
              to={`/docent/sessie/${session.id}/materiaal`}
            >Materiaal</NavLink>
          </TabGroup>
        </ContentHeading>
        <Route
          exact
          path="/docent/sessie/:code"
          component={TeacherSessionMain}
        />
        <Route
          exact
          path="/docent/sessie/:code/materiaal"
          component={TeacherSessionMaterials}
        />
        <Route
          exact
          path="/docent/sessie/:code/materiaal/:id"
          component={TeacherSessionMaterial}
        />
      </div>
    );
  }
}
