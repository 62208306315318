'use strict';

import PropTypes from 'prop-types';
import {cloneDeep} from 'lodash';
import createValidator from 'is-my-json-valid';
import Message from './message';
import Schema from '../lib/jsoneditor/schema-tools';
import JsonEditor from '../lib/jsoneditor';
import styled from 'styled-components';
import {CapsuleButton} from './buttons';

const Form = styled.form`
  padding-left: 20px;

  ul.form-errors {
    margin-top: 0;
    padding-left: 0;
    clear: both;
  }

  li.form-error {
    list-style-type: none;
    color: ${props => props.theme.errorRed};
  }

  div.form-element-wrapper {
    max-width: ${props => props.maxWidth || 900}px;
    position: relative;

    &.form-string.required {
      > .form-element-label::after {
        content: " *";
        font-weight: bold;
        color: rgb(245, 73, 16);
        position: relative;
        top: 1px;
      }
    }
  }

  fieldset {
    box-sizing: border-box;
    border: none;
    padding: 0;
  }

  // nested form element wrapper wraps individual form elements
  div.form-element-wrapper div.form-element-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  .has-error label.form-element-label {
    color: ${props => props.theme.errorRed}
  }

  label.form-element-label {
    flex-basis: 200px;
    flex-shrink: 0;
    line-height: 39px;
  }

  div.form-element {
    flex-grow: 1;
    position: relative;
    box-sizing: border-box;
  }

  input[type="text"], input[type="password"] {
    box-sizing: border-box;
    width: 100%;
    height: 39px;
    line-height: 37px;
    font-size: 14px;
    padding: 0 10px;
    color: black;
    border: 1px solid ${props => props.theme.lightGray};
    border-radius: 7px;

    &:placeholder {
      color: ${props => props.theme.mediumGray};
    }

    &[invalid] {
      color: ${props => props.theme.errorRed};

      &:focus {
        outline-color: ${props => props.theme.errorRed};
      }
    }
  }

  ul.form-element-errors {
    padding-left: 0;

    li.form-element-error-wrapper {
      list-style-type: none;
      color: ${props => props.theme.errorRed};
      padding-left: 11px;
      font-size: 14px;
    }
  }

  div.form-description {
    margin-top: 5px;
    color: ${props => props.theme.mediumGray};
    padding-left: 11px;
    font-size: 14px;
  }

  input[type="checkbox"], input[type="radio"] {
    margin-right: 10px;
  }

  label.form-radios-wrapping-label {
    margin-right: 30px;
  }

  span.form-radios-caption {
    line-height: 39px;
  }

  div.form-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  div.form-element-wrapper div.form-element-wrapper.form-template-static {
    margin-bottom: 35px;

    .form-element-label {
      line-height: inherit;
    }

    .form-element {
      > span {
        padding-left: 11px;
        margin-bottom: 5px;
        font-style: italic;
      }
    }
  }
`;

export default class extends React.Component {
  static propTypes = {
    onValidate: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    submitValue: PropTypes.string,
    errors: PropTypes.array,
    validateImmediately: PropTypes.bool,
    loading: PropTypes.bool,
    messages: PropTypes.array
  }

  static defaultProps = {
    onValidate: () => false,
    onBlur: () => {},
    onChange: values => values,
    submitValue: 'Opslaan',
    validateImmediately: true,
    errors: [],
    loading: false,
    messages: []
  }

  constructor(props) {
    super(props);

    this.schema = new Schema(props.schema);

    let value = cloneDeep(props.value);
    if (value === null) {
      value = this.schema.getEmptyValue();
    }
    this.state = {value, errors: !!props.initError, customErrors: false};

    this.validator = createValidator(props.schema, {greedy: true});
    this.filter = createValidator.filter(props.schema);

    this.validate = this.validate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.formatError = this.formatError.bind(this);
  }

  handleBlur(props) {
    return e => {
      const value = this.props.onBlur(e, props, cloneDeep(this.state.value));

      if (value) {
        if (value instanceof Promise) {
          value.then(this.handleChange);
        } else {
          this.handleChange(value);
        }
      }
    };
  }

  validate(value = this.state.value) {
    const removeNulls = obj => Object.keys(obj).reduce((out, key) => obj[key] === null ? out : {...out, [key]: typeof obj[key] === 'object' ? removeNulls(obj[key]) : obj[key]}, {});
    value = JSON.parse(JSON.stringify(value));
    if (value.avatar === null || value.avatar === false) {
      delete value.avatar;
    }
    if (value.billingAddress === null) {
      value.billingAddress = {};
    }
    if (value.billingAddressDisabled) {
      delete value.billingAddress;
    }
    value = removeNulls(value);
    this.validator(this.filter(value));
    let errors = this.validator.errors;
    if (Array.isArray(errors)) {
      errors = errors.filter(({field}) => field !== 'data.billingAddress');
      if (!errors.length) {
        errors = null;
      }
    }
    const customErrors = this.props.onValidate({errors, value});

    return {errors, customErrors};
  }

  formatError(error) {
    let message = 'Vul een geldige waarde in.';
    if (typeof error.schema.validation !== 'undefined') {
      message = error.schema.validation;
    }

    return {
      ...error,
      message
    };
  }

  handleSubmit(e) {
    e.preventDefault();

    const result = this.props.handleSubmit(this.state.value);

    if (result) {
      if (result instanceof Promise) {
        result.then(value => {
          window.scrollTo(0, 0);
          if (value) {
            this.setState({value});
          }
        });
      }
      else {
        window.scrollTo(0, 0);
        this.setState({value: result});
      }
    } else {
      window.scrollTo(0, 0);
    }
  }

  handleChange(value) {
    if (this.props.schema.properties.infix) {
      value.infix = value.infix || '';
    }
    value.billingAddressDisabled = Boolean(value.billingAddressDisabled);
    const {errors, customErrors} = this.validate(value);
    this.setState({
      errors,
      customErrors,
      value: this.props.onChange(value)
    });
  }

  render() {
    const hasError = Boolean(this.state.errors) || Boolean(this.state.customErrors);

    const {
      messages,
      errors,
      schema,
      validateImmediately,
      loading,
      submitValue,
      children,
      maxWidth
    } = this.props;

    const value = JSON.parse(JSON.stringify(this.state.value));
    value.billingAddressDisabled = Boolean(value.billingAddressDisabled);

    return (<React.Fragment>
      {messages && messages.map(message => <Message key={message}>{message}</Message>)}

      <Form onSubmit={this.handleSubmit} className={hasError ? 'has-error' : ''} maxWidth={maxWidth}>
        {(this.state.customErrors || errors.length > 0) && <ul className="form-errors">
          {(this.state.customErrors || []).concat(errors || []).map(error => <li className="form-error" key={error}>{error}</li>)}
        </ul>}

        <JsonEditor
          schema={schema}
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          formatError={this.formatError}
          validateImmediately={false}
          />
        <div className="form-actions">
          <CapsuleButton
            type="submit"
            disabled={hasError || loading}
            onClick={this.handleSubmit}
            >
            {submitValue}
          </CapsuleButton>
          { children }
        </div>
      </Form>
    </React.Fragment>);
  }
}
