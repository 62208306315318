export default class extends React.Component {
  render() {
    return (<svg className={this.props.className} width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-3.000000, -3.000000)">
          <g>
            <polygon points="0 0 24 0 24 24 0 24"></polygon>
            <path d="M3,13 L11,13 L11,3 L3,3 L3,13 Z M3,21 L11,21 L11,15 L3,15 L3,21 Z M13,21 L21,21 L21,11 L13,11 L13,21 Z M13,3 L13,9 L21,9 L21,3 L13,3 Z" fill="#000000" fillRule="nonzero"></path>
          </g>
        </g>
      </g>
    </svg>);
  }
}
