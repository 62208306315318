'use strict';

import { NavLink, Route } from 'react-router-dom';

import ContentHeading from '../content-heading';
import TabGroup from '../tab-group';

import MyOpenSupportRequests from '../../containers/screens/my-open-support-requests';
import MyClosedSupportRequests from '../../containers/screens/my-closed-support-requests';
import MySupportRequest from '../../containers/screens/my-support-request';
import Placeholder from "../placeholder";

export default class extends React.Component {
  render() {
    if (this.props.match.params.id === 'afgerond') {
      return null;
    }
    return (
      <div>
        <ContentHeading title="Mijn adviesdesk">
          <TabGroup>
            <NavLink
              activeClassName="active"
              exact
              to="/advies"
            >Open</NavLink>
            <NavLink
              activeClassName="active"
              to="/advies/afgerond"
            >Afgerond</NavLink>
          </TabGroup>
        </ContentHeading>
        <Route
          exact
          path="/advies"
          component={MyOpenSupportRequests}
        />
        <Route
          exact
          path="/advies/afgerond"
          component={MyClosedSupportRequests}
        />
        <Route
          exact
          path="/advies/:id"
          component={MySupportRequest}
        />
        <Placeholder position="PH_COP-opl_wide"/>
      </div>
    );
  }
}
