'use strict';

import Boolean from './boolean';

export default class extends React.Component {
  render() {
    return (<this.props.templates.formElement {...{...this.props, template: this.props.template || 'checkboxes'}}>
      {this.props.checkboxes.map(({value, label, checked, onClick}) => (
        <label className="form-checkbox-wrapping-label" key={value}>
          <input
            type="checkbox"
            checked={checked}
            value={value}
            onChange={onClick}
          />
          <span className="form-checkbox-caption">{label}</span>
        </label>
      ))}
    </this.props.templates.formElement>);
  }
}
