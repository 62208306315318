import {Route} from 'react-router-dom';
import TabGroup from '../tab-group';
import TabLink from '../tab-link';
import ContentHeading from '../content-heading';
import EducationGeneralHOC from '../../containers/screens/education-general';
import EducationMaterialHOC from '../../containers/screens/event-material';
import EducationMaterialViewer from '../../containers/screens/event-material-viewer';

export default class extends React.Component {
  render() {
    return (<div>
      <ContentHeading title={this.props.title}>
        <TabGroup>
          <TabLink to={`/opleiding/${this.props.id}`}>Algemeen</TabLink>
          <TabLink to={`/opleiding/${this.props.id}/materiaal`} exact={false}>Materiaal</TabLink>
        </TabGroup>
      </ContentHeading>
      <Route
        exact
        path="/opleiding/:id/materiaal"
        component={EducationMaterialHOC}
      />
      <Route
        exact
        path="/opleiding/:id"
        component={EducationGeneralHOC}
      />
      <Route
        exact
        path="/opleiding/:id/materiaal/:document"
        component={EducationMaterialViewer}
      />
    </div>);
  }
}
