'use strict';

import styled from 'styled-components';

import ContentHeading from '../content-heading';
import FilterList from '../filter-list';
import Activity from '../list-items/activity';
import Suggestion from '../list-items/suggestion';
import ShowMore from '../show-more';

const RailedContainer = styled.div.attrs({
  key: 'railed-container'
})`
  position: relative;
  padding-bottom: 20px;

  :before {
    content: ' ';
    background: linear-gradient(to bottom, transparent, ${props => props.theme.actionOrange} 45px, ${props => props.theme.actionOrange} calc(100% - 45px), transparent 100%);
    position: absolute;
    width: 3px;
    left: calc( 25px - 1.5px );
    top: 0;
    bottom: 0;
  }
`;

const filterLabels = {
  FAVORITE: 'favorieten',
  ELEARNING: 'e-learning',
  DOWNLOAD: 'downloads',
  ADVIES_DESK: 'adviesdesk'
};

export default class extends React.Component {
  render() {
    const { activities, filters, changeFilter, clearFilters } = this.props;

    return (
    	<div>
    		<ContentHeading title="Mijn activiteit"/>
    		<FilterList
          className="transparent scrollview"
    			filterTitle="Filter activiteiten op type"
    			filters={filters}
          filterLabels={filterLabels}
          changeFilter={changeFilter}
          clearFilters={clearFilters}
          ContentWrapper={RailedContainer}
          items={activities}
          itemRenderer={ ( item => item.render && item.render() || item.suggestion
            ? <Suggestion key={item.id} showRail {...item}/>
            : <Activity key={item.id} {...item}/>
          ) }
          placeholder="Er kunnen geen activiteiten worden weergegeven."
          loading={this.props.loading}
          loadingText="Uw activiteiten worden geladen."

    		>
          {(this.props.hasMore && !this.props.loading) && <ShowMore style={{ marginBottom: '20px', alignSelf: 'center' }} onClick={e => {
            e.preventDefault();
            this.props.loadMore();
          }}>Toon meer activiteiten</ShowMore>}
        </FilterList>
    	</div>
    );
  }
}
