import styled from 'styled-components';
import CalendarDate from './svg/calendar-date';

const Date = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  align-self: flex-start;
  position: relative;

  [fill] {
    fill: ${props => props.theme.actionOrange};
  }
  
  .month, .day {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }

  .month {
    color: ${props => props.theme.backgroundGray};
    text-transform: uppercase;
  }

  .day {
    font-weight: 700;
    color: ${props => props.theme.actionOrange};
  }
`;

export default class extends React.Component {
  static defaultProps = {
    width: 45
  };

  render() {
    const {
      width,
      date
    } = this.props;

    return (<Date style={{width: width + 'px'}}>
      <CalendarDate width={width}/>
      <span className="month" style={{top: (7 * (width / 45)) + 'px', fontSize: (14 * (width / 45)) + 'px'}}>{date.format('MMM').substring(0, 3)}</span>
      <span className="day" style={{top: (22 * (width / 45)) + 'px', fontSize: (21 * (width / 45)) + 'px'}}>{date.format('D')}</span>
    </Date>);
  }
}