'use strict';

import {clone} from 'lodash';
import {get} from 'jsonpointer';
import JsonEditor from '../json-editor';

export default class extends React.Component {
  render() {
    const {fullSchema, value, errors} = this.props;

    const schema = clone(get(fullSchema, this.props.schema.$ref.substring(1)));
    if (!schema) {
      return <div>Reference not found: {this.props.schema.$ref}</div>;
    }

    return (<JsonEditor
      path={this.props.path}
      name={this.props.name}
      schema={schema}
      label={this.props.label || schema.title}
      elements={this.props.elements}
      fullSchema={this.props.fullSchema}
      value={value}
      errors = {errors}
      validate={this.props.validate}
      validateImmediately={this.props.validateImmediately}
      onValidate={this.props.onValidate}
      templates={this.props.templates}
      context={this.props.context}
      getSubContext={this.props.getSubContext}
      setContext={this.props.setContext}
      onChange={this.props.onChange}
      formatError={this.props.formatError}
      />);
  }
}
