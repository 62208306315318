'use strict';

import styled from 'styled-components';
import c from '../lib/classer';

import Toggle from './toggle';
import { DropdownArrow } from './svg/';

const FilterHeader = styled.header`
	position: relative;
	margin-bottom: 40px;
	user-select: none;

	h1 {
		font-size: 18px;
		white-space: nowrap;
		margin-top: 0;
	}

	div.horizontal-divider {
		height: 100%;
		display: flex;
		flex-direction: row;

		div.main {
			flex-grow: 1;

			label {
				padding: 20px;
				white-space: nowrap;
				text-transform: capitalize;

				&:first-of-type {
					margin-left: -20px;
				}
			}

			&.expanded label:first-of-type {
				margin-left: 0;
			}
		}

		div.side {
			margin-top: 45px;
			margin-right: 55px;
			color: ${ props => props.theme.mediumGray };

			a {
				color: ${ props => props.theme.activeBlue };
				cursor: pointer;
				opacity: 1;
				transition: opacity 0.4s ease-in-out;
			}

			a.hidden {
				opacity: 0;
			}
		}
	}

	span.toggle-filter-list {
		display: none;
	}

	@media (max-width: ${ props => props.theme.breakpoints.big }px) {
		height: 40px;

		div.main h1 {
			margin-top: 0;
			line-height: 40px;
		}

		h1.filter-list {
			cursor: pointer;
		}

		span.toggle-filter-list {
			width: 40px;
			height: 40px;
			vertical-align: middle;
			position: relative;
			top: -2px;
			text-align: center;
			display: inline-block;
			transition: transform 0.4s ease-in-out, top 0.4s ease-in-out;

			svg {
				vertical-align: middle;
			}

			[fill] { fill: black }
		}

		div.filters {
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			position: absolute;
			height: 0;
			overflow: hidden;
			transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
			z-index: 50;
			background: white;
			padding: 20px;
			opacity: 0;
			top: 40px;
			left: 180px;
			border: 1px solid ${ props => props.theme.mediumGray };
		}

		div.main.expanded {
			span.toggle-filter-list {
				transform: rotate(180deg);
				top: 0;
			}

			div.filters {
				display: flex;
				opacity: 1;
				height: auto;
			}
		}
	}
`;

export default class extends React.Component {
	constructor( props ){
		super(props);

		this.state = {
			showFilters: false
		};
	}
	toggleFilterList() {
		this.setState( {
			showFilters: !this.state.showFilters
		} );
	}
  render() {
    const { title, filters, filterLabels, changeFilter, clearFilters } = this.props;

		const filtersArray = Object.entries( filters ).map( ( [ name, enabled ] ) => ( {
			name,
			enabled,
			label: filterLabels[ name ],
			toggle: () => changeFilter( name, !enabled )
		} ) );

    return (
    	<FilterHeader>
    		<div className="horizontal-divider">
    			<div className={ c( { main: true, expanded: this.state.showFilters } ) }>
    				<h1 className="filter-list" onClick={this.toggleFilterList.bind( this )}>{title}<span className="toggle-filter-list"><DropdownArrow/></span></h1>
						<div className="filters">
		    			{ filtersArray.map( filter => <label key={filter.label}><Toggle onChange={filter.toggle} enabled={filter.enabled}/> {filter.label}</label> ) }
						</div>
					</div>
		    	<div className="side">
						<a onClick={clearFilters} className={ c( { hidden: !filtersArray.find( filter => !filter.enabled ), 'show-all': true } ) }>Toon alles</a>
					</div>
	    	</div>
    	</FilterHeader>
    );
  }
}
