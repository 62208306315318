import {pipe} from '../../lib/functions';
import moment, {ISODATEFORMAT} from '../../lib/moment';

import {withRouter} from "react-router-dom";
import withApolloLoader from '../with-apollo-loader';

import CongressGeneral from '../../components/screens/congress-general';

import gql from "graphql-tag";

export default pipe(withRouter, withApolloLoader({
  query: gql `query($id: ID!) {
    EventScheduleData(id: $id) {
  		id
      sessions {
        items {
          id
          start
        }
      }
      eventSchedule {
        id
        hasEvaluation
      }
      loggedInParticipant {
        id
        paid
        finishedEvaluation
        certificateToken
        participations (limit: 1000) {
          items {
            id
            participated
          }
        }
      }
    }
  }`,
  getVariables: ({match: {params: {id}}}) => ({id}),
  transform: ({EventScheduleData}) => {
    if (typeof EventScheduleData !== 'undefined') {
      const {
        sessions: {items: sessions},
        eventSchedule: {hasEvaluation},
        loggedInParticipant: {
          participations: {items: participations},
          paid,
          finishedEvaluation,
          certificateToken
        }
      } = EventScheduleData;

      const dates = sessions.map(item => moment(item.start, ISODATEFORMAT).unix()).sort().reverse();

      return {
        participated: (participations || []).some(({participated}) => participated),
        eventHasPassed: (dates.length ? dates[0] : '') < moment().unix(),
        finishedEvaluation,
        hasEvaluation,
        paid,
        certificateToken
      };
    }
  }
}))(CongressGeneral);
