'use strict';

import {withApollo} from 'react-apollo';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import postcodeApi from '../../lib/postcode';
import PersonalDetails from '../../components/screens/personal-details-update';
import personalDetailsSchema from '../../schema/personal-details';
import { omit } from 'lodash';

const query = gql`query {
  User: currentUser {
    id
    initials
    salutation
    lastName
    phone
    mail
    invoicemail
    eventMail
    firstName
    infix
    functionName
    companyName
    avatar {
      items {
        id
        data
      }
    }
  }
}`;

@withApollo
@graphql(query, {
  props: ({data: {User}, ownProps: {client}}) => ({
    User,
    client,
    avatar: (User && User.avatar && User.avatar.items.length ? User.avatar.items[0].data : false)
  })
})
@graphql(gql`mutation ($userId: ID!, $user: UserUpdateInput!) {
  updateUser(id: $userId, input: $user) { id }
}`, {
  name: 'updateUser'
})
@graphql(gql`mutation ($avatar: String!) {
  setAvatar(data: $avatar) { id }
}`, {
  name: 'setAvatar'
})
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      populated: false,
      loading: false,
      messages: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setMessage(message) {
    this.setState({
      loading: false,
      messages: [message],
    });

    setTimeout(() => this.setState({messages: []}), 5000);
  }

  handleSubmit(data) {
    this.setState({loading: true});
    let {
      avatar,
      ...user
    } = data;

    const refetchQueries = [{
      query
    }];

    const promises = [];

    if (avatar && this.props.avatar !== avatar) {
      promises.push(this.props.setAvatar({
        variables: {
          avatar
        },
        refetchQueries
      }));
    }

    return Promise.all(promises).then(() => {
      return this.props.updateUser({
        variables: {
          userId: this.props.User.id,
          user: omit(user, ['id', '__typename'])
        },
        refetchQueries
      });
    }).then(() => {
      this.setState({loading: false});
      this.setMessage('Uw gegevens zijn bijgewerkt.');
    });
  }

  render() {
    if (!this.props.User || typeof this.props.User === 'undefined') {
      return <div><p>Uw gegevens worden ingeladen...</p></div>;
    }

    const {
      ...userProps
    } = this.props.User;

    const {avatar} = this.props;

    const value = {
      ...userProps,
      avatar
    };

    return (<PersonalDetails
      schema={personalDetailsSchema}
      value={value}
      handleSubmit={this.handleSubmit}
      loading={this.state.loading}
      messages={this.state.messages}
    />);
  }
}
