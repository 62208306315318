'use strict';

import styled from 'styled-components';

const Message = styled.div`
	background: ${props => props.theme.paleGreen };
	color: ${ props => props.theme.successGreen };
	border: 1px solid ${ props => props.theme.paleGreenDarker };
	border-radius: 4px;
	padding: 25px;
  margin-bottom: 20px;
`;

export default class extends React.Component {
  render() {
    return <Message>{this.props.children}</Message>;
  }
}
