'use strict';

import EntityForm from '../entity-form';
import styled from 'styled-components';

const Form = styled.div`
  div.form-key-postalCode input {
    width: 75px;
  }

  div.form-key-houseNumber input {
    width: 75px;
  }

  div.form-key-houseNumberSuffix input {
    width: 75px;
  }

  div.form-object-element > div.form-key-shippingAddress,
  div.form-object-element > div.form-key-billingAddress {
    > .form-element-label {
      font-size: 1.2em;
      font-weight: bold;
    }
  }

  div.form-object-element > div.form-key-billingAddress {
    display: none;
  }

  div.form-object-element > div.form-key-address {
    display: block;
  }

  &.billing-enabled {
    div.form-object-element > div.form-key-billingAddress {
      display: block;
    }
  }
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      billingAddressDisabled: null
    };
  }

  handleChange(value) {
    const {
      billingAddressDisabled
    } = value;

    this.setState({
      billingAddressDisabled
    });

    return value;
  }

  render() {
    const {
      billingAddressDisabled
    } = this.state;

    const value = {...this.props.value};

    // Remove country code and non-digits from phone number.
    if (typeof value.phone === 'string') {
      if (value.phone.startsWith('0031')) {
        value.phone = '0' + value.phone.substring(4);
      }
      value.phone = value.phone.replace(/[^\d]/g, '');
    }

    let hideBilling = true;
    if (billingAddressDisabled !== null) {
      hideBilling = billingAddressDisabled;
    } else {
      hideBilling = value.billingAddressDisabled;
    }

    const schema = {...this.props.schema};
    if (hideBilling) {
      // schema.required = ['shippingAddress'];
      schema.properties.billingAddress.required = [];
    }

    return (<Form className={`${hideBilling ? '' : 'billing-enabled'}`}>
      <EntityForm
        schema={schema}
        value={value}
        handleSubmit={this.props.handleSubmit}
        onChange={this.handleChange}
        onBlur={this.props.onBlur}
        loading={this.props.loading}
        messages={this.props.messages}
        maxWidth={550}
      />
    </Form>);
  }
}
