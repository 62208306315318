'use strict';

import styled from 'styled-components';
import ContentHeading from '../content-heading';
import Suggestion from '../list-items/suggestion';
import {CTACapsuleLink} from '../buttons';

const Container = styled.div`
  position: relative;

  > div.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    display: table;

    > h2 {
      color: white;
      text-align: center;
      font-style: italic;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .discipline {
    margin-bottom: 40px;
  }

  .box {
    margin-top: 40px;
  }

  .themes {
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
  }

  .themes span {
    display: inline-block;
    flex-basis: 50%;
    margin-bottom: 14px;
    cursor: pointer;

    input[type=checkbox] {
      margin-right: 1em;
    }
  }

  button {
    margin-left: -20px;
  }

  div.box {
    margin-top: 15px;
    background-color: white;
    border: 1px solid ${props => props.theme.lightGray};
  }

  .align-right {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(item) {
    return () => {
      const {getHandleAddTheme, getHandleRemoveTheme} = this.props;
      let func;

      if (item.themeObjectId) {
        func = getHandleRemoveTheme(item.themeObjectId);
      }
      else {
        func = getHandleAddTheme(item.id);
      }

      func();
    };
  }

  render() {
    const {disciplines, loading} = this.props;

    return (
      <div>
        <ContentHeading title="Mijn interesses"/>
        <Container>
          <p>
            Door uw interesses aan ons door te geven, kunnen wij gerichter communiceren met u. Wij gebruiken deze gegevens om u nog beter van interessante informatie te kunnen voorzien.
          </p>
          {disciplines.map(discipline => (<div key={discipline.title} className="discipline">
            <h1>{ discipline.title }</h1>
            <div className="themes">
              {discipline.themes.map(item => (<span key={item.title} className="theme">
                {item.title}
              </span>))}
            </div>
            {discipline.suggestions ? discipline.suggestions.map(suggestion => <Suggestion key={suggestion.title} {...suggestion}/>) : null}
          </div>))}
          <div>
            <CTACapsuleLink to="/interesses/aanpassen">Mijn interesses aanpassen</CTACapsuleLink>
          </div>
          {loading && <div className="overlay"><h2>Aan het opslaan...</h2></div>}
        </Container>
      </div>
    );
  }
}
