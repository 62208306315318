export default class extends React.Component {
  render() {
    return (<svg className={this.props.className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#000000" fillRule="nonzero">
          <g>
            <path d="M12,0 C5.37096774,0 0,5.37096774 0,12 C0,18.6290323 5.37096774,24 12,24 C18.6290323,24 24,18.6290323 24,12 C24,5.37096774 18.6290323,0 12,0 Z M12,4.64516129 C14.3516129,4.64516129 16.2580645,6.5516129 16.2580645,8.90322581 C16.2580645,11.2548387 14.3516129,13.1612903 12,13.1612903 C9.6483871,13.1612903 7.74193548,11.2548387 7.74193548,8.90322581 C7.74193548,6.5516129 9.6483871,4.64516129 12,4.64516129 Z M12,21.2903226 C9.15967742,21.2903226 6.61451613,20.0032258 4.91129032,17.9903226 C5.82096774,16.2774194 7.6016129,15.0967742 9.67741935,15.0967742 C9.79354839,15.0967742 9.90967742,15.116129 10.0209677,15.15 C10.65,15.3532258 11.3080645,15.483871 12,15.483871 C12.6919355,15.483871 13.3548387,15.3532258 13.9790323,15.15 C14.0903226,15.116129 14.2064516,15.0967742 14.3225806,15.0967742 C16.3983871,15.0967742 18.1790323,16.2774194 19.0887097,17.9903226 C17.3854839,20.0032258 14.8403226,21.2903226 12,21.2903226 Z"/>
          </g>
        </g>
      </g>
    </svg>);
  }
}
