export default {
  type: 'object',
  properties: {
    companyName: {
      title: 'Organisatie',
      type: 'string',
      maxLength: 255,
      minLength: 2,
      validation: 'Vul uw organisatie in'
    },
    salutation: {
      title: 'Aanhef',
      type: 'string',
      enum: ['heer', 'mevrouw', 'onbekend'],
      validation: 'Kies een aanhef'
    },
    lastName: {
      title: 'Achternaam',
      type: 'string',
      maxLength: 255,
      minLength: 2,
      validation: 'Vul een geldige achternaam in'
    },
    address: {
      title: 'Postadres',
      type: 'string',
      maxLength: 255,
      minLength: 2,
      validation: 'Vul een adres in'
    },
    city: {
      title: 'Plaats',
      type: 'string',
      maxLength: 255,
      minLength: 2,
      validation: 'Vul een plaatsnaam in'
    },
    phone: {
      title: 'Telefoonnummer',
      type: 'string',
      maxLength: 20,
      validation: 'Vul een geldig, Nederlands telefoonnummer in'
    },
    mail: {
      title: 'E-mail',
      type: 'string',
      maxLength: 255,
      format: 'email',
      validation: 'Vul een geldig e-mailadres in'
    },
    product: {
      title: 'Welk abonnement wilt u opzeggen?',
      type: 'string',
      maxLength: 255
    },
    reason: {
      title: 'Opzegreden',
      type: 'string',
      enum: [
        '',
        'De lezer heeft een andere functie binnen onze organisatie of is uit dienst getreden',
        'Ik heb geen tijd om het te lezen',
        'Ik ontvang dezelfde informatie al via een ander kanaal, namelijk via…',
        'Ik ben niet tevreden over de inhoud omdat…',
        'Financiële overwegingen',
        'Binnen onze organisatie wordt momenteel alle vakliteratuur opgezegd',
        'Bedrijfsbeëindiging/vestiging gaat sluiten',
        'Anders, namelijk…'
      ]
    },
    note: {
      title: 'Toelichting',
      type: 'string',
      maxLength: 255
    }
  },
  required: [
    'companyName',
    'salutation',
    'lastName',
    'address',
    'city',
    'phone',
    'mail',
    'product',
    'reason'
  ],
  additionalProperties: false
};
