'use strict';

import styled from 'styled-components';
import LoadingItem from './list-items/loading';

import FilterHeader from './filter-header';

const Container = styled.div`
	@media (max-width: ${ props => props.theme.breakpoints.big }px) {
		padding-top: 20px;
	}
`;

export default class extends React.Component {
  render() {
  	const {
			className,
			children,
			ContentWrapper,
			filterTitle,
			filters,
			filterLabels,
			changeFilter,
			clearFilters,
			items,
			itemRenderer,
			placeholder,
			loading,
			loadingText
		} = this.props;

		const Wrapper = ContentWrapper || React.Fragment;

    return (
    	<Container className={className}>
	    	<FilterHeader
	    		title={filterTitle}
	    		filters={filters}
					filterLabels={filterLabels}
					changeFilter={changeFilter}
					clearFilters={clearFilters}
	    	/>
				{ !loading && !items.length && placeholder}
				<ContentWrapper>
					{ items && items.length ? items.map( itemRenderer ) : '' }
					{ loading && <LoadingItem text={loadingText}/> } 
				</ContentWrapper>
				{ children }
	    </Container>
    );
  }
}
