'use strict';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(value) {
    if (!Array.isArray(this.props.value)) {
      this.props.onChange([value]);
    } else if (this.props.value.indexOf(value) >= 0) {
      this.props.onChange(this.props.value.filter(item => item !== value));
    } else {
      this.props.onChange([...this.props.value, value].sort());
    }
  }

  render() {
    const Component = this.props.templates.checkboxes;
    const checkboxes = this.props.schema.items.enum.map(value => ({
      value,
      checked: (this.props.value || []).indexOf(value) >= 0,
      label: value,
      onClick: () => this.handleClick(value)
    }));
    return <Component {...this.props} checkboxes={checkboxes}/>;
  }
}
