'use strict';

import styled from 'styled-components';
import EntityForm from '../entity-form';

const Form = styled.div`
  header {
    margin-bottom: 20px;

    h1 {
      padding-bottom: 0 !important;
    }

    a {
      margin-left: 40px;
      text-decoration: none;
    }
  }

  > div {
    p, h2, td {
      color: ${props => props.theme.almostBlack};
    }
    a {
      color: ${props => props.theme.activeBlue};
    }
  }

  > div > p:first-child {
    margin-top: 0;
  }

  .form-key-note {
    display: none !important;
  }
  .show-note-field .form-key-note {
    display: flex !important;
  }
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayNote: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(values) {
    this.setState({
      displayNote: String(values.reason).indexOf('…') >= 0
    });
    return values;
  }

  render() {
    const className = this.state.displayNote ? 'show-note-field' : '';
    return (<Form>
      <div className={className}>
        {this.props.submitted && (
          <React.Fragment>
            <p>
              Uw opzegging is ontvangen en wordt binnenkort verwerkt.
              U ontvangt een bevestiging per e-mail.
            </p>
          </React.Fragment>
        )}
        {!this.props.submitted && (
          <React.Fragment>
            <p>
              Wij vinden het jammer dat u overweegt om uw abonnement op te zeggen.
            </p>
            <p>
              Voor het beëindigen van uw abonnement kunt u onderstaand opzegformulier
              invullen. Opzeggingen worden zo spoedig mogelijk conform onze
              {' '}
              <a href="https://www.rendement.nl/av" target="_blank">algemene voorwaarden</a>
              {' '}
              verwerkt.
            </p>
            <p>
              Indien u specifieke vragen heeft over uw abonnement of over het opzeggen
              van het abonnement kunt u contact opnemen met onze klantenservice.
              U kunt hiervoor het
              {' '}
              <a href="https://www.rendement.nl/formulieren/id82-contactformulier.html">contactformulier</a>
              {' '}
              invullen, bellen of e-mailen.
              Wij staan voor u klaar op werkdagen van 9.00 uur tot 17.00 uur op
              telefoonnummer 010-2433933. Mailen kan naar
              {' '}
              <a href="mailto:klantenservice@rendement.nl">klantenservice@rendement.nl</a>.
            </p>
            <EntityForm
              schema={this.props.schema}
              value={this.props.value}
              handleSubmit={this.props.onSubmit}
              onChange={this.handleChange}
              onBlur={this.props.onBlur}
              loading={this.props.loading}
              messages={this.props.messages}
              maxWidth={550}
              initError={true}
              />
          </React.Fragment>
        )}
      </div>
    </Form>);
  }
}
