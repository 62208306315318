'use strict';

import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import NewsLetters from '../../components/screens/news-letters';

const query = gql`query {
  User: currentUser {
    id
    newsletters {
      items {
        id
        newsletter {
          id
        }
      }
    }
  }

  newsletters: listNewsletter(order: {
    field: "title",
    direction: ASC
  }) {
    items {
      id
      title
      description
      url
      newsletterAdditions {
        items {
          title
          description
        }
      }
    }
  }
}`;

@graphql(query, {
  props: ({data: {User, newsletters}}) => ({User, newsletters})
})
@graphql(gql`
  mutation($newsLetterId: ID!) {
    deleteUserNewsletter(id:$newsLetterId){
      id
    }
  }
`, {
  name: 'deleteNewsletterSubscription',
})
@graphql(gql`
  mutation($userId: ID!, $newsLetterId: ID!) {
    createUserNewsletter(input:{user:$userId, newsletter:$newsLetterId}){
      id
    }
  }
`, {
  name: 'createNewsLetterSubscription'
})
export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {loading:false};
  }
  toggleNewsletterSubscription(newsletter) {
    return () => {
      this.setState({loading: true});
      const {enabled, id} = newsletter.props;
      if (enabled) {
        this.props.deleteNewsletterSubscription({
          variables: {newsLetterId: id},
          refetchQueries: [{
            query
          }]
        }).then(() => {
          setTimeout(() => {
            this.setState({loading: false});
          }, 500);
        });
      }
      else {
        this.props.createNewsLetterSubscription({
          variables: {userId: this.props.User.id, newsLetterId: id},
          refetchQueries: [{
            query
          }]
        }).then(() => {
          setTimeout(() => {
            this.setState({loading: false});
          }, 500);
        });
      }
    }
  }

  render() {
    if (!this.props.User) {
      return null;
    }
    const {loading} = this.state;

    const {
      newsletters: {items: enabledNewsletters}
    } = this.props.User;

    const table = [
      ['ARBO', 'Vakgebied arbo'],
      ['FA REN', 'Vakgebied financiële administratie'],
      ['FIS', 'Vakgebied fiscaal'],
      ['HR REN', 'Vakgebied HR'],
      ['MANAGE', 'Vakgebied management'],
      ['MARKET', 'Vakgebied marketing'],
      ['BESTUU', 'Vakgebied non-profit'],
      ['OFFICE', 'Vakgebied officemanagement'],
      ['REN', 'Vakgebied ondernemen'],
      ['ORREN', 'Vakgebied OR'],
      ['SALARI', 'Vakgebied salarisadministratie'],
      ['FOF', 'Fundament voor Onderneming & Fiscus', ['FOF', 'FNDTOT']],
      ['FOAD', 'Fundament voor Onderneming & Administratie', ['FOAD', 'FNDTOT']],
      ['FOS', 'Fundament voor Onderneming & Salaris', ['FOS', 'FNDTOT']],
      ['FOM', 'Fundament voor OR & Medezeggenschap', ['FOM', 'FNDTOT']],
      ['FOP', 'Fundament voor Onderneming & Personeel', ['FOP', 'FNDTOT']],
      ['FOA', 'Fundament voor Onderneming & Arbo', ['FOA', 'FNDTOT']]
    ];

    // Get user subscriptions from JWT token.
    const tokenValue = JSON.parse(atob(localStorage.getItem('accessToken').split('.')[1])).subscriptions;
    const subscriptions = tokenValue ? ((Array.isArray(tokenValue) ? tokenValue : [tokenValue]).map(s => s.split(':')[0])) : [];

    const codesFound = this.props.newsletters.items.map(({id}) => id);
    const codesEnabled = this.props.User.newsletters.items.map(({newsletter: {id}}) => id);
    const newsletters = table.filter(([id, title, require]) => {
      if (require && require.filter(e => subscriptions.indexOf(e) >= 0).length === 0) {
        return false;
      }
      return codesFound.indexOf(id) >= 0
    }).map(([id, title]) => ({
      id,
      title,
      description: null,
      enabled: codesEnabled.indexOf(id) >= 0,
      url: `https://www.rendement.nl/nieuwsbrief/nieuwsbrief/${id}`
    }));

    return <NewsLetters
      loading={loading}
      newsletters={newsletters}
      toggleNewsletterSubscription={this.toggleNewsletterSubscription.bind(this)}
    />
  }
}
