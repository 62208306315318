import gql from "graphql-tag";

module.exports = gql`query($id: ID!) {
  EventScheduleData(id: $id) {
    id
    title
    sessions {
      items {
        id
        start
      }
    }
    eventSchedule {
      id
      hasEvaluation
      hasPreparation
      event {
        id
        group {
          id
          type
        }
      }
    }
    loggedInParticipant {
      id
      paid
      finishedEvaluation
      finishedPreparation
      certificateToken
      participations (limit: 1000) {
        items {
          id
          participated
        }
      }
    }
  }
}`;
