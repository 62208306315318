import Date from './date';
import moment from "../lib/moment";
import styled from 'styled-components';

const Container = styled.div`
  background: white;
  border-radius: 7px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
   
  .date-wrapper {
    margin-right: 10px;
  }

  .info {
    font-size: 0.85em;

    .time {
      color: ${props => props.theme.actionOrange};
      font-weight: bold;
    }

    > p {
      margin: 0;
      color: ${props => props.theme.darkGray};
      a {
        color: ${props => props.theme.darkGray};
      }
    }
  }
  
  @media (min-width: ${ props => props.theme.breakpoints.xl }px) {
    padding: 20px;
    
    .teacher {
      height: 46px;
    }

    .date-wrapper {
      margin-right: 20px;
    }
  }
  
  @media (min-width: 1800px) {
    .info {
      font-size: 1em;
    }
  }
`;

export default class extends React.Component {
  render() {
    const {
      locationInfo,
      eventTeacher,
      routeUrl
    } = this.props;

    const start = moment(this.props.start, 'YYYY-MM-DD HH:mm:ss');
    const end = moment(this.props.end, 'YYYY-MM-DD HH:mm:ss');

    return (<Container>
      <div className="date-wrapper">
        <Date date={start}/>
      </div>

      <div className="info">
        <div className="time">{start.format('HH:mm')} - {end.format('HH:mm')}</div>
        <p>{locationInfo}</p>
        {routeUrl && <p><a href={routeUrl} target="_blank">Routebeschrijving</a></p>}
        <p className="teacher">{eventTeacher.name}</p>
      </div>
    </Container>);
  }
}