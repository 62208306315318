import gql from "graphql-tag";

export default gql`query ($code: ID!) {
  currentUser {
    id
    initials
    lastName
  }
  TeacherEventSession (id: $code) {
    id
    start
    end
    eventName
    location
    teacherNote
    status {
      items {
        id
        material
        participants
      }
    }
    documents {
      count
      items {
        id
        extension
        file
        name
        source
        type
        url
      }
    }
    participants {
      count
      items {
        id
        firstName
        initials
        lastName
        companyName
        present
      }
    }
  }
}`
