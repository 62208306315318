export default class extends React.Component {
  render() {
    return (<svg className={this.props.className} width="15px" height="22px" viewBox="0 0 15 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	      <g transform="translate(-481.000000, -514.000000)" fill="#000000" fillRule="nonzero">
          <g transform="translate(481.000000, 514.000000)">
            <path d="M7.52007156,0 C4.11778297,0 1.91411068,1.40520703 0.184002661,3.91127344 C-0.129845557,4.36588281 -0.0330867013,4.9891875 0.404715153,5.32382813 L2.24347442,6.72929297 C2.68562402,7.06724219 3.31472709,6.98822266 3.66088509,6.55105859 C4.72859988,5.20265625 5.52061588,4.42629688 7.18840608,4.42629688 C8.49972301,4.42629688 10.1216913,5.27703516 10.1216913,6.55887891 C10.1216913,7.52791016 9.32814079,8.02557422 8.03336238,8.75733203 C6.52345535,9.61069141 4.52536366,10.672707 4.52536366,13.3294219 L4.52536366,13.75 C4.52536366,14.3195508 4.98336979,14.78125 5.54836478,14.78125 L8.63744454,14.78125 C9.20243953,14.78125 9.66044566,14.3195508 9.66044566,13.75 L9.66044566,13.5019414 C9.66044566,11.6603008 15,11.5836016 15,6.6 C15.0000426,2.8469375 11.1381281,0 7.52007156,0 Z M7.09292597,16.0470664 C5.46481969,16.0470664 4.14024637,17.3823203 4.14024637,19.0235547 C4.14024637,20.6647461 5.46481969,22 7.09292597,22 C8.72103225,22 10.0456056,20.6647461 10.0456056,19.0235117 C10.0456056,17.3822773 8.72103225,16.0470664 7.09292597,16.0470664 Z"></path>
          </g>
	      </g>
	    </g>
		</svg>);
  }
}
