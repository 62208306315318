import Document from '../document';
import Block from '../block';
import styled from 'styled-components';

const Container = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.big}px) {
    padding-right: 0;
    margin-right: 0 !important;
  }
`;

const List = styled.ul`
  margin: 20px;
  padding: 0;

  > li {
    list-style-type: none;
  }
`;

export default class extends React.Component {
  static defaultProps = {
    title: 'Materiaal'
  };

  render() {
    const {
      material,
      title,
      id
    } = this.props;

    return (<Container>
      {material.map(({
        title: materialTitle = title,
        description,
        documents
      }) => <Block key={materialTitle} title={materialTitle}>
        <p>{description}</p>
        <List>
          {documents.map(doc => (<li key={doc.id}>
            <Document {...doc} id={id}/>
          </li>))}
        </List>
      </Block>)}
    </Container>);
  }
}
