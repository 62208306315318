'use strict';

import styled from 'styled-components';
import moment from '../../lib/moment';
import AddressTable from '../address-table';
import {CTACapsuleLink, SubduedCapsuleLink} from "../buttons";

const Wrapper = styled.div`
  header {
    margin-bottom: 20px;

    h1 {
      padding-bottom: 0 !important;
    }

    a {
      margin-left: 40px;
    }
  }

  > div {
    p, h2, td {
      color: ${props => props.theme.almostBlack};
    }
  }

  > div > p:first-child {
    margin-top: 0;
  }

  div.address-tables {
    margin-bottom: 45px;
  }
`;

const FlexList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > *:not(:first-child) {
    margin-left: ${({spacing}) => spacing}px;
  }
`;

export default ({
  title,
  url,
  active,
  user: {
    companyName,
    billingAddress: userBilling,
    shippingAddress: userShipping
  },
  billingAddress: productBilling,
  shippingAddress: productShipping,
  startDate,
  endDate
}) => {
  const billingAddress = productBilling && productBilling.city ? productBilling : (userBilling || {
    postalCode: '',
    houseNumber: '',
    houseNumberSuffix: '',
    street: '',
    locality: ''
  });
  const shippingAddress = productShipping && productShipping.city ? productShipping : (userShipping || {
    postalCode: '',
    houseNumber: '',
    houseNumberSuffix: '',
    street: '',
    locality: ''
  });

  return (<Wrapper>
    <div>
      <p>Huidige abonnementsperiode: {moment(startDate).format('D MMMM YYYY')} tot {moment(endDate).format('D MMMM YYYY')}</p>
      <FlexList spacing={150} className="address-tables">
        <div className="address-container">
          <h2>Verzendgegevens</h2>
          <AddressTable {...{
            ...(companyName ? {companyName} : {}),
            postalCode: shippingAddress.postalCode,
            houseNumber: `${shippingAddress.houseNumber}${shippingAddress.houseNumberSuffix || ''}`,
            street: shippingAddress.street,
            locality: shippingAddress.city
          }}/>
        </div>
        <div className="address-container">
          <h2>Facturatiegegevens</h2>
          <AddressTable {...{
            ...(companyName ? {companyName} : {}),
            postalCode: billingAddress.postalCode,
            houseNumber: `${billingAddress.houseNumber}${billingAddress.houseNumberSuffix || ''}`,
            street: billingAddress.street,
            locality: billingAddress.city
          }}/>
        </div>
      </FlexList>
      <FlexList spacing={15}>
        {active && <CTACapsuleLink to={`${url}/wijzigen`}>Gegevens wijzigen</CTACapsuleLink>}
        {active && <SubduedCapsuleLink to={`${url}/opzeggen`}>Ik wil dit abonnement opzeggen</SubduedCapsuleLink>}
      </FlexList>
    </div>
  </Wrapper>);
}
