import gql from "graphql-tag";

import withApolloLoader from '../with-apollo-loader';

import CongressDetails from '../../components/screens/congress-details';
import ContentHeading from '../../components/content-heading';

export default withApolloLoader({
  query: gql`query($id: ID!) {
    EventScheduleData(id: $id) {
      id
      remarks
      startDate
      title
      endDate
      eventLocation {
        id
        name
        street
        houseNumber
        houseNumberSupplement
        city
        postalCode
        routeUrl
      }
      eventSchedule {
        id
        event {
          id
          name
          group { id type }
        }
        hasEvaluation
        hasPreparation
      }
      sessions {
        items {
          id
          code
          eventTeacher { id name }
          description
          start
          end
          location
        }
      }
      loggedInParticipant {
        id
        paid
        finishedEvaluation
        finishedPreparation
        certificateToken
        participations(limit: 1000) {
          items {
            id
            eventSession { id }
            participated
          }
        }
      }
    }
  }`,
  transform: data => {
    if (typeof data.EventScheduleData !== 'undefined') {
      return { title: data.EventScheduleData.title };
    }
  },
  getVariables: ({ match: { params: { id } } }) => ({ id }),
  getChildProps: ({ match: { params: { id } } }) => ({ id }),
  LoaderComponent: () => <ContentHeading title="Uw congres wordt geladen..." />
})(CongressDetails);
