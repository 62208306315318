import styled from 'styled-components';
import {TransparentAnchor, ChevronCapsuleButton} from "./buttons";

const Document = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.lightGray};
  padding: 10px 0;

  h5 {
    font-size: 1.2em;
    margin: 0;
  }

  span {
    color: ${props => props.theme.darkGray};
  }

  > *:last-child {
    flex-shrink: 0;
  }
`;

export default class extends React.Component {
  static defaultProps = {
    size: false,
    extension: false
  };

  render() {
    const {
      name,
      size,
      documentToken,
      extension,
      id
    } = this.props;

    return <Document>
      <div>
        <h5>{name}</h5>
        {extension && size && <span>{extension} | {size}</span>}
      </div>
      <div>
        <TransparentAnchor style={{marginRight: '15px'}} href={`/download-document/${documentToken}`}>Download</TransparentAnchor>
        <ChevronCapsuleButton to={`/opleiding/${id}/materiaal/${documentToken}`}>Bekijken</ChevronCapsuleButton>
      </div>
    </Document>;
  }
}
