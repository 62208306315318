export default class extends React.Component {
  render() {
    return (<svg
      width="22px"
      height="22px"
      viewBox="50 30 20 60" {...this.props}
    >
      <path
        d="M60 86.8L55.8 83c-15-13.6-24.9-22.6-24.9-33.6 0-9 7.1-16 16-16 5.1 0 9.9 2.4 13.1 6.1 3.2-3.7 8-6.1 13.1-6.1 9 0 16 7.1 16 16 0 11-9.9 20-24.9 33.7L60 86.8z"
        fill="#000000"
      />
    </svg>);
  }
}
