'use strict';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import moment from "../../lib/moment";

import MySupportRequest from '../../components/screens/my-support-request';

@graphql(gql`query {
  User: currentUser {
    id
    supportRequests {
      items {
        id
        title
        question
        date
        url
        status
        rating
        replies {
          items {
            reply
            name
            function
            image
            date
          }
        }
      }
    }
  }
}`, {
  props: ({data}) => {
    if (!data.loading && typeof data.User !== 'undefined') {
      const requests = data.User.supportRequests.items;
      return {requests};
    }
  }
})
export default class extends React.Component {
  render() {
    const {match: {params: {id}}, requests} = this.props;
    if (!requests) {
      return null;
    }
    const request = requests.filter(e => e.id === id)[0];
    if (!request) {
      return null;
    }
    return <MySupportRequest request={request}/>
  }
}
