'use strict';

import TeacherSession from '../list-items/teacher-session';

export default class extends React.Component {
  render() {
    const {sessions} = this.props;

    return (
      <div>
        { sessions.length
          ? sessions.map( item => {
            return (<TeacherSession key={ item.id } {...item} url={`/docent/sessie/${item.id}`} />);
          }) : (
            <p>
              Deze lijst bevat momenteel geen opleidingensessies die aan uw account zijn gekoppeld.
            </p>
          )
        }
      </div>
    );
  }
}
