'use strict';

import styled from 'styled-components';
import { Route } from 'react-router-dom';

import ContentHeading from '../content-heading';
import TabGroup from '../tab-group';
import TabLink from '../tab-link';
import PurchasesHOC from '../../containers/screens/purchases';
import NewsLettersHOC from '../../containers/screens/news-letters';
import SubscriptionsHOC from '../../containers/screens/subscriptions';

export default class extends React.Component {
  render() {
    return (
    	<div>
    		<ContentHeading title="Mijn producten">
          <TabGroup>
            <TabLink to="/producten">Abonnementen</TabLink>
            {/* <TabLink to="/producten/losse-producten">Losse producten</TabLink> */}
            {/* <TabLink to="/producten/nieuwsbrieven">Nieuwsbrieven</TabLink> */}
          </TabGroup>
        </ContentHeading>
        <Route
          exact
          path="/producten"
          component={SubscriptionsHOC}
        />
        <Route
          exact
          path="/producten/losse-producten"
          component={PurchasesHOC}
        />
        <Route
          exact
          path="/producten/nieuwsbrieven"
          component={NewsLettersHOC}
        />
    	</div>
    );
  }
}
