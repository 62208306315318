import styled from 'styled-components';

import sanitize from '../lib/sanitize';

import Modal from '../containers/modal';

const Wrapper = styled.div`
  background-color: white;
  padding: 20px;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 2px 3px ${props => props.theme.almostBlack};

  button.close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    font-size: 25px;
  }

  h1 {
    margin: 0;
    font-size: 20px;
  }

  p {
    width: 300px;
  }

  div.buttons-wrapper {
    display: flex;
    flex-direction: row-reverse;

    > *:not(:first-child) {
      margin-right: 15px;
    }
  }
`;

export default ({ title, body, buttons, onClose }) => <Modal onClose={onClose}>
  <Wrapper>
    <button className="close-button" onClick={onClose}>×</button>
    <h1>{title}</h1>
    {typeof body === 'string' && (
      <p dangerouslySetInnerHTML={sanitize(body)} />
    )}
    {typeof body === 'object' && body}
    <div className="buttons-wrapper">{buttons}</div>
  </Wrapper>
</Modal>;
