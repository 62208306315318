'use strict';

import withApolloLoader from '../with-apollo-loader';

import TeacherSessions from '../../components/screens/teacher-sessions';

import teacherSessionsQuery from '../../queries/teacher-sessions';

export default withApolloLoader({
  query: teacherSessionsQuery,
  variables: {
    sort: 'DESC'
  },
  transform: ({listTeacherEventSession}) => {
    const sessions = listTeacherEventSession.items;
    return {sessions};
  },
  LoaderComponent: () => <div>Trainingssessies worden ingeladen...</div>
})(TeacherSessions);
