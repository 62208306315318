import Calendar from './calendar';
import CalendarDate from './calendar-date';
import ChartBar from './chart-bar';
import ChevronDown from './chevron-down';
import ChevronRight from './chevron-right';
import Close from './close';
import Dashboard from './dashboard';
import Download from './download';
import DropdownArrow from './dropdown-arrow';
import ELearning from './e-learning';
import Hamburger from './hamburger';
import Heart from './heart';
import Learning from './learning';
import NoParking from './no-parking';
import QuestionMark from './question-mark';
import Star from './star';
import Tag from './tag';
import UserRounded from './user-rounded';
import Cross from './cross';
import Check from './check';
import Chat from './chat';
import Cog from './cog';

import styled from 'styled-components';

const BlueChevronRight = styled(ChevronRight)`
	transform: scale(0.69) translateY(-2px);
	vertical-align: sub;

	[fill] {
		fill: ${props => props.theme.activeBlue};
	}
`;

export {
	Chat,
	Check,
	Cross,
	BlueChevronRight,
	CalendarDate,
	Learning,
	Star,
	Calendar,
	ChartBar,
	ChevronDown,
	ChevronRight,
	Close,
	Dashboard,
	Download,
	DropdownArrow,
	ELearning,
	Hamburger,
	Heart,
	NoParking,
	QuestionMark,
	Tag,
	UserRounded,
	Cog
};
