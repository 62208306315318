'use strict';

import moment, { ISODATEFORMAT } from 'moment';
import { orderBy } from 'lodash';

import withApolloLoader from '../with-apollo-loader';

import MyUpcomingEducations from '../../components/screens/my-upcoming-educations';

import getStartAndEndTime from '../../lib/start-end-times';

import eventScheduleQuery from '../../queries/event-schedule';

export default withApolloLoader({
  query: eventScheduleQuery,
  transform: ({ User }) => {
    const current = moment().subtract(7, 'days').unix();

    const events = User.events.items.filter(({
      eventScheduleData: { eventSchedule: { event: { group: { type } } } }
    }) => type == 'course' || type == 'education' || type == 'in_company').map(item => {
      const startDates = item.eventScheduleData.sessions.items.map(item => moment(item.start, ISODATEFORMAT).unix()).sort();
      const endDates = item.eventScheduleData.sessions.items.map(item => moment(item.end, ISODATEFORMAT).unix()).sort();

      const dates = startDates.filter(item => {
        return item > current;
      }).sort();

      return {
        id: item.id,
        type: item.eventScheduleData.eventSchedule.event.group.type,
        title: item.eventScheduleData.title,
        url: `/opleiding/${item.eventScheduleData.id}`,
        timestamp: dates.length ? dates[0] : false,
        date: dates.length ? moment.unix(dates[0]) : false,
        location: item.eventScheduleData.eventLocation ? item.eventScheduleData.eventLocation.name : null,
        ...getStartAndEndTime(item.eventScheduleData.sessions.items, unix => unix ? moment.unix(unix).format('HH:mm') : false)
      };
    }).filter(({ timestamp }) => timestamp)

    return { events: orderBy(events, ['timestamp', 'asc']) };
  },
  LoaderComponent: () => <div>Uw opleidingen worden ingeladen...</div>
})(MyUpcomingEducations);
