import styled from 'styled-components';

const Table = styled.table`
  td {
    line-height: 1.6rem;
  }

  td:first-child {
    width: 150px;
  }

  td:nth-child(2) {
    color: ${props => props.theme.mediumGray};
  }
`;

export default ({
  organization,
  postalCode,
  houseNumber,
  street,
  locality
}) => <Table>
  <tbody>
    {organization ? <tr>
      <td>Organisatie:</td>
      <td>{organization}</td>
    </tr> : null}
    <tr>
      <td>Postcode:</td>
      <td>{postalCode}</td>
    </tr>
    <tr>
      <td>Huisnummer:</td>
      <td>{houseNumber}</td>
    </tr>
    <tr>
      <td>Straat:</td>
      <td>{street}</td>
    </tr>
    <tr>
      <td>Plaats:</td>
      <td>{locality}</td>
    </tr>
  </tbody>
</Table>;
