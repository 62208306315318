export default class extends React.Component {
  render() {
    return (<svg className={this.props.className} width="26px" height="18px" viewBox="0 0 26 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#000000" fillRule="nonzero">
          <g>
            <path d="M4.72727273,17.15625 L4.72727273,12.09375 C4.72727273,11.6296875 4.32840909,11.25 3.84090909,11.25 L0.886363636,11.25 C0.398863636,11.25 0,11.6296875 0,12.09375 L0,17.15625 C0,17.6203125 0.398863636,18 0.886363636,18 L3.84090909,18 C4.32840909,18 4.72727273,17.6203125 4.72727273,17.15625 Z M18.9090909,17.15625 L18.9090909,7.59375 C18.9090909,7.1296875 18.5102273,6.75 18.0227273,6.75 L15.0681818,6.75 C14.5806818,6.75 14.1818182,7.1296875 14.1818182,7.59375 L14.1818182,17.15625 C14.1818182,17.6203125 14.5806818,18 15.0681818,18 L18.0227273,18 C18.5102273,18 18.9090909,17.6203125 18.9090909,17.15625 Z M11.8181818,17.15625 L11.8181818,3.09375 C11.8181818,2.6296875 11.4193182,2.25 10.9318182,2.25 L7.97727273,2.25 C7.48977273,2.25 7.09090909,2.6296875 7.09090909,3.09375 L7.09090909,17.15625 C7.09090909,17.6203125 7.48977273,18 7.97727273,18 L10.9318182,18 C11.4193182,18 11.8181818,17.6203125 11.8181818,17.15625 Z M26,17.15625 L26,0.84375 C26,0.3796875 25.6011364,0 25.1136364,0 L22.1590909,0 C21.6715909,0 21.2727273,0.3796875 21.2727273,0.84375 L21.2727273,17.15625 C21.2727273,17.6203125 21.6715909,18 22.1590909,18 L25.1136364,18 C25.6011364,18 26,17.6203125 26,17.15625 Z"></path>
          </g>
        </g>
      </g>
    </svg>);
  }
}
