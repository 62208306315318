export default {
  pagination: {
    activities: 10,
    subscriptions: 4,
    purchases: 4,
    visitedEvents: 4
  },
  mailingsDescription: {
    1: 'Nieuws en aankondigingen over aankomende evenementen, congressen & opleidingen.',
    2: 'Informatie en de laatste ontwikkelingen op het gebied van onze papieren uitgaven.',
    3: 'Informatie en de laatste ontwikkelingen op het gebied van onze online uitgaven.'
  },
  filterMapping: {
    FAVORITE: 0,
    DOWNLOAD: 1,
    ELEARNING: 2,
    ADVIES_DESK: 3
  }
};
