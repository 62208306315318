import Block from './block';
import {ChevronCapsuleAnchor} from "./buttons";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  padding: 25px 0;
`;

export default class extends React.Component {
  render() {
    const {
      token
    } = this.props;

    const url = `/download-certificate/${token}`;
    return (<Block title="Certificaat" description="Download hier uw certificaat van deelname" hasBackground>
      <Container>
        <ChevronCapsuleAnchor href={url}>Download certificaat</ChevronCapsuleAnchor>
      </Container>
    </Block>);
  }
}