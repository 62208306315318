'use strict';

import {TransparentAnchor, TransparentLink} from '../buttons';
import Table from '../table';
import Adviser from '../adviser';
import {isEmpty} from 'lodash';

export default class extends React.Component {
  render() {
    const {requests} = this.props;

    if (!requests.length) {
      return <div><p>U heeft op dit moment geen advies aanvragen uitstaan.</p></div>;
    }

    return <div>
      <Table
        columns={['title', 'date', 'status', 'adviser']}
        columnLabels={{
          title: 'Vraag',
          date: 'Datum',
          status: 'Status',
          adviser: 'Adviseur'
        }}
        columnWidths={{
          question: 4,
          date: 1,
          status: 2,
          adviser: 2
        }}
        columnTypes={{
          status: 'html'
        }}
        items={requests.map(({title, date, status, replies, id, url}) => ({
          key: id,
          title: <TransparentLink to={`/advies/${id}`}>{title}</TransparentLink>,
          date: date.format('DD-MM-YYYY'),
          adviser: <Adviser
            adviser={replies.items.length ? {
              name: replies.items[replies.items.length - 1].name,
              image: ''
            } : {
              name: 'Rendement',
              image: ''
            }}
          />,
          status: {
            IN_PROGRESS_ADVISOR: <span><i className="rendement rendement-busy warning"></i> In behandeling bij adviseur</span>,
            ANSWERED_BY_ADVISOR: <span><i className="rendement rendement-mail warning"></i> Beantwoord door adviseur</span>,
            REQUIRES_INFO: <span><i className="rendement rendement-warning crimson"></i> Info vereist</span>,
            REQUIRES_RATING: <span><TransparentAnchor target="_blank" href={url}>Waardeer het antwoord</TransparentAnchor></span>
          }[status] || <span><i className="rendement rendement-busy purple"></i> In behandeling bij Rendement</span>
        }))}
      />
    </div>
  }
}
