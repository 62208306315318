export default class extends React.Component {
  render() {
    return (<svg className={this.props.className} width="20px" height="19px" viewBox="0 0 20 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-2.000000, -2.000000)">
          <g>
            <polygon fill="#000000" fillRule="nonzero" points="12 17.27 18.18 21 16.54 13.97 22 9.24 14.81 8.63 12 2 9.19 8.63 2 9.24 7.46 13.97 5.82 21"></polygon>
            <polygon points="0 0 24 0 24 24 0 24"></polygon>
          </g>
        </g>
      </g>
    </svg>);
  }
}
