'use strict';

import styled from 'styled-components';
import sanitize from '../../lib/sanitize';
import { ChevronCapsuleAnchor, TransparentAnchor } from '../buttons';

const Page = styled.div`
  h2 {
    font-size: 100%;
  }
  .status {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .post {
    border: 1px solid rgb(202,202,202);
    border-radius: 10px;
    padding: 25px;
    background-color: white;
    margin-top: 20px;
  }
  .question {
    margin-right: 20px;
  }
  .reply {
    margin-left: 20px;
  }
  .meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .sender {
    font-weight: bold;
  }
  hr {
    border: 0;
    border-bottom: 1px solid rgb(202,202,202);
    margin-bottom: 20px;
  }
  .cta {
    margin-top: 20px;
  }
  h2 {
    margin: 0;
    padding: 0 20px 0 0;
  }
`;

export default class extends React.Component {
  render() {
    const {request} = this.props;

    return (
      <Page>
        <h1>{request.title}</h1>

        <div className="status">
          <div>
            <h2>Status:</h2>
          </div>
          <div className="summary">
            {{
              IN_PROGRESS_ADVISOR: <span><i className="rendement rendement-busy warning"></i> In behandeling bij adviseur</span>,
              ANSWERED_BY_ADVISOR: <span><i className="rendement rendement-mail warning"></i> Beantwoord door adviseur</span>,
              REQUIRES_INFO: <span><i className="rendement rendement-warning crimson"></i> Info vereist</span>,
              REQUIRES_RATING: <span><i className="rendement rendement-mail warning"></i> Waardeer het antwoord</span>
            }[request.status] || <span><i className="rendement rendement-busy purple"></i> In behandeling bij Rendement</span>}
          </div>
        </div>

        {request.status === 'REQUIRES_INFO' && (
          <div className="cta">
            <ChevronCapsuleAnchor href={request.url || 'https://rendement.nl/'} target="_blank">Geef meer informatie op Rendement.nl</ChevronCapsuleAnchor>
          </div>
        )}
        {(request.status === 'REQUIRES_RATING' || request.status === 'ANSWERED_BY_ADVISOR') && (
          <div className="cta">
            <ChevronCapsuleAnchor href={request.url || 'https://rendement.nl/'} target="_blank">Geef uw waardering op Rendement.nl</ChevronCapsuleAnchor>
          </div>
        )}

        <div className="post question">
          <div className="meta">
            <div className="sender">
              Uw vraag
            </div>
            {request.date && (
              <div className="date">
                {request.date.split(' ')[0]}
              </div>
            )}
          </div>
          <hr/>
          <div className="text">
            <div dangerouslySetInnerHTML={sanitize(request.question)}/>
          </div>
        </div>

        {request.replies.items.map((item, index) => (
          <div className="post reply" key={`${item.date}-${index}`}>
            <div className="meta">
              <div className="sender">
                Reactie {item.name}
              </div>
              {item.date && (
                <div className="date">
                  Op {item.date.split(' ')[0]}
                </div>
              )}
            </div>
            <hr/>
            <div className="text">
              <div dangerouslySetInnerHTML={sanitize(item.reply)}/>
            </div>
          </div>
        ))}
      </Page>
    );
  }
}
