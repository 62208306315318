'use strict';

export default class extends React.Component {
  render() {
    const schema = this.props.schema;
    const items = schema.enum.map(value => {
      return <option key={value} value={value}>{value || 'Maak een keuze'}</option>;
    });

    const handleChange = ({target}) => this.props.onChange(target.value);

    return (<this.props.templates.formElement {...{...this.props, template: this.props.template || 'dropdown'}}>
      <select
        value={this.props.value}
        onChange={handleChange}
        >
        {items}
      </select>
    </this.props.templates.formElement>);
  }
}
