import Subscription from '../list-items/subscription';
import ShowMoreTab from '../show-more-tab';
import settings from '../../settings';

const paginationLimit = settings.pagination.subscriptions;

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: true
    };
  }

  showAll() {
    this.setState({limit: false});
  }

  render() {
    const {subscriptions, count} = this.props;
    const {limit} = this.state;

    return (<div>
      { subscriptions.length ?
        (limit ? subscriptions.slice(0, paginationLimit) : subscriptions).map(item => {
          return (<Subscription key={item.id} {...item}/>);
        }) :
        'Er zijn nog geen abonnementen om weer te geven.'
      }
      { limit && subscriptions.length > paginationLimit && <ShowMoreTab onClick={this.showAll.bind(this)}>Toon alle { count } abonnementen</ShowMoreTab> }
    </div>);
  }
}
