import styled from 'styled-components';

import {weigh} from '../lib/functions';

const TableWrapper = styled.div`
  overflow-x: auto;

  table {
    width: 100%;
    min-width: 600px;
    position: relative;
    color: rgb( 74,74,74 );

    thead {
      font-weight: 900;
    }

    td {
      box-sizing: border-box;
      padding: 5px;
    }
  }
`;

export default ({columns, columnLabels, columnWidths, items, className}) => {
  const weightedColumnWidths = weigh(columnWidths);

  return (
    <TableWrapper className={className}>
      <table cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            {columns.map(columnKey => <td key={columnKey} style={columnWidths ? {width: `${weightedColumnWidths[columnKey] * 100}%`} || {} : {}}>{columnLabels[columnKey]}</td>)}
          </tr>
        </thead>
        <tbody>
          {items.map(({key, ...item}) => <tr key={key}>
            {columns.map(columnKey => <td key={columnKey}>{item[columnKey]}</td>)}
          </tr>)}
        </tbody>
      </table>
    </TableWrapper>
  );
};
