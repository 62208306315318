'use strict';

import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import Purchases from '../../components/screens/purchases';

@graphql(gql`query {
  User: currentUser {
    id
    purchases(order: {
      field: "created",
      direction:DESC
    }) {
      count
      items {
        id
        title
        description
        logo
        url
      }
    }
  }
}`, {
  props: ({data: {User}}) => User
})
export default class extends React.Component {
  render() {
    if (!this.props.purchases) {
      return null;
    }

    const {
      purchases: {
        count,
        items: purchases
      }
    } = this.props;

    if (!purchases) {
      return null;
    }

    return (<Purchases
      purchases={purchases}
      count={count}
    />);
  }
}
