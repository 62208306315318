'use strict';

import styled from 'styled-components';
import {CTACapsuleLink, TransparentLink} from "../buttons";

const Wrapper = styled.div`
  td {
    line-height: 1.6rem;
  }
  td:first-child {
    width: 200px;
  }

  td:nth-child(2) {
    color: ${props => props.theme.mediumGray};
  }
  table {
    margin-bottom: 14px;
  }
`;

export default class extends React.Component {
  render() {
    const value = {...this.props.value};

    // Remove country code and non-digits from phone number.
    if (typeof value.phone === 'string') {
      if (value.phone.startsWith('0031')) {
        value.phone = '0' + value.phone.substring(4);
      }
      value.phone = value.phone.replace(/[^\d]/g, '');
    }

    return (
      <Wrapper>
        <table>
          <tbody>
            <tr>
              <td>Organisatie:</td>
              <td>{value.companyName}</td>
            </tr>
            <tr>
              <td>Functie:</td>
              <td>{value.functionName}</td>
            </tr>
            <tr>
              <td>Aanhef:</td>
              <td>{value.salutation}</td>
            </tr>
            <tr>
              <td>Voorletter(s):</td>
              <td>{value.initials}</td>
            </tr>
            <tr>
              <td>Tussenvoegsel(s):</td>
              <td>{value.infix}</td>
            </tr>
            <tr>
              <td>Achternaam:</td>
              <td>{value.lastName}</td>
            </tr>
            <tr>
              <td>Telefoonnummer:</td>
              <td>{value.phone}</td>
            </tr>
            <tr>
              <td>E-mailadres:</td>
              <td>{value.eventMail}</td>
            </tr>
            <tr>
              <td>E-mailadres facturatie:</td>
              <td>{value.invoicemail}</td>
            </tr>
          </tbody>
        </table>
        <p>
          Uw verzend- en facturatiegegevens kunt u per abonnement terugvinden en wijzigen bij <TransparentLink to="/producten">Mijn producten</TransparentLink>.
        </p>
        <CTACapsuleLink to="/gegevens/wijzigen">Gegevens wijzigen</CTACapsuleLink>
      </Wrapper>
    );
  }
}
