'use strict';

import styled from 'styled-components';
import slugify from '../../lib/slugify';
import moment from '../../lib/moment';
import {Link} from 'react-router-dom';
import {ChevronCapsuleAnchor, CTACapsuleLink} from '../buttons';
import {BlueChevronRight, Cog} from '../svg';

import c from '../../lib/classer';

const Item = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.lightGray};
  }

  &:first-child {
    margin-top: -30px;
  }

  .icon-container {
    flex-basis: 110px;
    flex-grow: 0;
    flex-shrink: 0;

    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;

    > img {
      width: 71px;
      height: 71px;
    }
  }

  .body {
    flex-grow: 1;
    flex-shrink: 1;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: ${props => props.theme.breakpoints.small}px) {
    .body {
      flex-direction: column;
      .buttons {
        text-align: left !important;
        .button {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }

  .body .buttons {
    text-align: right;
    .button {
      margin-bottom: 5px;
      a {
        white-space: nowrap;
      }
    }
  }

  h1, h1 > a {
    font-size: 24px;
    color: ${props => props.theme.almostBlack};
    margin-bottom: 6px;
    font-size: 24px;
    line-height: 28px;
    margin-left: 0;

    svg {
      height: 15px;
      cursor: pointer;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 19px;
    color: ${props => props.theme.mediumDarkGray};
  }

  a {
    color: ${props => props.theme.activeBlue};
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 5px;
    text-decoration: none;
    font-weight: 900;
    flex-shrink: 0;
  }

  button {
    margin-right: 25px;
    align-self: center;
    flex-shrink: 0;
  }

  &.expired {
    h1, p {
      color: ${props => props.theme.expiredRed};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.big}px) {
    flex-direction: column;
    align-items: flex-start;

    .icon-container {
      display: none;
    }

    h1 {
      font-size: 18px;
    }

    p {
      font-size: 13px;
    }

    a {
      margin: 0;
    }

    button {
      margin: 0;
      align-self: flex-start;
    }
  }
`;

const Title = styled.h1`
  position: relative;
  padding-right: 45px;
`;

const SettingsLinkContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  svg {
    fill: #777;
  }
`;

const completeDateFormat = 'D MMMM YYYY';

export default class extends React.Component {
  render() {
    const {
      id,
      active,
      startDate,
      endDate,
      title,
      logo,
      url
    } = this.props;

    const expired = !active || moment(endDate).valueOf() < new Date();
    const editUrl = `/producten/abonnementen/${slugify(title)}/${id}`;

    return (
      <Item key={id} className={c({expired: expired})}>
        {logo && <div className="icon-container"><img src={logo} alt={title}/></div>}
        <div className="body">
          <div className="info">
            <Title>
              <Link to={editUrl}>{title}</Link>
            </Title>
            <p>{ expired ?
              'Verlopen' :
              `startdatum: ${
                moment(startDate).format(completeDateFormat)
              }${
                parseInt(moment(endDate).format('YYYY'), 10) > (new Date()).getFullYear() + 10 ? '' :
              `, einddatum: ${
                moment(endDate).format(completeDateFormat)
              }`}`}
            </p>
          </div>
          <div className="buttons">
            {!expired && url && (
              <div className="button">
                <ChevronCapsuleAnchor href={url} target="_blank">
                  <span style={{color: 'white'}}>Ga naar {url.replace(/^https?...(?:www.)?([^/]+).+/, '$1')}</span>
                </ChevronCapsuleAnchor>
              </div>
            )}
            <div className="button">
              <CTACapsuleLink to={editUrl}>
                <span style={{color: 'white'}}>Abonnement beheren</span>
              </CTACapsuleLink>
            </div>
          </div>
        </div>
        <div>
        </div>
      </Item>
    );
  }
}
