'use strict';

import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import SubscriptionCancel from '../../components/screens/subscription-cancel';
import subscriptionCancelSchema from '../../schema/subscription-cancel';
import {withRouter} from "react-router-dom";
import UserSubscriptionQuery from "../../queries/user-subscription";

@withRouter
@graphql(UserSubscriptionQuery, {
  props: ({data: {UserSubscription, User, loading}, ownProps: {match: {url, params: {id: userSubscriptionId}}}}) => ({
    ...UserSubscription,
    loading,
    userSubscriptionId,
    url,
    user: User
  }),
  options: ({match: {params: {id: userSubscriptionId}}}) => ({variables: {userSubscriptionId}})
})
@graphql(gql`mutation ($data: JSON!) {
  cancelSubscription(data: $data)
}`, {
  name: 'cancelSubscription'
})
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    const {
      cancelSubscription,
      userSubscriptionId,
      endDate
    } = this.props;
    cancelSubscription({
      variables: {
        data: {
          ...data,
          subscriptionId: userSubscriptionId.split('---')[1],
          productId: userSubscriptionId.split('---')[0],
          endDate: (endDate || '').substring(0, 10)
        }
      }
    }).then(() => {
      this.setState({
        submitted: true
      });
    });
  }

  render() {
    if (this.props.loading || !this.props.user) {
      return null;
    }

    const {
      title,
      url,
      user
    } = this.props;

    const value = {
      companyName: user.companyName,
      salutation: user.salutation,
      lastName: user.lastName,
      address: user.address ? `${user.address.street} ${user.address.houseNumber} ${user.address.houseNumberSuffix}`.trim() : '',
      city: user.address ? user.address.city : '',
      phone: user.phone,
      mail: user.eventMail,
      product: title,
      reason: ''
    };

    Object.keys(value).forEach(key => {
      if (value[key]) {
        subscriptionCancelSchema.properties[key].readOnly = true;
        // if (subscriptionCancelSchema.properties[key].type === 'string' && !subscriptionCancelSchema.properties[key].enum) {
        //   subscriptionCancelSchema.required = subscriptionCancelSchema.required.filter(item => item !== key);
        // }
      }
    });

    return <SubscriptionCancel
      submitted={this.state.submitted}
      title={title}
      url={url}
      onSubmit={this.handleSubmit}
      schema={subscriptionCancelSchema}
      value={value}
    />;
  }
}
