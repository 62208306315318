'use strict';

import { NavLink, Route } from 'react-router-dom';

import ContentHeading from '../content-heading';
import TabGroup from '../tab-group';

import MyVisitedCongresses from '../../containers/screens/my-visited-congresses';
import MyUpcomingCongresses from '../../containers/screens/my-upcoming-congresses';
import Placeholder from "../placeholder";

export default class extends React.Component {
  render() {
    return (
    	<div>
    		<ContentHeading title="Mijn congressen">
          <TabGroup>
            <NavLink
              activeClassName="active"
              exact
              to="/congressen"
            >Actueel</NavLink>
            <NavLink
              activeClassName="active"
              to="/congressen/bezocht"
            >Historie</NavLink>
          </TabGroup>
        </ContentHeading>
        <Route
          exact
          path="/congressen"
          component={MyUpcomingCongresses}
        />
        <Route
          exact
          path="/congressen/bezocht"
          component={MyVisitedCongresses}
        />
        <Placeholder position="PH_COP-opl_wide"/>
    	</div>
    );
  }
}
