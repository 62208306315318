export default {
  type: 'object',
  title: 'Adres',
  properties: {
    postalCode: {
      title: 'Postcode',
      type: 'string',
      maxLength: 6,
      minLength: 6,
      mask: '9999aa',
      validation: 'Vul een geldige Nederlandse postcode in'
    },
    houseNumber: {
      title: 'Huisnummer',
      type: 'string',
      maxLength: 10,
      minLength: 1,
      validation: 'Vul uw huisnummer in'
    },
    houseNumberSuffix: {
      title: 'Huisnummer toevoeging',
      type: 'string',
      maxLength: 10
    },
    street: {
      title: 'Adres',
      type: 'string',
      maxLength: 255,
      minLength: 1,
      validation: 'Vul uw straatnaam in'
    },
    city: {
      title: 'Plaats',
      type: 'string',
      maxLength: 255,
      minLength: 1,
      validation: 'Vul uw plaats in'
    }
  },
  required: [
    'postalCode',
    'houseNumber',
    'street',
    'city'
  ],
  additionalProperties: false
};
