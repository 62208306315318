import CongressDateLocations from '../components/congress-date-locations';
import {withRouter} from "react-router-dom";
import {graphql} from "react-apollo";
import gql from "graphql-tag";

@withRouter
@graphql(gql `query($id: ID!) {
  EventScheduleData(id: $id) {
    id
		sessions (limit: 1000) {
      items {
        eventTeacher {
          id
          name
        }
        description
        start
        end
        id
        code
        locationInfo: location
      }
    }
    loggedInParticipant {
      id
      participations (limit: 1000) {
        items {
          id
          eventSession {
            id
          }
        }
      }
    }
  }
}`, {
  props: ({data}) => {
    if (!data.loading && typeof data.EventScheduleData !== 'undefined') {
      const ids = data.EventScheduleData.loggedInParticipant.participations.items.map(item => item.eventSession.id);
      const eventSessions = data.EventScheduleData.sessions.items.filter(item => ids.indexOf(item.code) !== -1);
      return {eventSessions};
    }
  },
  options: ({match: {params: {id}}}) => ({variables: {id}})
})
export default class extends React.Component {
  render() {
    if (!this.props.eventSessions || this.props.eventSessions.length < 1) {
      return null;
    }

    return <CongressDateLocations eventSessions={this.props.eventSessions}/>;
  }
}
