export default class extends React.Component {
  render() {
    return (<svg className={this.props.className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-477.000000, -710.000000)" fill="#000000" fillRule="nonzero">
          <g transform="translate(477.000000, 710.000000)">
            <path d="M10.125,0 L13.875,0 C14.4984375,0 15,0.5015625 15,1.125 L15,9 L19.1109375,9 C19.9453125,9 20.3625,10.0078125 19.771875,10.5984375 L12.6421875,17.7328125 C12.290625,18.084375 11.7140625,18.084375 11.3625,17.7328125 L4.2234375,10.5984375 C3.6328125,10.0078125 4.05,9 4.884375,9 L9,9 L9,1.125 C9,0.5015625 9.5015625,0 10.125,0 Z M24,17.625 L24,22.875 C24,23.4984375 23.4984375,24 22.875,24 L1.125,24 C0.5015625,24 0,23.4984375 0,22.875 L0,17.625 C0,17.0015625 0.5015625,16.5 1.125,16.5 L8.0015625,16.5 L10.2984375,18.796875 C11.240625,19.7390625 12.759375,19.7390625 13.7015625,18.796875 L15.9984375,16.5 L22.875,16.5 C23.4984375,16.5 24,17.0015625 24,17.625 Z M18.1875,21.75 C18.1875,21.234375 17.765625,20.8125 17.25,20.8125 C16.734375,20.8125 16.3125,21.234375 16.3125,21.75 C16.3125,22.265625 16.734375,22.6875 17.25,22.6875 C17.765625,22.6875 18.1875,22.265625 18.1875,21.75 Z M21.1875,21.75 C21.1875,21.234375 20.765625,20.8125 20.25,20.8125 C19.734375,20.8125 19.3125,21.234375 19.3125,21.75 C19.3125,22.265625 19.734375,22.6875 20.25,22.6875 C20.765625,22.6875 21.1875,22.265625 21.1875,21.75 Z"></path>
          </g>
        </g>
    	</g>
		</svg>);
  }
}
