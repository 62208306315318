import styled from 'styled-components';
import NewsLetter from '../list-items/news-letter';

const Container = styled.div`
  > div.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    display: table;

    > h2 {
      color: white;
      text-align: center;
      font-style: italic;
      display: table-cell;
      vertical-align: middle;
    }
  }
`

export default class extends React.Component {
  render() {
    const {newsletters, loading} = this.props;
    return (<Container>
      {newsletters.map(item => <NewsLetter key={item.id} action={this.props.toggleNewsletterSubscription} {...item} />)}
      {loading && <div className="overlay"><h2>Aan het opslaan...</h2></div>}
    </Container>);
  }
}
