import {compose} from '../../lib/functions';
import moment, {ISODATEFORMAT} from '../../lib/moment';

import {withRouter} from "react-router-dom";
import withApolloLoader from '../with-apollo-loader';

import EducationGeneral from '../../components/screens/education-general';

import EducationGeneralQuery from '../../queries/education-general';

export default compose(withRouter, withApolloLoader({
  query: EducationGeneralQuery,
  transform: ({EventScheduleData}) => {
    if (typeof EventScheduleData !== 'undefined') {
      const {
        sessions: {items: sessions},
        eventSchedule: {
          hasPreparation,
          hasEvaluation,
          event: {
            group: {
              type
            }
          }
        },
        loggedInParticipant: {
          finishedPreparation,
          finishedEvaluation,
          paid,
          participations: {items: participations},
          certificateToken
        }
      } = EventScheduleData;

      const dates = sessions.map(item => moment(item.start, ISODATEFORMAT).unix()).sort().reverse();

      return {
        participated: participations.length ? participations.map(item => item.participated).every(item => item) : false,
        eventHasPassed: (dates.length ? dates[0] : '') < moment().unix(),
        certificateToken,
        hasPreparation,
        finishedPreparation,
        hasEvaluation,
        finishedEvaluation,
        paid,
        type
      };
    }
  },
  getVariables: ({match: {params: {id}}}) => ({id})
}))(EducationGeneral);
