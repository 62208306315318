export const weigh = weightMap => {
  const entries = Object.entries(weightMap);
  const total = entries.reduce((total, [,value]) => total + value, 0);

  return entries.reduce((memo, [key, value]) => {
    memo[key] = value / total;

    return memo;
  }, {});
};

export const compose = (...functions) => (...data) => functions.reduceRight((value, func) => func(value), ...data)

export const pipe = (...functions) => (...data) => functions.reduce((value, func) => func(value), ...data)

export const identity = value => value;
