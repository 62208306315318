'use strict';

import JsonEditor, {Raw} from './jsoneditor/index';
import FileEditor from './editors/file';

export default class extends React.PureComponent {
  render() {
    const templates = {
      array: Raw.ArrayEditor,
      arraySortable: Raw.ArraySortable,
      boolean: Raw.BooleanEditor,
      dropdown: Raw.DropdownEditor,
      null: Raw.NullEditor,
      number: Raw.NumberEditor,
      object: Raw.ObjectEditor,
      oneOf: Raw.OneOfEditor,
      radios: Raw.RadiosEditor,
      textarea: Raw.TextareaEditor,
      textfield: Raw.TextfieldEditor,
      groups: Raw.GroupsEditor,
      checkboxes: Raw.CheckboxesEditor,
      description: Raw.Description,
      error: Raw.Error,
      formElement: Raw.FormElement,
      static: Raw.StaticEditor,
      file: FileEditor
    };

    return (<JsonEditor
      templates={templates}
      {...this.props}
    />);
  }
}
