'use strict';

import styled from 'styled-components';

import { TransparentButton } from './buttons';

const ShowMoreButton = TransparentButton.extend`
  color: ${ props => props.theme.rendementBlue };
  margin-left: 75px;
  padding: 0;

  &:hover {
    color: ${ props => props.theme.actionOrange }
  }

  &:hover svg g {
    fill: ${ props => props.theme.actionOrange }
  }
`;

const Container = styled.span`
	display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10px;

  svg {
    margin-left: 10px;
  }
`;

export default class extends React.Component {
  render() {
    const { onClick, children } = this.props;

    return (
      <ShowMoreButton onClick={onClick}>↓ { children }</ShowMoreButton>
    );
  }
}
