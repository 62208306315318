'use strict';

export default class extends React.Component {
  render() {
    return (<this.props.templates.formElement {...{...this.props, errors: false}}>
      <ul>
        {this.props.items.map(({element, handleDelete}, index) => (<li key={index}>
          <a href="#" aria-label="Delete" onClick={e => {
            e.preventDefault();
            return handleDelete(e);
          }}>
            Remove
          </a>
          {element}
        </li>))}
        <a href="#" onClick={e => {
          e.preventDefault();
          return this.props.onClickAdd(e);
        }}>Add item</a>
      </ul>
    </this.props.templates.formElement>);
  }
}
