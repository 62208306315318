'use strict';

import OneOf from './one-of';

export default class extends React.Component {
  render() {
    const schema = {
      allowChange: true,
      oneOf: [{
        title: 'String',
        type: 'string'
      }, {
        title: 'Number',
        type: 'number'
      }, {
        title: 'Boolean',
        type: 'boolean'
      }, {
        title: 'Null',
        type: 'null'
      }, {
        title: 'Array',
        type: 'array'
      }, {
        title: 'Object',
        type: 'object'
      }]
    };
    return <OneOf {...this.props} schema={schema}/>;
  }
}
