import gql from 'graphql-tag';

import withApolloLoader from '../with-apollo-loader';

import EducationDetails from '../../components/screens/education-details';
import ContentHeading from '../../components/content-heading';

export default withApolloLoader({
  query: gql`query ($id: ID!) {
    EventScheduleData(id: $id) {
      id
      remarks
      title
      description
      eventLocation {
        id
        name
        street
        houseNumber
        houseNumberSupplement
        city
        postalCode
        routeUrl
      }
      sessions {
        items {
          id
          eventTeacher {
            id
            name
          }
          start
          end
          location
        }
      }
      eventSchedule {
        id
        description
        hasEvaluation
        hasPreparation
        event {
          id
          name
          group {
            id
            type
          }
        }
      }
      loggedInParticipant {
        id
        paid
        finishedEvaluation
        finishedPreparation
        certificateToken
        participations (limit: 1000) {
          items {
            id
            participated
          }
        }
      }
    }
  }`,
  transform: data => {
    if (typeof data.EventScheduleData !== 'undefined') {
      return { title: data.EventScheduleData.title };
    }
  },
  getVariables: ({ match: { params: { id } } }) => ({ id }),
  getChildProps: ({ match: { params: { id } } }) => ({ id }),
  LoaderComponent: () => <ContentHeading title="Uw opleiding wordt geladen..." />
})(EducationDetails);
