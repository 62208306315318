'use strict';

export default class extends React.Component {
  render() {
    const items = this.props.properties.map((d) => {
      const {name, label, included, optional, element, handleClick} = d;
      return (<div className="form-object-element" key={name}>
        {optional && (<label>
          <input
            className="form-new-property"
            type="checkbox"
            checked={included}
            onChange={() => {
              handleClick();
            }}
          />
          {label ? label : name}
        </label>)}
        {element}
      </div>);
    });

    if (this.props.allowAddProperty) {
      const handleChange = ({target}) => this.props.onChangeNewPropertyName(target.value);
      items.push(<div key="">
        <input
          className="form-new-property"
          type="text"
          value={this.props.newPropertyName}
          maxLength="255"
          onChange={handleChange}
        />
        <button className="add-more" onClick={e => {
          e.preventDefault();
          return this.props.onClickAddProperty(e);
        }}>Add</button>
      </div>);
    }

    return (<this.props.templates.formElement {...{...this.props, errors: false, template: this.props.template || 'object'}}>
      <fieldset>
        {this.props.schema.intro || null}
        {items}
      </fieldset>
    </this.props.templates.formElement>);
  }
}
