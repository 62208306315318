/**
 * feed this function a hashMap, every key sets the className to add,
 * the value or return value if value is a function toggles if the class gets added
 */

export default function classer( hashMap, ...parameters ) {
  return Object.entries( hashMap ).reduce( ( memo, [ className, value ] ) => {
    if( typeof value === 'function' ? value( ...parameters ) : value ) memo.push( className );
    return memo;
  }, [] ).join( ' ' );
}
