'use strict';

import styled from 'styled-components';
import { Mutation, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { Upload } from '../../containers/upload';
import DownloadIcon from '../svg/download';
import CrossIcon from '../svg/cross';
import SearchIcon from '../svg/search';
import Dialog from '../dialog';
import Block from '../block';
import { TransparentAnchor, ChevronCapsuleButton, WarningCapsuleButton, CapsuleButton } from "../../components/buttons";

const Wrapper = styled.div`
  table {
    width: 100%;
  }

  thead {
    font-weight: bold;
    tr td {
      border-bottom: 1px solid ${props => props.theme.darkGray};
    }
  }

  th {
    text-align: left;
  }
  td, th {
    text-align: center;
  }
  td:first-child, th:first-child {
    text-align: left;
  }
  td:nth-child(2), th:nth-child(2) {
    text-align: right;
  }
  svg {
    width: 24px;
    height: 24px;
  }

  tr {
    width: 100%;

    td {
      padding: 5px;
      border-bottom: 1px solid ${props => props.theme.lightGray};
    }
  }
  tr.button-row td {
    border: none;
  }

  a {
    cursor: pointer;
  }
`;

const InnerWrapperFiles = styled.div`
  text-align: center;
  margin-top:20px
  h3 {
    margin-top: 0;
    margin-bottom: 0px;

  }
  p {
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .upload-drop {
    span {
      display: block;
      margin-top: 15px;
    }
  }
`;

const DialogWrapper = styled.div`
  h1 {
    color: ${props => props.theme.activeBlue};
  }
  p {
    color: ${props => props.theme.darkGray};
  }
`;

@withApollo
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDelete: null,
      showConfirm: false
    };
  }

  render() {
    const {
      session,
      code,
      refetch,
      type,
      multiple,
      title
    } = this.props;

    const status = session.status.items[0];
    const submitted = status && status.material;

    const documents = session.documents.items.filter(item => {
      return String(item.type) === String(type);
    });

    return (
      <Mutation mutation={gql`mutation ($session: String!) {
        submitMaterial(session: $session)
      }`}>
        {(submitMaterial, { }) => (
          <Mutation mutation={gql`mutation DeleteDocument ($session: String!, $code: String!) {
            deleteTeacherDocument(session: $session, code: $code)
          }`}>
            {(deleteDocument, { }) => (
              <Wrapper>
                <Block title={title}>
                  {documents.length === 0 && (
                    <p>Er zijn nog geen documenten voor deze sessie.</p>
                  )}
                  {documents.length > 0 && (
                    <table>
                      <thead>
                        <tr>
                          <th>Naam</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {documents.sort((a, b) => {
                          return String(a.name).toLowerCase().localeCompare(String(b.name).toLowerCase());
                        }).map(doc => (
                          <tr key={doc.id}>
                            <td>
                              {doc.name}
                            </td>
                            <td>
                              {!submitted && (
                                <TransparentAnchor style={{ marginRight: '15px', color: 'red', position: 'relative' }} onClick={() => {
                                  this.setState({
                                    showDelete: doc
                                  })
                                }}>
                                  <svg style={{ position: 'absolute', right: 0, bottom: '-2px' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                  </svg>
                                </TransparentAnchor>
                              )}
                              <ChevronCapsuleButton style={{ marginRight: '15px' }} to={`/api/media/download/${doc.url}/${doc.name}`}>
                                Download
                              </ChevronCapsuleButton>
                              <ChevronCapsuleButton to={`/docent/sessie/${code}/materiaal/${doc.id}`}>
                                Bekijken
                              </ChevronCapsuleButton>


                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {!submitted && (documents.length === 0 || multiple) && (
                    <InnerWrapperFiles className={"form-files"}>
                      {multiple && (
                        <React.Fragment>
                          <h3>Bestanden toevoegen</h3>
                          <p>Sleep bestanden naar onderstaand vlak of klik om bestanden te uploaden.</p>
                        </React.Fragment>
                      )}
                      {!multiple && (
                        <React.Fragment>
                          <h3>Bestand toevoegen</h3>
                          <p>Sleep een bestand naar onderstaand vlak of klik om te uploaden.</p>
                        </React.Fragment>
                      )}
                      <Upload multiple={multiple} client={this.props.client} session={session.id} type={type} refetch={refetch} />
                    </InnerWrapperFiles>
                  )}
                </Block>

                {!submitted && multiple && (
                  <Block title={`${title} bevestigen`}>
                    <p>
                      Na het uploaden van het materiaal dient u dit te bevestigen.
                      Na het bevestigen kunt u geen bestanden meer toevoegen of verwijderen.
                    </p>
                    <CapsuleButton onClick={() => {
                      this.setState({ showConfirm: true });
                    }}>Bevestigen</CapsuleButton>
                  </Block>
                )}

                {this.state.showDelete && (
                  <DialogWrapper>
                    <Dialog
                      title="Document verwijderen"
                      body={`Weet u zeker dat u ${this.state.showDelete.name} wilt verwijderen?`}
                      buttons={(
                        <React.Fragment>
                          {!submitted && (
                            <WarningCapsuleButton onClick={() => {
                              deleteDocument({
                                variables: {
                                  session: code,
                                  code: this.state.showDelete.url
                                }
                              }).then(() => {
                                refetch();
                              });
                              this.setState({ showDelete: null });
                            }}>
                              Verwijderen
                            </WarningCapsuleButton>
                          )}
                          <TransparentAnchor onClick={() => {
                            this.setState({ showDelete: null });
                          }}>
                            Annuleren
                          </TransparentAnchor>
                        </React.Fragment>
                      )}
                      onClose={() => {
                        this.setState({ showDelete: null });
                      }}
                    />
                  </DialogWrapper>
                )}

                {this.state.showConfirm && (
                  <DialogWrapper>
                    <Dialog
                      title="Materiaal bevestigen"
                      body={`Weet u zeker dat u het materiaal wilt bevestigen? U kunt hierna geen bestanden meer toevoegen of verwijderen.`}
                      buttons={(
                        <React.Fragment>
                          {!submitted && (
                            <CapsuleButton onClick={() => {
                              submitMaterial({
                                variables: {
                                  session: code
                                }
                              }).then(() => {
                                refetch();
                              });
                              this.setState({ showConfirm: false });
                            }}>
                              Bevestigen
                            </CapsuleButton>
                          )}
                          <TransparentAnchor onClick={() => {
                            this.setState({ showConfirm: false });
                          }}>
                            Annuleren
                          </TransparentAnchor>
                        </React.Fragment>
                      )}
                      onClose={() => {
                        this.setState({ showConfirm: false });
                      }}
                    />
                  </DialogWrapper>
                )}

              </Wrapper>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}
