import EventPreparationTeaser from '../components/event-preparation-teaser';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import {withRouter} from 'react-router-dom';
import { from } from 'zen-observable';

@withRouter
@graphql(gql `query($id: ID!) {
  EventScheduleData(id: $id) {
    id
    forms {
      items {
        id
        type
        remarks
        formIO
      }
    }
  }
}`, {
  props: ({data}) => {
    if (!data.loading && typeof data.EventScheduleData !== 'undefined') {
      const form = data.EventScheduleData.forms.items.filter(item => item.type === 'preparation_assignment');
      if (form.length) {
        return {
          preparation: {
            description: form[0].remarks ? form[0].remarks : "",
            id: data.EventScheduleData.id
          }
        }
      }
    }
  },
  options: ({match: {params: {id}}}) => ({variables: {id}})
})
export default class extends React.Component {
  render() {
    if (!this.props.preparation) {
      return null;
    }

    return (<EventPreparationTeaser
      description={this.props.preparation.description}
      uri={`/opleiding/${this.props.preparation.id}/voorbereidingsformulier`}
    />);
  }
}