import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import moment, { ISODATEFORMAT } from 'moment';
import { withRouter } from 'react-router-dom'

import CongressInfo from '../components/congress-info';

import { identity } from '../lib/functions';

import getStartAndEndTime from '../lib/start-end-times';

@withRouter
@graphql(gql`query($id: ID!) {
  EventScheduleData(id: $id) {
    id
    eventLocation {
      id
      routeUrl
    }
    sessions {
      items {
        id
        start
        end
        location
      }
    }
    location: eventLocation {
      id
      name
      street
      houseNumber
      houseNumberSupplement
      city
      postalCode
    }
  }
}`, {
  props: ({ data: {
    loading,
    EventScheduleData
  } }) => {
    if (loading) {
      return { loading: true };
    }

    if (typeof EventScheduleData !== 'undefined') {
      const {
        eventLocation: { routeUrl },
        sessions: { items: eventSessions = [] },
        location: {
          street,
          houseNumber,
          houseNumberSupplement,
          name: locationInfo,
          postalCode,
          city
        }
      } = EventScheduleData;

      const sessionData = eventSessions.map(item => {
        return {
          ...item,
          start: moment(item.start, ISODATEFORMAT).unix(),
          end: moment(item.end || item.start, ISODATEFORMAT).unix()
        }
      })

      let currentSession = sessionData.filter(item => moment().unix() < item.end).sort((a, b) => a.end - b.end).shift();
      if (!currentSession) {
        currentSession = sessionData.sort((a, b) => a.end - b.end).shift();
      }

      const dates = getStartAndEndTime(eventSessions, unix => unix ? unix : false)

      return {
        loading: false,
        title: 'Datum en locatie',
        address: [street, houseNumber, houseNumberSupplement].filter(identity).join(' '),
        locationInfo,
        postalCode,
        city,
        routeUrl,
        start: (((!typeof dates.start !== undefined) ? dates.start : false)),
        end: ((!typeof dates.end !== undefined) ? dates.end : false),
        extraLocationInfo: ((!typeof currentSession !== undefined) ? currentSession.location : false)
      };
    }

    return { error: true };
  },
  options: ({ match: { params: { id } } }) => ({ variables: { id } })
})
export default class extends React.Component {
  render() {
    const { loading, error, ...props } = this.props;

    if (loading || error) {
      return null;
    }

    return <CongressInfo {...props} />
  }
}
