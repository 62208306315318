export default class extends React.Component {
  render() {
    return (<svg width="15px" height="14px" viewBox="0 0 15 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g transform="translate(-670.000000, -767.000000)" fill="#9B9B9B" fillRule="nonzero">
        <g transform="translate(448.000000, 341.000000)">
          <g transform="translate(222.500000, 424.000000)">
            <path d="M7,2 C3.13402419,2 0,5.13399597 0,9 C0,12.866004 3.13402419,16 7,16 C10.8659758,16 14,12.8659758 14,9 C14,5.13402419 10.866004,2 7,2 Z M10.6724032,5.32759677 C12.5197258,7.17491935 12.6482097,9.99843145 11.2560282,11.9786411 L4.02133065,4.744 C6.00289516,3.3508871 8.82609677,3.48131855 10.6724032,5.32759677 Z M3.32759677,12.6724032 C1.48027419,10.8250806 1.35179032,8.00156855 2.74397177,6.02135887 L9.97866935,13.256 C7.99713306,14.6491129 5.17390323,14.5187097 3.32759677,12.6724032 Z" id="Shape"></path>
        </g>
      </g>
    </g>
  </svg>);
  }
}
