import EventInfo from '../components/event-info';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import {withRouter} from 'react-router-dom';

@withRouter
@graphql(gql `query($id: ID!) {
  EventScheduleData(id: $id) {
    id
    remarks
    loggedInParticipant {
      id
      paid
      finishedEvaluation
      finishedPreparation
      participations (limit: 1000) {
        items {
          id
          participated
        }
      }
    }
    eventSchedule {
      id
      hasEvaluation
      hasPreparation
      event {
        id
        group {
          id
          type
        }
      }
    }
  }
}`, {
  props: ({
    data: {loading, EventScheduleData}
  }) => {
    if (loading) {
      return {loading};
    }

    if (!EventScheduleData) {
      return {error: 'no Event schedule data found'};
    }

    const {
      loggedInParticipant: {
        participations: {items: participations = []},
        paid,
        finishedEvaluation,
        finishedPreparation
      },
      eventSchedule: {
        event: {group: {type: eventType}},
        hasEvaluation
      },
      hasPreparation,
      eventSchedule,
      remarks: description
    } = EventScheduleData;

    return {
      participated: participations.some(({participated}) => participated),
      paid,
      finishedEvaluation,
      finishedPreparation,
      hasEvaluation,
      hasPreparation: eventType !== 'congress' ? hasPreparation : false,
      description,
      type: eventType
    };
  },
  options: ({match: {params: {id}}}) => ({variables: {id}})
})
export default class extends React.Component {
  render() {
    const {loading, error, ...props} = this.props;

    if (loading || error) {
      return null;
    }

    return <EventInfo {...props}/>;
  }
}
