import gql from "graphql-tag";

export default gql`query {
  User: currentUser {
    id
    isTeacher
    events {
      items {
        id
        eventScheduleData {
          id
          title
          description
          eventLocation {
            id
            name
          }
          sessions {
            items {
              id
              start
              end
            }
          }
          eventSchedule {
            id
            description
            event {
              id
              name
              group {
                id
                type
              }
            }
          }
        }
      }
    }
  }
}`
