'use strict';

import styled from 'styled-components';
import {ChevronDown} from '../svg/index';

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  &:link, &:visited {
    text-decoration: none;
  }

  :not(:last-child) {
    margin: 40px 0;
  }

  strong {
    font-family: Karla, sans-serif;
    color: black;
  }

  div.icon-container {
    display: flex;
    flex-basis: 50px;
    flex-shrink: 0;
    justify-content: space-around;
    align-items: center;
    margin-right: 25px;

    div.circle {
      width: 50px;
      height: 50px;
      background-color: white;
      border: 3px solid ${props => props.theme.actionOrange};
      border-radius: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;

      svg {
        [fill]:not([fill=none]) { fill: ${props => props.theme.actionOrange}; }
      }
    }
  }

  div.content {
    flex-grow: 1;

    h1 {
      font-size: 18px;
      margin-top: 5px;
      margin-bottom: 5px;

      .title-body {
        color: ${props => props.theme.actionBlue};
        max-width: 610px;
      }

      span.moment {
        color: ${props => props.theme.almostBlack};
        float: right;
        font-size: 16px;
        font-weight: 400;

        @media (max-width: ${props => props.theme.breakpoints.big}px) {
          float: none;
          display: block;
          margin-top: 5px;
        }
      }
    }

    p {
      margin-top: 0;
      color: ${props => props.theme.mediumGray};
      margin-bottom: 0;
    }
  }
`;

export default class extends React.Component {
  render() {
    const {text} = this.props;
    return (
      <Item>
        <div className='icon-container'>
          <div className='circle'>
            <ChevronDown/>
          </div>
        </div>
        <div className='content'>
          <h1>
            <span className='title-body'>
              {'Laden...'}
            </span>
          </h1>
          <p>{text}</p>
        </div>
      </Item>
    );
  }
}
