'use strict';

import gql from 'graphql-tag';
import { graphql, withApollo } from 'react-apollo';
import MyInterestsUpdate from '../../components/screens/my-interests-update';

const query = gql`query {
  User: currentUser {
    id
    themes {
      items {
        id
        theme {
          id
        }
      }
    }
  }

  disciplines: listDiscipline(order: {
    field: "title"
  }) {
    items {
      id
      title
      themes {
        items {
          id
          title
        }
      }
    }
  }
}`;

@withApollo
@graphql(query, {
  props: ({data: {User, disciplines}}) => ({...User, disciplines})
})
export default class extends React.Component {
  mutateThemes( { add, remove } ) {
    if( !( add.length + remove.length ) ) return;

    const {props: {
      client,
      id
    }} = this;

    client.mutate({
      mutation: gql`mutation ${ add.length ? '( $id: ID! )' : '' } {
        ${ [
          ...add.map( ( themeId, index ) => `
            addUserTheme${ index }: createUserTheme( input: {
              user: $id,
              theme: "${ themeId }"
            } ){ id }
          ` ),
          ...remove.map( ( userThemeObjectId, index ) => `
            removeUserTheme${ index }: deleteUserTheme( id: "${ userThemeObjectId }" ){ id }
          ` )
        ].join( '\n\n' ) }
      }`,
      variables: {id},
      refetchQueries: [{ query }]
    });
  }
  render() {
    if (!this.props.disciplines || !this.props.themes) {
      return null;
    }

    const { themes: { items: themes }, disciplines: { items: disciplines } } = this.props;

    const viewDisciplines = disciplines.map( discipline => {
      const viewThemes = discipline.themes.items.map( theme => {
        const userThemeObject = themes.find( ( { theme: { id } } ) => theme.id === id );

        return {
          ...theme,
          userThemeObjectId: userThemeObject && userThemeObject.id,
          enabled: Boolean( userThemeObject )
        };
      } );

      return {
        ...discipline,
        themes: viewThemes,
        enabled: Boolean( viewThemes.filter( filter => filter.enabled ).length )
      };
    } ).filter( discipline => discipline.themes.length > 0 );

    return (
      <MyInterestsUpdate
        disciplines={viewDisciplines}
        save={this.mutateThemes.bind( this )}
      />
    );
  }
}
