'use strict';

import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import MyActivities from '../components/screens/my-activities';
import settings from '../settings';

const paginationLimit = settings.pagination.activities;

@graphql(gql`query ($limit: Int!, $offset: Int!, $categories: [String]) {
  User: currentUser {
    id
    activities (
      limit: $limit,
      offset: $offset,
      filters: {
        category_in: $categories
      }
    ) {
      count
      items {
        id
        title
        url
        created
        category
        type
      }
    }
  }
}`, {
  props: ({data: {loading, User, fetchMore}}) => ({
    hasMore: User ? User.activities.items.length < User.activities.count : false,
    User,
    loading,
    loadMore() {
      return fetchMore({
        variables: {offset: User ? User.activities.items.length : 0},
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult || !fetchMoreResult.User) {
            return prev;
          }

          return {
            ...prev,
            hasMore: fetchMoreResult.User.activities.items.length < fetchMoreResult.User.activities.count,
            User: {
              ...prev.User,
              activities: {
                ...prev.User.activities,
                items: [ ...prev.User.activities.items, ...fetchMoreResult.User.activities.items ]
              }
            }
          };
        }
      });
    }
  }),
  options: ({filters}) => ({
    variables: {
      limit: paginationLimit,
      offset: 0,
      categories: Object.keys(filters).filter(item => Boolean(filters[item])).map(item => String(settings.filterMapping[item]))
    },
    notifyOnNetworkStatusChange: true
  })
})
export default class extends React.Component {
  render() {
    const {
      loadMore,
      hasMore,
      loading,
      filters,
      changeFilter,
      clearFilters
    } = this.props;

    const activities = this.props.User ? this.props.User.activities.items : [];

    return <MyActivities
      activities={activities.filter( activity => activity.category ? filters[ activity.category ] : true ).map( activity => ( { ...activity } ) )}
      loadMore={loadMore}
      hasMore={hasMore}
      loading={loading}
      filters={filters}
      changeFilter={changeFilter}
      clearFilters={clearFilters}
    />
  }
}
