'use strict';

import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {ApolloProvider} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import {HttpLink} from 'apollo-link-http';
import {onError} from 'apollo-link-error';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ThemeProvider} from 'styled-components';
import theme from './theme';
import Site from './routes';
import 'unfetch/polyfill';

class App extends React.Component {
  checkAccessToken() {
    const match = document.location.hash.match(/token=([^&]+).+expires_in=([\d]+)/);
    if (match) {
      const token = decodeURIComponent(match[1]);
      const expiresIn = parseInt(match[2], 10);
      try {
        localStorage.setItem('accessToken', token);
        localStorage.setItem('accessTokenExpires', ~~(new Date() / 1e3) + expiresIn);
        // Chrome bug.
        // @see https://stackoverflow.com/questions/13292744/why-isnt-localstorage-persisting-in-chrome
        localStorage.getItem('accessToken');
        localStorage.getItem('accessTokenExpires');
      } catch (err) {
        // Errors can occur due to browser settings.
      }
      // Remove token from URL.
      if (window.history) {
        window.history.replaceState(null, null, document.location.pathname);
      } else {
        document.location = document.location.pathname;
      }
      return token;
    }
  }

  render() {
    const basename = '/';

    const newAccessToken = this.checkAccessToken();
    const storedAccessToken = typeof localStorage !== 'undefined' ? localStorage.accessToken : null;
    const accessToken = newAccessToken || storedAccessToken;

    const headers = {};
    let loggedIn = false;
    if (accessToken) {
      const expire = typeof localStorage !== 'undefined' ? localStorage.accessTokenExpires : null;
      if (!expire || expire > ~~(new Date() / 1e3) + 600) {
        headers['Authorization'] = 'Bearer ' + localStorage.accessToken;
        loggedIn = true;
      }
    }

    if (!loggedIn) {
      document.location = '/login';
      return <div/>;
    }

    const logoutLink = onError(({networkError, graphQLErrors}) => {
      let error = false;
      if (networkError && networkError.statusCode) {
        error = [401, 403].indexOf(networkError.statusCode) >= 0;
      }
      if (Array.isArray(graphQLErrors) && graphQLErrors.length) {
        const message = graphQLErrors[0].message;
        error = ['Forbidden', 'Unauthorized'].indexOf(message) >= 0;
      }
      if (error) {
        console.log(graphQLErrors);return;
        document.location = '/logout';
      }
    });

    const httpLink = new HttpLink({
      uri: '/graphql',
      credentials: 'same-origin',
      headers
    });

    const client = new ApolloClient({
      link: logoutLink.concat(httpLink),
      cache: new InMemoryCache(),
      ssrMode: false
    });

    return (<ApolloProvider client={client}>
      <BrowserRouter basename={basename}>
        <ThemeProvider theme={theme}>
          <Site/>
        </ThemeProvider>
      </BrowserRouter>
    </ApolloProvider>);
  }
}

ReactDOM.render(<App/>, document.getElementById('app-site'));
