'use strict';

import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import {CapsuleButton, TransparentButton} from '../buttons';
import ContentHeading from '../content-heading';

const Wrapper = styled.div`
  @keyframes containerShrink {
    0% {
      flex-basis: 750px;
    }
    100% {
      flex-basis: 250px;
      cursor: pointer;
      background-color: ${props => props.theme.backgroundGray};
    }
  }

  @keyframes contentFadeOut {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }

  @keyframes containerGrow {
    0% {
      flex-basis: 250px;
      background-color: ${props => props.theme.backgroundGray};
    }

    100% {
      flex-basis: 750px;
      background-color: white;
    }
  }

  @keyframes contentFadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .left, .right {
    flex-shrink: 0;
    background-color: white;
  }

  .left {
    flex-basis: 750px;
    display: flex;
    flex-direction: column;

    h2 {
      padding-left: 20px;
    }
  }

  .right {
    cursor: pointer;
    flex-basis: 250px;
    background-color: ${props => props.theme.backgroundGray};

    h2 {
      color: ${props => props.theme.mediumDarkGray};
      padding-left: 20px;
    }

    *:not( h2 ) {
      opacity: 0;
    }
  }

  &.step-2 {
    .left {
      animation containerShrink 750ms both;
      animation-delay: 300ms;

      h2 {
        color: ${props => props.theme.mediumDarkGray};
      }

      *:not( h2 ) {
        animation contentFadeOut 500ms both;
      }
    }

    .right {
      animation containerGrow 750ms both;
      animation-delay: 300ms;

      display: flex;
      flex-direction: column;

      *:not( h2 ) {
        animation contentFadeIn 500ms both;
        animation-delay: 1s;
      }

      h2 {
        color: black;
      }
    }
  }

  &.returned.step-1 {
    .left {
      animation containerGrow 750ms both;
      animation-delay: 300ms;

      *:not( h2 ) {
        opacity: 0;
        animation contentFadeIn 500ms both;
        animation-delay: 1s;
      }
    }

    .right {
      flex-basis: 750px;
      background-color: white;
      animation containerShrink 750ms both;
      animation-delay: 300ms;

      h2 {
        color: ${props => props.theme.mediumDarkGray};
      }

      *:not( h2 ) {
        animation contentFadeOut 500ms both;
      }
    }
  }

  .disciplines {
    flex-grow: 1;
    position: relative;
    max-width: 50vw;

    div {
      display: inline-block;
      width: calc( 100% / 2 - 50px);
      box-sizing: border-box;
      margin:10px 0 30px 2%;
      flex-grow: 1;
      width: calc(100% * /2 - 10px - 1px);

      label {
        cursor: pointer;
      }

      input[type=checkbox] {
        margin-left: 20px;
        margin-right: 1em;
      }
    }
  }

  .theme-groups-container {
    flex-grow: 1;
    position: relative;
    overflow-y: scroll;
  }

  .theme-groups h3 {
    padding-left: 20px;
  }

  .themes {
    position: relative;
    max-width: 50vw;

    label {
      width: 50%;
      margin-bottom: 10px;
      padding-left: 20px;
      display: inline-block;
      box-sizing: border-box;
      cursor: pointer;

      input[type=checkbox] {
        margin-right: 1em;
      }
    }
  }

  .footer {
    border-top: 1px solid ${props => props.theme.lightGray};
    flex-shrink: 0;
    flex-basis: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .counter {
      text-transform: uppercase;
    }
  }
`;

@withRouter
export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      disciplines: props.disciplines.slice().map(discipline => ({
        ...discipline,
        themes: discipline.themes.map(theme => ({...theme}))
      }))
    };
  }
  close({save} = {}) {
    if (save) {
      this.props.save(this.serializeChanges());
    }

    this.props.history.push('/interesses');
  }
  toggleStep() {
    if( this.state.step === 1 ) {
      this.setState( {
        step: 2
      } );
    } else {
      this.setState( {
        step: 1,
        returned: true
      } )
    }
  }
  toggleDiscipline(id) {
    const {disciplines} = this.state;

    this.setState({
      disciplines: disciplines.slice().map(item => (item.id !== id ? item : {...item, enabled: !item.enabled}))
    });
  }
  toggleTheme(disciplineId, themeId) {
    const {disciplines} = this.state;

    this.setState({
      disciplines: disciplines.slice().map(item => (item.id !== disciplineId ? item : {
        ...item,
        themes: item.themes.map(item => (item.id !== themeId ? item : {
          ...item,
          enabled: !item.enabled
        }))
      }))
    });
  }
  serializeChanges() {
    const {state: {disciplines}, props: {disciplines: originalDisciplines}} = this;

    return disciplines.reduce((memo, {id, enabled, themes}) => {
      const {themes: originalThemes} = originalDisciplines.find(discipline => discipline.id === id);

      if (enabled) {
        // Filter to only get changed themes
        const {add, remove} = themes.filter(
          ({enabled, id}) => enabled !== originalThemes.find(({id: themeId}) => id === themeId).enabled
        ).reduce((memo, {enabled, id, userThemeObjectId}) => {
          // Enabled or disabled needs to be saved either way
          (enabled ? memo.add : memo.remove).push(enabled ? id : userThemeObjectId);

          return memo;
        }, {add: [], remove: []});

        memo.add.push(...add);
        memo.remove.push(...remove);
      } else {
        // Just remove all selected themes when the whole discipline is disabled
        memo.remove.push(...originalThemes.filter(theme => theme.enabled).map(({userThemeObjectId}) => userThemeObjectId));
      }

      return memo;
    }, {add: [], remove: []});
  }
  render() {
    const {step, disciplines, returned} = this.state;

    return (
      <Wrapper className={`step-${step} ${returned ? 'returned' : ''}`}>
        <ContentHeading title="Mijn interesses"/>
        <div>
          {step === 1 && (
            <div>
              <h2>Stap 1{ step === 1 ? <span>: Kies uw vakgebieden</span> : null }</h2>
              <div className="disciplines">
                { disciplines.map(({title, id, enabled}) => (
                  <div>
                    <label key={id}>
                      <input type="checkbox" checked={enabled} onChange={this.toggleDiscipline.bind(this, id)}/>{ title }
                    </label>
                  </div>
                )) }
              </div>
              <div className="footer">
                <span className="counter">{ disciplines.filter(({enabled}) => enabled).length } geselecteerd</span>
                <TransparentButton onClick={this.toggleStep.bind(this)}>Volgende →</TransparentButton>
              </div>
            </div>
          )}
          {step === 2 && (
            <div>
              <h2>Stap 2{ step === 2 ? <span>: {'Kies interessante thema\'s'}</span> : null }</h2>
              <div className="theme-groups-container">
                <div className="theme-groups">
                  { disciplines.filter(({enabled}) => enabled).map(({title, id: disciplineId, themes}) => (<div>
                    <h3>{ title }</h3>
                    <div className="themes">
                      { themes.map(({title, id: themeId, enabled}) => (<label>
                        <input type="checkbox" checked={enabled} onChange={this.toggleTheme.bind(this, disciplineId, themeId)}/> { title }
                      </label>)) }
                    </div>
                  </div>)) }
                </div>
              </div>
              <div className="footer">
                <TransparentButton onClick={this.toggleStep.bind(this)}>← Vorige</TransparentButton>
                <CapsuleButton onClick={this.close.bind(this, {save: true})}>Interesses opslaan</CapsuleButton>
              </div>
            </div>
          )}
        </div>
      </Wrapper>
    );
  }
}
