import styled from 'styled-components';
import Check from "./svg/check";
import Cross from "./svg/cross";

const List = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    list-style-type: none;
    flex-basis: 100%;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    color: ${props => props.theme.warningRed};
    
    > svg {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 20px;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      
      > path {
        color: ${props => props.theme.warningRed};
      }
    }
    
    &.done {
      color: ${props => props.theme.successGreen};
      
      > svg > path {
        fill: ${ props => props.theme.successGreen };
      }
    }
  }
`;

export default class extends React.Component {
  render() {
    return (<List>
      {this.props.steps.map(item => {
        const {
          label,
          done
        } = item;

        return (<li key={label} className={done ? 'done' : ''}>
          {done ? <Check/> : <Cross/>}
          <span>{label}</span>
        </li>);
      })}
    </List>);
  }
}