import styled from 'styled-components';

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-right: 40px;
  
  > * {
    flex-basis: 48%;
    margin-right: 2%;
    
    &:nth-child(even) {
      margin-left: 2%;
      margin-right: 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.big}px) {
    padding-right: 0;
    margin-right: 0 !important;

    > * {
      flex-basis: 100%;
      margin-right: 0;
      margin-left: 0;
      
      &:nth-child(even) {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
`;

export default class extends React.Component {
  render() {
    return (<Page>
      {this.props.children}
    </Page>);
  }
}