export default class extends React.Component {
  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" version="1.1">
        <path style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: 'rgb(0%,0%,0%)',
          fillOpacity: 1
         }} d="M 16.285156 11.144531 C 16.285156 9.492188 15.699219 8.078125 14.523438 6.902344 C 13.351562 5.730469 11.9375 5.144531 10.285156 5.144531 C 8.632812 5.144531 7.222656 5.730469 6.046875 6.902344 C 4.871094 8.078125 4.285156 9.492188 4.285156 11.144531 C 4.285156 12.792969 4.871094 14.207031 6.046875 15.382812 C 7.222656 16.554688 8.632812 17.144531 10.285156 17.144531 C 11.9375 17.144531 13.351562 16.554688 14.523438 15.382812 C 15.699219 14.207031 16.285156 12.792969 16.285156 11.144531 Z M 23.144531 22.285156 C 23.144531 22.75 22.972656 23.152344 22.632812 23.492188 C 22.292969 23.832031 21.894531 24 21.429688 24 C 20.945312 24 20.542969 23.832031 20.222656 23.492188 L 15.628906 18.910156 C 14.03125 20.019531 12.25 20.570312 10.285156 20.570312 C 9.007812 20.570312 7.789062 20.324219 6.621094 19.828125 C 5.457031 19.332031 4.453125 18.664062 3.609375 17.820312 C 2.765625 16.976562 2.097656 15.972656 1.601562 14.804688 C 1.105469 13.640625 0.855469 12.417969 0.855469 11.144531 C 0.855469 9.867188 1.105469 8.644531 1.601562 7.480469 C 2.097656 6.316406 2.765625 5.308594 3.609375 4.464844 C 4.453125 3.621094 5.457031 2.953125 6.621094 2.457031 C 7.789062 1.960938 9.007812 1.714844 10.285156 1.714844 C 11.5625 1.714844 12.785156 1.960938 13.949219 2.457031 C 15.113281 2.953125 16.117188 3.621094 16.960938 4.464844 C 17.804688 5.308594 18.476562 6.316406 18.972656 7.480469 C 19.464844 8.644531 19.714844 9.867188 19.714844 11.144531 C 19.714844 13.105469 19.160156 14.886719 18.054688 16.488281 L 22.648438 21.082031 C 22.976562 21.410156 23.144531 21.8125 23.144531 22.285156 Z M 23.144531 22.285156 "/>
      </svg>);
  }
}
