'use strict';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);

    this.state = {
      errors: null
    }
  }

  validate(file) {
    const {schema} = this.props;

    if (schema.mimes && schema.mimes.indexOf(file.type) === -1) {
      const message = `Please provide a file in the right format: ${schema.mimes.join(', ')}`;

      this.setState({
        errors: [this.props.formatError({schema, message})]
      });

      return false;
    }

    this.setState({
      errors: null
    });

    return true;
  }

  handleChange({target}) {
    const {files: [file]} = target;
    const reader = new FileReader();

    reader.onload = () => {
      this.props.onChange(reader.result);
    };

    reader.onerror = () => {
      reader.abort();
    };

    if (file) {
      if (this.validate(file)) {
        reader.readAsDataURL(file);
      }
      else {
        this.props.onChange(null);
      }
    }
  }

  render() {
    const Component = this.props.templates.file;

    let errors = [
      ...(this.props.errors ? this.props.errors : []),
      ...(this.state.errors ? this.state.errors : []),
    ];

    if (errors.length < 1) {
      errors = null;
    }

    const props = {
      ...this.props,
      onChange: this.handleChange,
      errors
    };

    return <Component {...props}/>;
  }
}
