export default class extends React.Component {
  render() {
    return (<svg className={this.props.className} width="9px" height="15px" viewBox="0 0 9 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-195.000000, -16.000000)" fill="#FFFFFF" fillRule="nonzero">
          <g>
            <g transform="translate(195.000000, 16.000000)">
              <g >
                <polygon points="0.165 1.91833333 5.74666667 7.5 0.165 13.0816667 1.66666667 14.5833333 8.75 7.5 1.66666667 0.416666667"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>);
  }
}
