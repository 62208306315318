import moment from '../lib/moment';
import Block from './block';
import styled from 'styled-components';

const TableWrapper = styled.div`
  overflow-x: auto;

  table {
    width: 100%;

    thead {
      font-weight: bold;
      tr td {
        border-bottom: 1px solid ${props => props.theme.darkGray};
      }
    }

    tr {
      width: 100%;

      td {
        padding: 5px;
        border-bottom: 1px solid ${props => props.theme.lightGray};
      }
    }
  }
`;

export default class extends React.Component {
  render() {
    let {
      eventSessions
    } = this.props;

    if (!eventSessions.length) {
      return null;
    }

    return (<Block title="Uw congresprogramma">
      <TableWrapper>
        <table cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <td>Start</td>
              <td>Eind</td>
              <td>Onderwerp</td>
              <td>Spreker</td>
            </tr>
          </thead>
          <tbody>
            {eventSessions.map((session, index) => {
              const {
                description,
                eventTeacher
              } = session;
              const start = (session.start.split('T')[1] || '').substring(0, 5);
              const end = (session.end.split('T')[1] || '').substring(0, 5);

              return (<tr key={`${description}-${index}`}>
                <td>
                  {start && end && `${start}`}
                </td>
                <td>
                  {start && end && `${end}`}
                </td>
                <td>{description}</td>
                <td>{eventTeacher.name}</td>
              </tr>);
            })}
          </tbody>
        </table>
      </TableWrapper>
    </Block>);
  }
}
