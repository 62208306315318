'use strict';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import moment from "../../lib/moment";

import MyOpenSupportRequests from '../../components/screens/my-open-support-requests';

@graphql(gql`query {
  User: currentUser {
    id
    supportRequests {
      items {
        id
        title
        question
        date
        url
        status
        rating
        replies {
          items {
            reply
            name
            function
            image
          }
        }
      }
    }
  }
}`, {
  props: ({data}) => {
    if (!data.loading && typeof data.User !== 'undefined') {
      const requests = data.User.supportRequests.items
        .filter(item => item.status !== 'FINISHED')
        .map(item => {
          return {
            ...item,
            date: moment(item.date, 'YYYY-MM-DD')
          };
        })
        .sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0));
      return {requests};
    }
  }
})
export default class extends React.Component {
  render() {
    if (!this.props.requests) {
      return null;
    }
    return <MyOpenSupportRequests requests={this.props.requests}/>
  }
}
