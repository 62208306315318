import styled from 'styled-components';
import Block from './block';
import EventSteps from './event-steps';

const Container = styled.div`
  margin-top: 25px;

  .in-between {
    margin-top: 0;
  }

  @media (max-width: ${ props => props.theme.breakpoints.big }px) {
    margin-top: 0;
  }
`;

export default class extends React.Component {
  render() {
    const {
      participated,
      finishedEvaluation,
      hasEvaluation,
      hasPreparation,
      finishedPreparation,
      paid,
      description,
      type
    } = this.props;


    const steps = [{done: participated, label: 'Deelgenomen'}];
    if (hasEvaluation) {
      steps.push({done: finishedEvaluation, label: 'Evaluatieformulier ingevuld'});
    }

    if (type !== 'in_company') {
      steps.push({done: paid, label: 'Betaald'});
    }

    return (<Container>
      <Block title="Algemene informatie" description={description}>
        <div>
          <div>
            <p>De stappen naar uw certificaat van deelname:</p>
            <EventSteps
              steps={steps}
            />
          </div>

          {hasPreparation && <div>
            <p className="in-between">Overig:</p>
            <EventSteps steps={[{done: finishedPreparation, label: 'Voorbereidingsopdracht gemaakt'}]}/>
          </div>}
        </div>
      </Block>
    </Container>);
  }
}
