export default class extends React.Component {
  render() {
    return (<svg className={this.props.className} width="28px" height="22px" viewBox="0 0 28 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-474.000000, -613.000000)" fill="#000000" fillRule="nonzero">
          <g transform="translate(474.000000, 613.000000)">
            <path d="M9.10004771,15.125 C8.99548547,15.125 8.89092323,15.1400391 8.79117349,15.1718359 C8.22417492,15.3527344 7.62786392,15.46875 7.00005301,15.46875 C6.37224209,15.46875 5.7759311,15.3527344 5.20849503,15.1718359 C5.10874528,15.1400391 5.00462054,15.125 4.90005831,15.125 C2.18494016,15.125 -0.0143667865,17.2940625 7.06770126e-05,19.9641406 C0.00619566155,21.0925 0.950755777,22 2.10006538,22 L11.9000406,22 C13.0493502,22 13.9939104,21.0925 14.0000353,19.9641406 C14.0144728,17.2940625 11.8151659,15.125 9.10004771,15.125 Z M7.00005301,13.75 C9.31967215,13.75 11.2000424,11.9032031 11.2000424,9.625 C11.2000424,7.34679687 9.31967215,5.5 7.00005301,5.5 C4.68043386,5.5 2.80006361,7.34679687 2.80006361,9.625 C2.80006361,11.9032031 4.68043386,13.75 7.00005301,13.75 Z M25.9000053,0 L9.10004771,0 C7.94198813,0 7.00005301,0.956054687 7.00005301,2.13082031 L7.00005301,4.125 C8.02467542,4.125 8.97317303,4.41632812 9.80004594,4.88984375 L9.80004594,2.75 L25.2000071,2.75 L25.2000071,15.125 L22.4000141,15.125 L22.4000141,12.375 L16.8000283,12.375 L16.8000283,15.125 L13.4645367,15.125 C14.3001596,15.8421484 14.913533,16.7891797 15.2009698,17.875 L25.9000053,17.875 C27.0580649,17.875 28,16.9189453 28,15.7441797 L28,2.13082031 C28,0.956054687 27.0580649,0 25.9000053,0 Z"></path>
          </g>
        </g>
      </g>
    </svg>);
  }
}
