'use strict';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    const {multipleOf, minimum, maximum, exclusiveMinimum, exclusiveMaximum} = this.props.schema;

    let valid = true;
    value = parseFloat(value);
    if (Number.isNaN(value)) {
      valid = false;
    } else if (multipleOf && value % multipleOf !== 0) {
      valid = false;
    } else if (typeof minimum === 'number' && exclusiveMinimum && value <= minimum) {
      valid = false;
    } else if (typeof minimum === 'number' && !exclusiveMinimum && value < minimum) {
      valid = false;
    } else if (typeof maximum === 'number' && exclusiveMaximum && value >= maximum) {
      valid = false;
    } else if (typeof maximum === 'number' && !exclusiveMaximum && value > maximum) {
      valid = false;
    }

    if (valid) {
      this.props.onChange(value);
    }
  }

  render() {
    const Component = this.props.templates.number;
    return <Component {...this.props} onChange={this.handleChange}/>;
  }
}
