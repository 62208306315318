import styled from 'styled-components';

const Tags = styled.span`
    color: rgb( 131, 131, 131 );
    background-color: white;
    border: 1px solid rgb( 220, 220, 220 );
    margin-left: 7px;
    padding: 4px;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    text-transform: uppercase;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    vertical-align: top;
    letter-spacing: 0.5px;
    top: 2px;
`;

export default class extends React.Component {
  render() {
    const {tags} = this.props;
    return tags.map(tag => <Tags key={tag} className="tag">{ tag }</Tags>);
  }
}
