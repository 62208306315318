export default {
  type: 'object',
  properties: {
    name: {
      title: 'Inlognaam',
      type: 'string',
      readOnly: true,
      maxLength: 255,
      description: 'Neem contact op met de klantenservice om uw inlognaam te wijzigen.'
    },
    password: {
      title: 'Huidig wachtwoord',
      type: 'string',
      maxLength: 255,
      password: true,
      description: 'Geef uw huidige wachtwoord op om uw e-mailadres te wijzigen en/of uw wachtwoord te wijzigen.'
    },
    newPassword: {
      title: 'Nieuw wachtwoord',
      type: 'string',
      maxLength: 255,
      password: true,
      description: 'Uw wachtwoord moet bestaat uit ten minste 8 karakters, 1 hoofdletter, 1 kleine letter en 1 cijfer.'
    },
    newPasswordConfirm: {
      title: 'Bevestig wachtwoord',
      type: 'string',
      maxLength: 255,
      password: true
    }
  },
  required: [],
  additionalProperties: false
};
