'use strict';

import {get} from 'jsonpointer';

export default class Schema {
  constructor(schema, options = {}) {
    this.schema = schema;
    this.options = {
      fullSchema: options.fullSchema || schema,
      ...options
    };
  }

  getEmptyValue() {
    if (typeof this.schema.default !== 'undefined') {
      return this.schema.default;
    }
    if (typeof this.schema.const !== 'undefined') {
      return this.schema.const;
    }
    if (this.schema.$ref) {
      const subschema = get(this.options.fullSchema, this.schema.$ref.substring(1));
      if (subschema) {
        const schema = new Schema(subschema, this.options);
        return schema.getEmptyValue();
      }
      return null;
    }
    if (this.schema.type === 'object') {
      return (this.schema.required || []).reduce((object, name) => {
        const subschema = new Schema(this.schema.properties[name] || {}, this.options);
        return {
          ...object,
          [name]: subschema.getEmptyValue()
        };
      }, {});
    }
    const type = this.schema.type || 'null';
    const empty = {
      string: '',
      number: 0,
      integer: 0,
      boolean: false,
      null: null,
      array: [],
      object: {}
    };
    return empty[type];
  }

}
