'use strict';

import {graphql} from 'react-apollo';
import Subscriptions from '../../components/screens/subscriptions';
import UserSubscriptionsQuery from '../../queries/user-subscriptions';
import {cloneDeep} from 'lodash';

@graphql(UserSubscriptionsQuery, {
  props: ({data: {User}}) => User
})
export default class extends React.Component {
  render() {
    if (!this.props.subscriptions) {
      return null;
    }

    const {
      subscriptions: {
        count,
        items: subscriptions
      }
    } = this.props;

    if (!subscriptions) {
      return null;
    }

    return (<Subscriptions
      subscriptions={cloneDeep(subscriptions).sort((a, b) => b.active - a.active)}
      count={count}
    />);
  }
}
