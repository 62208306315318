'use strict';

import {clone, union} from 'lodash';
import JsonEditor from '../json-editor';
import Schema from '../schema-tools';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPropertyName: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeNewPropertyName = this.handleChangeNewPropertyName.bind(this);
    this.handleClickAddProperty = this.handleClickAddProperty.bind(this);
  }

  getEmptyValue(schema) {
    const schemaInstance = new Schema(schema);
    return schemaInstance.getEmptyValue();
  }

  getValue() {
    return typeof this.props.value === 'object' && this.props.value !== null && !Array.isArray(this.props.value) ? this.props.value : {};
  }

  handleChange(property, value) {
    const newValue = clone(this.getValue());
    newValue[property] = value;
    if (value === '') {
      const propertySchema = this.getPropertySchema(property);

      if (typeof propertySchema.removeOnEmpty === 'undefined' || propertySchema.removeOnEmpty) {
        const alwaysVisible = ['string', 'integer', 'number'].indexOf(propertySchema.type) >= 0 && !propertySchema.enum;
        const required = (this.props.schema.required || []).indexOf(property) >= 0;
        if (alwaysVisible && typeof newValue[property] !== 'undefined' && !required) {
          delete newValue[property];
        }
      }
    }

    this.props.onChange(newValue);
  }

  getPropertySchema(property) {
    const {schema} = this.props;
    const propertySchemas = schema.properties || {};
    const propertySchema = propertySchemas[property] || schema.additionalProperties || {};
    return typeof propertySchema === 'object' ? propertySchema : {};
  }

  handleClickProperty(property, value = false) {
    const included = typeof this.getValue()[property] !== 'undefined';
    const newValue = clone(this.getValue());

    if (included) {
      if (value) {
        newValue[property] = true;
      }
      else {
        delete newValue[property];
      }
    } else {
      const propertySchema = this.getPropertySchema(property);
      newValue[property] = this.getEmptyValue(propertySchema);
    }
    this.props.onChange(newValue);
  }

  handleChangeNewPropertyName(newPropertyName) {
    this.setState({...this.state, newPropertyName});
  }

  handleClickAddProperty() {
    const newValue = clone(this.getValue());
    if (typeof newValue[this.state.newPropertyName] === 'undefined') {
      let propertyValue = null;
      const {additionalProperties} = this.props.schema;
      if (typeof additionalProperties === 'object' && additionalProperties.type) {
        propertyValue = this.getEmptyValue(additionalProperties);
      }
      newValue[this.state.newPropertyName] = propertyValue;
    }
    this.props.onChange(newValue);
    this.setState({
      ...this.state,
      newPropertyName: ''
    });
  }

  render() {
    const Component = this.props.templates.object;
    const {schema} = this.props;
    const value = this.getValue();
    const editableProperties = schema.additionalProperties === false ? Object.keys(schema.properties || {}) : union(Object.keys(schema.properties || {}), Object.keys(value));
    const properties = editableProperties.map(name => {
      const propertySchema = this.getPropertySchema(name);
      // Textfields can always be displayed. Empty values are stored as null.
      // This does not apply to enum fields because they have no way of unsetting the value.
      const alwaysVisible = ['string', 'integer', 'number'].indexOf(propertySchema.type) >= 0 && !propertySchema.enum;
      const optional = (schema.required || []).indexOf(name) < 0 && !alwaysVisible;
      const included = (typeof value[name] !== 'undefined' && value[name] !== null) || !optional;
      const handleChange = value => this.handleChange(name, value);
      const handleClick = value => this.handleClickProperty(name, value);
      const context = this.props.getSubContext(this.props.context, name);
      const label = propertySchema.title;

      const props = {
        name,
        path: `${this.props.path}/${name}`,
        deletable: optional,
        label: optional ? '' : label,
        elements: this.props.elements,
        schema: propertySchema,
        required: (schema.required || []).indexOf(name) > -1,
        fullSchema: this.props.fullSchema,
        errors: this.props.errors,
        validate: this.props.validate,
        validated: this.props.validated,
        validateImmediately: this.props.validateImmediately,
        onValidate: this.props.onValidate,
        onBlur: this.props.onBlur,
        value: value[name],
        context,
        templates: this.props.templates,
        getSubContext: this.props.getSubContext,
        onChange: handleChange,
        formatError: this.props.formatError,
        setChange: this.props.setChange ? this.props.setChange : () => {}
      };

      const element = included && (<JsonEditor {...props}/>);
      return {name, label, included, optional, element, handleClick};
    });

    return (<Component
      {...this.props}
      allowAddProperty={schema.additionalProperties !== false}
      properties={properties}
      newPropertyName={this.state.newPropertyName}
      onClickProperty={this.handleClickProperty}
      onChangeNewPropertyName={this.handleChangeNewPropertyName}
      onClickAddProperty={this.handleClickAddProperty}
      onChange={this.handleChange}
      />);
  }
}
