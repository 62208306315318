export default class extends React.Component {
  render() {
    return (<svg className={this.props.className} width="10px" height="5px" viewBox="0 0 10 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-7.000000, -10.000000)" fill="#1D99FD" fillRule="nonzero">
          <g>
            <polygon points="7 10 12 15 17 10"></polygon>
          </g>
        </g>
      </g>
    </svg>);
  }
}
