import moment from "../lib/moment";
import Date from './date';
import Block from './block';
import StyledLink from "./styled-link";
import { withRouter } from 'react-router-dom';
import styled from "styled-components";

const WhiteWrapper = styled.div`
  padding: 25px;
  background: white;
  border-radius: 8px;

  p:last-child {
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  h2 {
    margin-top: 0;
    color: ${props => props.theme.actionBlue};
  }

  .contents {
    > h4 {
      margin-top: 10px;
      color: ${props => props.theme.darkGray};
    }
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;

    p {
      margin-top: 0;
    }
  }

  .date-wrapper {
    margin-right: 30px;

    .time {
      color: ${props => props.theme.actionOrange};
      font-weight: bold;
    }
  }

  .location {
    > h3 {
      margin-top: 10px;
      margin-bottom: 0;
    }

    > p {
      color: ${props => props.theme.darkGray};
      margin: 0;
      .location-link {
        color: ${props => props.theme.darkGray};
      }
    }
  }
`;

@withRouter
export default class extends React.Component {
  render() {
    const {
      locationInfo,
      title,
      address,
      postalCode,
      city,
      routeUrl,
      extraLocationInfo
    } = this.props;

    const start = this.props.start ? moment.unix(this.props.start) : false;
    const end = this.props.end ? moment.unix(this.props.end) : false;

    let date = '';
    if (start && end) {
      date = (<div className="date-wrapper">
        <Date date={start} width={75} />
        <span className="time">{start.format('HH:mm')} - {end.format('HH:mm')}</span>
      </div>);
    }

    return (<Container>
      <Block title={false}>
        <WhiteWrapper>
          <h2>{title}</h2>
          <div className="info">
            {date}

            <div className="location">
              <h3>{locationInfo}</h3>
              {address !== 'Postbus 0' && (<p>{address}</p>)}
              {postalCode !== '0000 ZZ' && (<p>{postalCode}, {city}</p>)}
              <p>{extraLocationInfo}</p>
              {routeUrl && <p><a className="location-link" href={routeUrl} target="_blank">Routebeschrijving downloaden</a></p>}
            </div>
          </div>

          <p>
            <StyledLink to={`${this.props.match.url}/materiaal`}>Ga naar het materiaal</StyledLink>
          </p>
        </WhiteWrapper>
      </Block>
    </Container>);
  }
}
