import EventGeneral from '../event-general';
import EventInfo from '../../containers/event-info';
import EducationGeneralConditionalBlock from '../../containers/education-general-conditional-block';
import EducationGeneralConditionalPreparationBlock from '../../containers/education-general-conditional-preparation-block';
import EducationsDateLocations  from '../../containers/education-date-locations';
import Placeholder from "../placeholder";

export default ({
  eventHasPassed,
  hasPreparation,
  finishedPreparation,
  hasEvaluation,
  finishedEvaluation,
  paid,
  participated,
  certificateToken
}) => (
  <EventGeneral>
    <EventInfo/>
    <EducationGeneralConditionalBlock {...{
      eventHasPassed,
      hasEvaluation,
      finishedEvaluation,
      paid,
      participated,
      certificateToken
    }}/>
    <EducationsDateLocations/>
    <EducationGeneralConditionalPreparationBlock {...{
      hasPreparation,
      finishedPreparation
    }}/>
    <Placeholder position="PH_COP-opldetail_left"/>
    <Placeholder position="PH_COP-opldetail_right"/>
  </EventGeneral>
);
