'use strict';

export default class extends React.Component {
  render() {
    const handleChange = () => {
      this.props.onChange(!Boolean(this.props.value));
    };

    return (<this.props.templates.formElement {... {...this.props, label: false}}>
      <label>
        <input type="checkbox" checked={this.props.value} onChange={handleChange}/>
        {this.props.label}
      </label>
    </this.props.templates.formElement>);
  }
}
