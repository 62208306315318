import addressSchema from './address';

export default {
  type: 'object',
  properties: {
    avatar: {
      title: 'Profielfoto',
      type: 'string',
      upload: true,
      accept: 'image/*',
      mimes: [
        'image/gif',
        'image/png',
        'image/jpeg'
      ],
      validation: 'Upload een bestand in jp(e)g, gif of png formaat'
    },
    companyName: {
      title: 'Organisatie',
      type: 'string',
      maxLength: 255,
      minLength: 2,
      validation: 'Vul een organisatie in'
    },
    functionName: {
      title: 'Functie',
      type: 'string',
      maxLength: 255,
      minLength: 2,
      validation: 'Vul een functie in'
    },
    salutation: {
      title: 'Aanhef',
      type: 'string',
      enum: ['heer', 'mevrouw', 'onbekend'],
      validation: 'Kies een aanhef'
    },
    initials: {
      title: 'Voorletter(s)',
      type: 'string',
      maxLength: 16,
      minLength: 1,
      validation: 'Vul uw voorletter(s) in'
    },
    firstName: {
      title: 'Voornaam',
      type: 'string',
      maxLength: 255,
      validation: 'Vul uw voornaam in',
      removeOnEmpty: false
    },
    infix: {
      title: 'Tussenvoegsel(s)',
      type: 'string',
      maxLength: 255,
      removeOnEmpty: false
    },
    lastName: {
      title: 'Achternaam',
      type: 'string',
      maxLength: 255,
      minLength: 2,
      validation: 'Vul een geldige achternaam in'
    },
    phone: {
      title: 'Telefoonnummer',
      type: 'string',
      maxLength: 20,
      mask: '9999999999',
      pattern: '^(0[123457][0-9]|06[1-589]|08[578]|091)[0-9]{7}$',
      validation: 'Vul een geldig, Nederlands telefoonnummer in'
    },
    eventMail: {
      title: 'E-mailadres',
      type: 'string',
      maxLength: 255,
      format: 'email',
      validation: 'Vul een geldig e-mailadres in',
      description: 'Dit e-mailadres gebruiken we om u te informeren over de producten en diensten van Rendement Uitgeverij',
    },
    invoicemail: {
      title: 'E-mailadres facturatie',
      type: 'string',
      format: 'email',
      validation: 'Vul een geldig e-mailadres in',
      removeOnEmpty: false
    }
  },
  required: [
    'companyName',
    'functionName',
    'initials',
    'salutation',
    'lastName',
    'phone',
    'eventMail'
  ],
  additionalProperties: false
};
