'use strict';

import styled from 'styled-components';

import { TransparentButton } from './buttons';
import { ChevronDown } from './svg';

const ShowMoreButton = TransparentButton.extend`
	background-color: ${ props => props.theme.backgroundGray };
	border-radius: 0 0 15px 15px;
  color: ${ props => props.theme.rendementBlue };

  &:hover {
		background-color: ${ props => props.theme.backgroundGray };
    color: ${ props => props.theme.actionOrange }
  }

  &:hover svg g {
    fill: ${ props => props.theme.actionOrange }
  }
`;

const Container = styled.span`
	display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10px;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;

  svg {
    margin-left: 10px;
  }
`;

export default class extends React.Component {
  render() {
    const { onClick, children } = this.props;

    return (
      <Container>
        <ShowMoreButton onClick={onClick}>{ children }<ChevronDown/></ShowMoreButton>
      </Container>
    );
  }
}
