'use strict';

import Event from '../list-items/event';
import Suggestion from '../list-items/suggestion';
import StyledLink from '../styled-link';

export default class extends React.Component {
  render() {
    const { events } = this.props;

    return (
      <div>
        { events.length
          ? events.map( item => {
            const Component = item.suggestion ? Suggestion : Event;
            return (<Component key={ item.id } {...item} />);
          } )
          : <p>
            Momenteel zijn er geen actuele congressen beschikbaar in deze digitale leeromgeving.<br/>
            Bent u wel ingeschreven voor een congres, dan is de informatie enkele weken voor het congres beschikbaar in de digitale leeromgeving.<br/>
            U krijgt hiervan bericht per mail. Een overzicht van de congressen die u sinds 1 april 2019 heeft gevolgd, staat onder het tabblad ‘Historie’.<br/>
            Hier kunt u onder andere het congresmateriaal terugvinden en het Certificaat van Deelname downloaden.
            Kijk voor ons volledige aanbod op
            {' '}
            <StyledLink to="https://www.rendementco.nl/" target="_blank">Rendementco.nl.</StyledLink>.
          </p>
        }
      </div>
    );
  }
}
