'use strict';

import styled from 'styled-components';

const Header = styled.header`
	position: relative;
	background-color: white;

	h1 {
		font-size: 48px;
		color: rgb( 34, 34, 34 );
		font-family: Karla, sans-serif;
		margin: 0;
		margin-left: 40px;
		text-indent: -5px;
		padding: 60px 0;

    &.has-children {
      padding-bottom: 40px;
    }
	}

	@media (max-width: ${props => props.theme.breakpoints.big}px) {
		&:before {
			display: none;
		}

		h1 {
		  padding: 30px 0;
			font-size: 40px;
			text-align: center;
			margin-left: 0;
		}
	}
`;

export default class extends React.Component {
  render() {
    const {children} = this.props;

    document.title = `${this.props.title} - Rendement`;

    return (
    	<Header>
    		<h1 className={children ? 'has-children' : ''}>{ this.props.title === '' ? <span>&nbsp;</span> : this.props.title }</h1>
				{ children || null }
    	</Header>
    );
  }
}
