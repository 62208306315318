import EducationDateLocations from '../components/education-date-locations';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import {withRouter} from 'react-router-dom';

@withRouter
@graphql(gql `query($id: ID!) {
  EventScheduleData(id: $id) {
    id
    eventLocation {
      id
      name
      routeUrl
    }
		sessions {
      items {
				eventTeacher {
          id
					name
				}
        start
        end
        id
      }
    }
  }
}`, {
  props: ({data}) => {
    if (!data.loading && typeof data.EventScheduleData !== 'undefined') {
      const {
        routeUrl,
        name: locationInfo
      } = data.EventScheduleData.eventLocation;

      return {
        eventSessions: data.EventScheduleData.sessions.items,
        routeUrl,
        locationInfo
      };
    }
  },
  options: ({match: {params: {id}}}) => ({variables: {id}})
})
export default class extends React.Component {
  render() {
    if (!this.props.eventSessions) {
      return null;
    }
    return <EducationDateLocations
      eventSessions={this.props.eventSessions}
      routeUrl={this.props.routeUrl}
      locationInfo={this.props.locationInfo}
    />;
  }
}
